import * as Cognito from '../../infrastructure/aws/cognito/cognito';

export default class Signup {
  static async doSignup({ username, email, password }) {
    const res = await Cognito.registerUser({ username, password, email })
      .catch(() => false);
    return !!res;
  }

  static async confirmRegistration({ username, code }) {
    const res = await Cognito.confirmRegistration({ username, code })
      .catch(() => false);
    return !!res;
  }

  static async resendConfirmationCode({ username }) {
    const res = await Cognito.resendConfirmationCode(username)
      .catch(() => false);
    return !!res;
  }
}

<template>
  <b-container>
    <transition name="modal">
      <Modal v-show="showModal" message="異なる端末でログインされたため、ログアウトされました。" :isCancel="false" @done="close" />
    </transition>

    <section class="eye-catch-section">
      <h1 class="top-title">オルタナティブデータで情報を先取る</h1>
      <div class="text-center">
        <img src="/chart.svg" class="panel" style="width: 80%">
      </div>
      <div class="top-desc">
        企業や業界の業績をいち早く予測することは、有利な投資判断を行う上で重要です。PERAGARUではEC売上予測、ホテル予約予測、CM放映時間データ、購買データ、位置情報データなど、１企業から横断的なデータまで、先行指標となるデータを扱っています。
      </div>
      <div class="text-center">
        <b-button class="top-button" @click="doSignup">無料会員登録</b-button>
      </div>
    </section>

    <section>
      <h2 class="section-title">PERAGARUが紹介されました</h2>
      <div class="youtube mx-auto">
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/6hLTn-98f7E?si=ymEgAAKQUcWqwDBu" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
    </section>

    <section class="data-section">
      <h2 class="section-title">PERAGARUのオルタナティブデータ</h2>
      <div class="data-group pb-5">
        <div v-for="(data, index) in dataList" :key="index" class="data-panel">
          <div class="data-component">
            <img :src="data.img" style="width:30%;object-fit:contain;">
            <div style="width: 70%;" class="ml-4">
              <div>{{ data.plan }}</div>
              <h5>{{ data.title }}</h5>
              <div>{{ data.desc }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-5">
        <h5 class="mb-5">銘柄および各銘柄が扱うカテゴリーについては銘柄一覧よりご確認いただけます。</h5>
        <b-button class="top-button mr-md-5 mb-md-0 mb-4" @click="doSignup">無料会員登録</b-button>
        <b-button class="top-button" @click="goto('/list')">銘柄一覧はこちら</b-button>
      </div>
    </section>

    <section>
      <h2 class="section-title">プラン</h2>
      <div class="plan-group mb-20">
        <div class="plan-panel">
          <div class="text-center">
            <div class="plan-text">フリープラン</div>
            <h3 class="my-4">¥ 0<span class="unit"> /月</span></h3>
          </div>
          <div class="my-2">
            <div>全銘柄のWebスクレイピングデータを<u>90日前まで</u>ご覧いただけるプランです。過去の業績と比較してオルタナティブデータの有用性確認に活用できます。<u>無料会員登録のみ</u>でご覧いただけます。</div>
          </div>
        </div>
        <div class="plan-panel">
          <div class="text-center">
            <div class="plan-text">個別銘柄カテゴリープラン</div>
            <h3 class="my-4">¥ 4,950~<span class="unit"> /月</span></h3>
          </div>
          <div class="my-2">
            <div>１銘柄の中からカテゴリー単位で、PERAGARUでのデータ閲覧権限を提供するプランです。</div>
            <div><u>最新（7日前まで）</u>のデータを閲覧できます。</div>
            <div class="mt-2">※銘柄により扱うカテゴリーは異なります。</div>
          </div>
        </div>
        <div class="plan-panel">
          <div class="text-center">
            <div class="plan-text">従業員数パッケージプラン</div>
            <h3 class="my-4">¥ 4,400<span class="unit"> /月</span></h3>
          </div>
          <div class="my-2">
            <div>全銘柄の従業員数データプランです。PERAGARUで扱う従業員データは、上場企業およびその子会社の被保険者数から算出しています。</div>
            <div>PERAGARUでの個別銘柄でのメトリクス閲覧、従業員数一覧表示画面での閲覧が可能です。</div>
          </div>
        </div>
        <!-- <div class="plan-panel">
          <div class="text-center">
            <div class="plan-text">従業員数プレミアムパッケージプラン</div>
            <h3 class="my-4">¥ 13,200<span class="unit"> /月</span></h3>
          </div>
          <div class="my-2">
            <div>従業員数パッケージプランで提供するスプレッドシートをアップグレードしたプランです。上場企業と子会社の被保険者数の内訳まで提供します。</div>
          </div>
        </div> -->
        <div class="plan-panel">
          <div class="text-center">
            <div class="plan-text">PERAGARU賃金データプラン</div>
            <h3 class="my-4">¥ 3,850<span class="unit"> /月</span></h3>
          </div>
          <div class="my-2">
            <div>PERAGARU賃金データは全国の求人データをもとに算出しています。</div>
            <div>本データは週次単位で配信され、正社員の平均賃金推移や求人件数など重要なマクロ指標をいち早く把握することができます。</div>
          </div>
        </div>
        <div class="plan-panel">
          <div class="text-center">
            <div class="plan-text">エンタープライズプラン</div>
            <h3 class="my-4">ASK</h3>
          </div>
          <div class="my-2">
            <div>全銘柄を対象としたPERAGARUデータ閲覧権限を提供するプランです。</div>
            <div>対象カテゴリーについてはご要望ください。</div>
          </div>
        </div>
      </div>
      <div class="text-center mt-5">
        <h5 class="mb-5">詳細についてはプランページよりご確認いただけます。</h5>
        <b-button class="top-button mr-md-5 mb-md-0 mb-4" @click="doSignup">無料会員登録</b-button>
        <b-button class="top-button" @click="goto('/plan')">プラン詳細はこちら</b-button>
      </div>
    </section>

    <section>
      <h2 class="section-title">登録方法</h2>
      <div class="signup-group pb-5">
        <div class="signup-panel">
          <div class="contact-component">
            <div class="text-center my-auto">
              <img src="/img_form.png" style="object-fit:contain;height:60px;" class="mb-3">
              <h5>１ 登録フォームを入力</h5>
              <div>キャリアメールは利用できません</div>
            </div>
          </div>
        </div>
        <div class="signup-panel">
          <div class="contact-component">
            <div class="text-center my-auto">
              <img src="/img_mail.png" style="object-fit:contain;height:60px;" class="mb-3">
              <h5>２ メール認証</h5>
              <div>認証メールが届かない場合はお問い合わせください</div>
            </div>
          </div>
        </div>
        <div class="signup-panel">
          <div class="contact-component">
            <div class="text-center my-auto">
              <img src="/img_login.png" style="object-fit:contain;height:60px;" class="mb-3">
              <h5>３ ログイン</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-5">
        <h5 class="mb-5">登録の際に困ったことがございましたらお気軽にお問い合わせください。</h5>
        <b-button class="top-button mr-md-5 mb-md-0 mb-4" @click="doSignup">無料会員登録</b-button>
        <b-button class="top-button" @click="goto('/contact')">お問い合わせ</b-button>
      </div>
    </section>

  </b-container>
</template>

<script>
import Init from '@/components/mixins/Init.vue';
import router from '@/router';
import Modal from '@/components/utility/Modal.vue';

export default {
  components: {
    Modal,
  },
  name: 'About',
  mixins: [
    Init,
  ],
  props: [
    'isRestricted',
  ],
  data: () => ({
    showModal: false,
    dataList: [
      {
        img: '/img_scraping.png',
        plan: 'カテゴリー',
        title: 'Webスクレイピング',
        desc: '弊社オリジナルのオルタナティブデータ。EC売上予測からホテル予約状況、店舗データなど、投資判断において有益となりうる情報をWebスクレイピングで収集・分析して提供しています。',
      },
      {
        img: '/img_cm_data.png',
        plan: 'カテゴリー',
        title: 'TVCM',
        desc: 'TVCMの放映回数・放映秒数がわかるオルタナティブデータ。上場企業の広告宣伝費用の予測に活用できます。',
      },
      {
        img: '/img_smart_receipt.png',
        plan: 'カテゴリー',
        title: 'レシート販売金額',
        desc: '190万人・1.6万店舗のレシート販売金額がわかるオルタナティブデータ。売上実績を個別企業ごとに把握することができます。',
      },
      {
        img: '/img_time_in_store.png',
        plan: 'カテゴリー',
        title: '拠点滞在時間',
        desc: '450億件の人流位置情報に関するオルタナティブデータ。位置情報で小売店・ホテル・工場の稼働率を可視化することができます。',
      },
      {
        img: '/img_card.png',
        plan: 'カテゴリー',
        title: 'トレーディングカード販売量',
        desc: 'POSデータを元に各上場企業のトレーディングカードの売上がわかるオルタナティブデータ。KONAMIやバンダイナムコ・ブシロードなどのメーカーや卸し、小売を行っている企業の分析に活用できます。',
      },
      {
        img: '/img_employee.png',
        plan: 'パッケージ',
        title: '従業員数',
        desc: '全上場企業とその子会社の従業員数の遷移を横比較できるオルタナティブデータ。業界の成長トレンドや企業の潜在的な成長・リスク傾向をスクリーニングすることができます。',
      },
      {
        img: '/img_salary.png',
        plan: 'パッケージ',
        title: 'PERAGARU賃金データ',
        desc: 'PERAGARU賃金指数は賃金動向や求人件数の増減を把握することで経済状況をスピーディーに把握できます。',
      },
    ],
  }),
  created() {
    this.init();
  },
  async mounted() {
    this.showModal = this.isRestricted;
  },
  methods: {
    init() {
      const url = new URL(window.location.href);
      const ref = url.searchParams.get('ref');
      if (ref) document.cookie = `ref=${ref}`;
    },
    goto(path) {
      router.push({ path });
    },
    doSignup() {
      router.push({ path: '/signup' }).catch(() => {});
    },
    openNewTab(url) {
      window.open(url);
    },
    close() {
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';
section {
  padding: 0 5rem 15rem 5rem;
  @include media-breakpoint-down(md) {
    padding: 0 1rem 10rem 1rem;
  }
}
.top-title {
  text-align: center;
  padding: 5rem 0;
  font-size: 3.5rem;
  font-weight: bold;
  letter-spacing: .4rem;
  @include media-breakpoint-down(md) {
    font-size: 2.5rem;
  }
}
.panel {
  border: 1px solid #555;
  border-radius: 10px;
  background-color: #333;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
.top-desc {
  margin: 4rem 12rem;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: .2rem;
  @include media-breakpoint-down(md) {
    margin: 4rem 0rem;
  }
}
.top-button {
  border-color: white;
  background-color: #222;
  padding: 2rem 4rem;
  font-size: 1.3rem;
  &:hover {
    border: 1px solid white;
  }
  font-weight: bold;
  @include media-breakpoint-down(md) {
    width:100%;
  }
}
h3 {
  font-size: 2.5rem;
  font-weight: bold;
}
h5 {
  font-size: 1.5rem;
  font-weight: bold;
}
.unit {
  font-size: 1rem;
  font-weight: normal;
}
.section-title {
  text-align: center;
  padding-bottom: 8rem;
  font-size: 2.5rem;
  font-weight: bold;
  letter-spacing: .3rem;
  @include media-breakpoint-down(md) {
    padding-bottom: 4rem;
  }
}
.data-group {
  display: flex;
  flex-wrap: wrap;
  gap: 50px 6%;
  @include media-breakpoint-down(md) {
    gap: 20px 0%;
  }
}
.data-panel {
  width: 47%;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
.data-component {
  display: flex;
  height: 100%;
  border: 2px solid #777;
  border-radius: 10px;
  background-color: #333;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  padding: 1.5rem;
  @include media-breakpoint-down(md) {
    padding: 1rem;
  }
}
.contact-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 2px solid #777;
  border-radius: 10px;
  background-color: #333;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  padding: 1.5rem;
  @include media-breakpoint-down(md) {
    padding: 1rem;
  }
}
.plan-text {
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: .1rem;
}
.plan-group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 2%;
  // padding: 1rem;
  // border: 2px solid #777;
  // border-radius: 10px;
  // background-color: #333;
}
.plan-panel {
  border: 2px solid #777;
  border-radius: 10px;
  background-color: #333;
  padding: 1.5rem;
  width: 32%;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
.signup-group {
  display: flex;
  flex-wrap: wrap;
  gap: 50px 5%;
  @include media-breakpoint-down(md) {
    gap: 20px 0%;
  }
}
.signup-panel {
  width: 30%;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
.youtube {
  width: 50%;
  aspect-ratio: 16/9;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
</style>

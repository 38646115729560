<template>
  <div>
    <div v-show="isLoading" class="text-center my-5 py-5">
      <b-spinner></b-spinner>
    </div>
    <div v-show="!isLoading">
      <div class="text-right mt-auto">
        <div>
          最終更新日: {{ updateAt }}
        </div>
      </div>
      <ag-grid-vue
        style="width: 100%; height: 1000px;"
        ref="table"
        :class="themeClass"
        :columnDefs="columnDefs"
        :rowData="rowData"
        :key="columnSetting"
        @first-data-rendered="onFirstDataRendered"
      >
      </ag-grid-vue>
      <div class="text-right mt-auto">
        <div>
          DOWNLOAD:
          <b-link class="mx-2" v-on:click="downloadCSV()">CSV</b-link>
        </div>
        <div class="small text-secondary">
          <div>
            CSVファイルをExcelで開く際、Excelの仕様により文字化けや表示形式の変換がされる場合がございます。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import CompanyLinkRenderer from '../ag-grid/CompanyLinkRenderer.vue';

export default {
  name: 'App',
  data() {
    return {
      displayWidth: window.innerWidth,
      columnDefs: [],
      columnDefsForDefault: [],
      columnDefsForSmall: [],
      rowData: [],
      gridApi: null,
      themeClass: 'ag-theme-material-dark',
      isLoading: true,
      // eslint-disable-next-line no-nested-ternary
      columnSetting: window.innerWidth >= 1000 ? 'default' : window.innerWidth >= 600 ? 'forMedium' : 'forSmall', // カラムの設定を反映させ再描画させるためのkey設定用文字列
    };
  },
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    CompanyLinkRenderer,
  },
  props: ['employeeData', 'industryDataList', 'startDate', 'lastDate', 'updateAt'],
  mounted() {
    this.rowData = this.employeeData.map((row) => ({
      ...row,
      PERAGARUIndustryCategory: this.industryDataList.filter((industryData) => row.securityCode === industryData.securityCode).map((industryData) => industryData.peragaruIndustry),
    }));
    this.setColumnDefs(this.startDate, this.lastDate);
    if (this.displayWidth >= 1000) this.columnDefs = this.columnDefsForDefault;
    if (this.displayWidth < 1000 && this.displayWidth >= 600) this.columnDefs = this.columnDefsForMedium;
    if (this.displayWidth < 600) this.columnDefs = this.columnDefsForSmall;

    window.addEventListener('resize', this.handleResize);
    this.isLoading = false;
  },
  methods: {
    onFirstDataRendered(params) {
      this.gridApi = params.api;
      // 初期表示のテーブルのスクロールを右端にする
      this.$nextTick(() => {
        const gridElement = this.$refs.table.$el.querySelector('.ag-center-cols-viewport');
        if (gridElement) {
          gridElement.scrollLeft = gridElement.scrollWidth;
          gridElement.addEventListener('wheel', this.handleWheel, { passive: false });
        }
      });
    },
    downloadCSV() {
      this.gridApi.exportDataAsCsv();
    },
    setColumnDefs(start, last) {
      const companyColumn = {
        headerName: '企業名',
        field: 'companyName',
        pinned: 'left',
        filter: true,
        width: 280,
        cellRenderer: 'CompanyLinkRenderer',
        filterParams: {
          filterOptions: ['contains', 'equals', 'startsWith'],
          caseSensitive: false,
          maxNumConditions: 1,
          textMatcher({ filterOption, value, filterText }) {
            if (filterText == null) {
              return false;
            }
            const toHalfString = (str) => str.replace(/[Ａ-Ｚａ-ｚ]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0));
            const normalizedValue = toHalfString(value);
            const normalizedFilterText = toHalfString(filterText);
            switch (filterOption) {
              case 'contains':
                return normalizedValue.indexOf(normalizedFilterText) >= 0;
              case 'equals':
                return normalizedValue === normalizedFilterText;
              case 'startsWith':
                return normalizedValue.indexOf(normalizedFilterText) === 0;
              default:
                console.warn(`invalid filter type ${filterOption}`);
                return false;
            }
          },
        },
      };
      const securityAndCategoryColumns = [{
        headerName: '銘柄コード',
        field: 'securityCode',
        filter: true,
        width: 130,
        filterParams: {
          filterOptions: ['contains', 'equals', 'startsWith'],
          caseSensitive: false,
          maxNumConditions: 1,
        },
        cellStyle: { textAlign: 'right' },
      },
      {
        headerName: 'カテゴリ',
        field: 'PERAGARUIndustryCategory',
        filter: true,
        width: 160,
        filterParams: {
          filterOptions: ['contains'],
          caseSensitive: false,
          maxNumConditions: 1,
        },
      }];
      const fixedColumns = [companyColumn, ...securityAndCategoryColumns].map((obj) => ({ ...obj, pinned: 'left' }));

      const dataColumns = this.generateDateColumns(start, last);

      const changeRateColumn = {
        headerName: '直近変化率',
        field: 'latestChangeRate',
        width: 120,
        cellStyle: { textAlign: 'right' },
        valueFormatter: (params) => (params.value ? `${(params.value * 100).toFixed(2)}%` : null),
        comparator: (a, b) => (a - b),
      };
      this.columnDefsForDefault = [...fixedColumns, ...dataColumns, changeRateColumn];
      this.columnDefsForMedium = [companyColumn, ...securityAndCategoryColumns, ...dataColumns, changeRateColumn];
      this.columnDefsForSmall = [{ ...companyColumn, width: 180 }, ...securityAndCategoryColumns, ...dataColumns, changeRateColumn];
    },
    generateDateColumns(start, last) {
      const startDate = new Date(`${start}/01`);
      const lastDate = new Date(`${last}/01`);
      const headers = [];

      // 月を一ヶ月ずつ増やして、ヘッダー設定を追加
      const current = new Date(startDate);
      while (current <= lastDate) {
        const yearMonth = `${current.getFullYear()}/${(`0${current.getMonth() + 1}`).slice(-2)}`;
        headers.push({
          field: yearMonth,
          width: 100,
          sortable: false,
          cellStyle: { textAlign: 'right' },
        });
        current.setMonth(current.getMonth() + 1);
      }

      return headers;
    },
    handleWheel(event) {
      // テーブル内で水平スクロールをした際にブラウザの戻るが発生することを防ぐ
      if (event.deltaX !== 0 && event.target.closest('.ag-center-cols-viewport')) {
        event.preventDefault();
        const gridElement = event.target.closest('.ag-center-cols-viewport');
        gridElement.scrollLeft += event.deltaX;
      }
    },
    handleResize() {
      this.displayWidth = window.innerWidth;
    },
  },
  watch: {
    displayWidth(newDisplayWidth, oldDisplayWidth) {
      if (newDisplayWidth >= 1000 && oldDisplayWidth < 1000) {
        this.columnDefs = this.columnDefsForDefault;
        this.columnSetting = 'default';
      }
      if ((newDisplayWidth < 1000 && newDisplayWidth >= 600) && (oldDisplayWidth >= 1000 || oldDisplayWidth < 600)) {
        this.columnDefs = this.columnDefsForMedium;
        this.columnSetting = 'forMedium';
      }
      if (newDisplayWidth < 600 && oldDisplayWidth >= 600) {
        this.columnDefs = this.columnDefsForSmall;
        this.columnSetting = 'forSmall';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/custom";
@import "~bootstrap/scss/bootstrap.scss";
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-quartz.css";
.ag-theme-material-dark {
  --ag-background-color: #303030;
  --ag-header-background-color: #2d2d2d;
  --ag-odd-row-background-color: #2d2d2d;
  --ag-border-radius: 5px;
}
</style>

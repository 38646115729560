<template>
  <b-container class="payment">
    <b-row align-v="start">
      <b-col cols="12">
        <h1 class="my-4 h1-title">退会について</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-alert v-model="isError" variant="danger" dismissible>
          <b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{ errorMessage }}
            <a href="#"
              @click="goto('/contact')">
              お問い合わせ
            </a>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="panel">
          <h4>PERAGARUをご利用いただきありがとうございます。</h4>
          <h4>退会される場合、ご契約中のプランは<span class="highlight">退会完了時点ですべて解約され、途中解約による返金はございません</span>。</h4>
          <h4>また、ご利用のアカウントは完全に停止され、同じメールアドレスまたはユーザー名を使用したアカウントの作成は不可となりますのでご了承ください。</h4>
          <h4>ご利用プランの解約は<router-link to="/user/subscription">こちら</router-link>からお申し込みいただけます。</h4>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4 text-right">
        <b-button @click="showModal = true" variant="danger">
          <b>退会</b>
        </b-button>
        <b-button @click="goto('/user')" class="ml-3">
          <b>戻る</b>
        </b-button>
      </b-col>
    </b-row>
    <transition name="modal">
      <Modal v-show="showModal" :message="'退会しますか？'" :isCancel="true" @close="showModal = false" @done="doDeleteAccount"/>
    </transition>
  </b-container>
</template>

<script>
import router from '@/router';
import * as Http from '@/lib/http/http';
import Singin from '@/lib/model/user/signin';
import Session from '@/lib/model/user/session';
import Modal from '@/components/utility/Modal.vue';

export default {
  components: { Modal },
  name: 'UserDelete',
  data: () => ({
    sessionToken: '',
    errorMessage: '',
    isError: false,
    showModal: false,
  }),
  methods: {
    async doDeleteAccount() {
      const token = await Session.getSession();
      if (!token) {
        console.error('Session Error.');
        return;
      }
      this.sessionToken = token;

      const res = await Http.post(
        `https://${process.env.VUE_APP_BACKEND_USER_DOMAIN}/${process.env.VUE_APP_BACKEND_USER_VERSION}/user/deleteAccount`,
        this.sessionToken,
      )
        .catch((e) => {
          console.error(e);
          return e.response.data;
        });

      console.log(res);
      this.showModal = false;
      if (res) {
        this.errorMessage = res.errorMessage;
        this.isError = true;
      } else {
        this.goComp();
      }
    },
    async goComp() {
      Singin.doSignout();
      router.push({
        name: 'userDeleteComplate',
        params: {
          isDeleted: true,
        },
      }).catch(() => {});
    },
    goto(link) {
      router.push({ path: link });
    },
  },
};
</script>

<style scoped>
.highlight {
  border-bottom: solid 2px #E22B6F;
}
.panel {
  background-color: #303030;
  padding: 1.5rem 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #555;
}
</style>

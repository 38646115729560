<template>
  <div>
    <h5>PERAGARUデータ閲覧</h5>
    <ul>
      <li>対象の銘柄およびカテゴリーデータの閲覧権限</li>
      <li v-if='category !== "cm"'>対象の銘柄およびカテゴリーデータのファイルダウンロード（csv、json形式）権限</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'UnitDetail',
  props: ['planCode', 'category'],
  data: () => ({}),
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';
</style>

/* eslint-disable no-shadow,no-unused-vars,no-param-reassign */
import Metrics from '../../../lib/model/project/metrics';

import * as LocalStorage from '../../../lib/infrastructure/local-storage/local-storage';

const storageKeyName = `${process.env.NODE_ENV}-MetricsMap`;

const state = {
  metricsMap: {},
};

const getters = {
  getMetricsWithProject: (
    state,
    _getters,
    _rootState,
    _rootGetters,
  ) => (projectName) => {
    if (!state.metricsMap || !state.metricsMap[projectName]) return [];
    return state.metricsMap[projectName].map((m) => new Metrics(m));
  },
  getAllMetrics: (state, _getters, _rootState, _rootGeters) => Object.entries(state.metricsMap)
    .reduce((ac, [projectName, metricsList]) => {
      ac[projectName] = metricsList.map((m) => new Metrics(m));
      return ac;
    }, {}),
};

const actions = {
  getMetricsFromLocal({ commit, state, getters }, projectName) {
    const metricsMap = LocalStorage.get(storageKeyName);
    commit('setMetricsMap', metricsMap || {});
    return getters.getMetricsWithProject(projectName);
  },
  getAllFromLocal({ commit, state, getters }) {
    const metricsMap = LocalStorage.get(storageKeyName);
    commit('setMetricsMap', metricsMap || {});
    return getters.getAllMetrics;
  },
  upsertMetricsWithProject({ commit, state, getters }, { projectName, metrics }) {
    commit('upsertProjectMetrics', { projectName, metrics });
    try {
      LocalStorage.upsert(storageKeyName, state.metricsMap);
    } catch (e) {
      console.log(e);
      commit('reset');
      LocalStorage.upsert(storageKeyName, state.metricsMap);
    }
  },
  removeMetrics({ commit, state, getters }, projectName) {
    commit('removeProjectMetrics', projectName);
    LocalStorage.upsert(storageKeyName, state.metricsMap);
  },
  resetMetrics({ commit, state, getters }) {
    commit('reset');
    LocalStorage.upsert(storageKeyName, state.metricsMap);
  },
  filterProjectMetrics({ commit, state, getters }, projectNameList) {
    commit('filterProjectMetrics', projectNameList);
    LocalStorage.upsert(storageKeyName, state.metricsMap);
  },
};

const mutations = {
  setMetricsMap(state, map) {
    state.metricsMap = Object.entries(map)
      .reduce((ac, [projectName, metricsList]) => {
        ac[projectName] = metricsList.map((m) => new Metrics(m));
        return ac;
      }, {});
  },
  upsertProjectMetrics(state, { projectName, metrics }) {
    state.metricsMap[projectName] = metrics;
  },
  removeProjectMetrics(state, projectName) {
    state.metricsMap = Object.entries(state.metricsMap).reduce((ac, [pn, metricsList]) => {
      if (pn === projectName) return ac;
      ac[pn] = metricsList.map((m) => new Metrics(m));
      return ac;
    }, {});
  },
  reset(state) {
    state.metricsMap = {};
  },
  filterProjectMetrics(state, projectNameList) {
    state.metricsMap = Object.entries(state.metricsMap).reduce((ac, [pn, metricsList]) => {
      if (!projectNameList.includes(pn)) return ac;
      ac[pn] = metricsList.map((m) => new Metrics(m));
      return ac;
    }, {});
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div class="filter panel">
    <!-- ヘッダー -->
    <div class="d-flex" style="padding: 10.5px 0">
      <div class="font-weight-bold" style="font-size: 17.5px;">絞り込み</div>
      <b-link @click="reset" class="text-right mt-auto">クリア</b-link>
    </div>

    <!-- フィルター -->
    <b-form @submit.prevent>

      <!-- 検索 -->
      <b-form-group label="検索" class="text-secondary mb-4">
        <b-form-tags
          v-model="searchConditions.inputCondtion"
          size="lg"
          no-outer-focus
          class="p-0"
        >
          <template v-slot="{ tags, inputAttrs, inputHandlers, removeTag }">
            <b-input-group class="mb-2">
              <b-form-input
                v-bind="inputAttrs"
                v-on="inputHandlers"
                placeholder="銘柄コード・銘柄名"
              ></b-form-input>
            </b-input-group>
            <div>
              <b-form-tag
                v-for="tag in tags"
                @remove="removeTag(tag)"
                :key="tag"
                :title="tag"
                class="d-inline-flex bg-tag"
              >{{ tag }}</b-form-tag>
            </div>
          </template>
        </b-form-tags>
      </b-form-group>

      <!-- 契約プラン -->
      <b-form-group label="契約プラン" class="text-secondary">
        <b-list-group v-for="plan in searchConditions.planCondition" :key="plan[0]">
          <b-form-checkbox
            v-model="plan.selected"
            class="my-1 text-light"
          >
            {{ plan.value }}
          </b-form-checkbox>
        </b-list-group>
      </b-form-group>

      <!-- 決算月 -->
      <b-form-group label="決算月" class="text-secondary">
        <b-form-checkbox v-model="searchConditions.fiscalEndMonthCondition.thisMonth" class="my-1 text-light">今月</b-form-checkbox>
        <b-form-checkbox v-model="searchConditions.fiscalEndMonthCondition.nextMonth" class="my-1 text-light">来月</b-form-checkbox>
      </b-form-group>

    </b-form>
  </div>
</template>

<script>
import DataCategory from '@/lib/model/project/data-category';
import PackagePlan from '@/lib/model/project/package-plan';

export default {
  name: 'ProjectContractFilter',
  data: () => ({
    searchConditions: {
      inputCondtion: [],
      planCondition: [],
      fiscalEndMonthCondition: {
        thisMonth: false,
        nextMonth: false,
      },
    },
  }),
  created() {
    const categoryList = Object.entries(DataCategory)
      .map((([key, value]) => ({
        key,
        value,
        selected: false,
      })));
    const planList = Object.entries(PackagePlan)
      .map(([key, value]) => ({
        key,
        value,
        selected: false,
      }));
    this.searchConditions.planCondition = categoryList.concat(planList);
  },
  methods: {
    reset() {
      this.searchConditions.inputCondtion = [];
      this.searchConditions.fiscalEndMonthCondition = {
        thisMonth: false,
        nextMonth: false,
      };
    },
  },
  watch: {
    searchConditions: {
      handler() {
        this.$emit('searchConditions', this.searchConditions);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  font-weight: bold;
  margin-top: 26.25px;
}
.b-form-tags {
  background-color: transparent;
  border: none;
}
.bg-tag {
  padding-bottom: 0.5rem;
  margin: 0.25rem 0.5rem 0.25rem 0;
  // background-color: #e22b6e75;
  // border: solid 1px #E22B6F;
  background-color: #555;
  border: solid 1px #666;
  font-size: 0.9rem;
  font-weight: bold;
}
.panel {
  padding: 0.5rem 2rem;
  background-color: #303030;
  border: 1px solid #555;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
</style>

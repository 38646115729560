<template>
  <b-container class="mb-5">
    <div v-show="isLoading" class="text-center my-5 py-5">
      <b-spinner></b-spinner>
    </div>
    <div v-show="!isLoading">
      <div v-if="announceList.length" class="mb-5">
        <b-card class="pera-panel p-0">
          <div v-for="(announce, index) in formatAnnouncement" :key="announce.id">
            <p class="mb-2 font-weight-bold">{{ announce.title }}</p>
            <small class="insights" v-html="announce.context"></small>
            <hr v-if="index != announceList.length - 1" class="border-secondary">
          </div>
        </b-card>
      </div>
      <h1 class="mb-3">契約一覧</h1>
      <div v-if="projects.length">
        <ProjectContractTable :projects="projects"/>
      </div>
      <div v-else>
        <h5 class="mb-3 text-secondary">ご契約の銘柄はありません</h5>
        <div class="cards">
          <b-card class="card-style" @click="goto('/list')">一覧から探す</b-card>
          <b-card class="card-style" @click="goto('/plan')">プランから探す</b-card>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex';
import * as Http from '@/lib/http/http';
import Session from '@/lib/model/user/session';
import router from '@/router';
import Init from '@/components/mixins/Init.vue';
import LoginRestriction from '@/components/mixins/LoginRestriction.vue';
import ProjectModel from '@/lib/model/project/project';
import ProjectContractTable from '@/components/project/ProjectContractTable.vue';
import * as Analytics from '@/lib/infrastructure/google/analytics';

export default {
  name: 'Top',
  mixins: [
    Init,
    LoginRestriction,
  ],
  components: {
    ProjectContractTable,
  },
  data: () => ({
    isLoading: true,
    projects: [],
    announceList: [],
    subscriptions: [],
    userPlans: [],
  }),
  methods: {
    ...mapActions({
      getAllContractFromLocal: 'ContractList/getAllContractFromLocal',
      setContractList: 'ContractList/setContractList',
      resetContractList: 'ContractList/resetContractList',
      getUserStatus: 'UserInfo/getUserStatus',
      setUserStatus: 'UserInfo/setUserStatus',
      resetUserStatus: 'UserInfo/resetUserStatus',
    }),
    sort() {
      this.projects = this.projects.sort((p1, p2) => {
        if (p1.securityCode < p2.securityCode) return -1;
        if (p1.securityCode > p2.securityCode) return 1;
        return 0;
      });
    },
    goto(link) {
      router.push({ path: link });
    },
  },
  async created() {
    this.projects = await this.getAllContractFromLocal();
    if (this.projects.length) this.isLoading = false;
  },
  async mounted() {
    const token = await Session.getSession();
    if (!token) {
      console.error('Session Error.');
      return;
    }
    this.announceList = await Http.get(`https://${process.env.VUE_APP_BACKEND_USER_DOMAIN}/${process.env.VUE_APP_BACKEND_USER_VERSION}/personal/user/announcement`, token);
    const userPlans = await Http.get(
      `https://${process.env.VUE_APP_BACKEND_DOMAIN}/2/personal/plan`,
      token,
    ).catch((e) => {
      console.error(e);
      return [];
    });
    this.isPaidEmployee = userPlans.some((plan) => plan.planCode.includes('employee'));
    const projects = await Http.get(`https://${process.env.VUE_APP_BACKEND_DOMAIN}/2/personal/list`, token);
    if (!projects || !Array.isArray(projects)) {
      this.resetContractList();
      return;
    }
    this.projects = projects.map((p) => new ProjectModel(p));

    this.setContractList(this.projects);

    this.isLoading = false;

    Analytics.sendIsUserEvent();
  },
  computed: {
    formatAnnouncement() {
      return this.announceList.map((announce) => {
        const formatedAnnounce = announce;
        const matchedLinkList = Array.from(new Set(announce.context.match(/((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g)))
          .sort((a, b) => (a.length < b.length ? 1 : -1));
        if (matchedLinkList && matchedLinkList.length) {
          matchedLinkList.forEach((matched) => {
            const escapedMatched = matched.replace('?', '\\?').replace('&', '\\&');
            const regex = new RegExp(`(?!(.*href=|.*\\/a>))${escapedMatched}`, 'g');
            formatedAnnounce.context = announce.context.replace(
              regex,
              `<a href=${matched} target="_blank">${matched}</a>`,
            );
          });
        }
        return formatedAnnounce;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';
.pera-panel {
  background-color: #222;
  border: 1px solid #555;
  border-radius: 1rem;
  padding: 1rem 2rem;
}
.cards {
  display: inline-flex;
  justify-content: space-around;
  gap: 1rem;
  margin-top: 1rem;
}
.card-style {
  font-size: 18px;
  width: auto;
  display: inline-block;
  text-align: center;
  background-color: #222;
  border: 1px solid #555;
  border-radius: 1rem;
  cursor: pointer;
  &:hover {
    background-color: #333;
  }
}
</style>

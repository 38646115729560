export default {
  default: 'Webスクレイピング',
  cm: 'TVCM出稿量',
  geoLocation: '拠点滞在時間',
  smartReceipt: 'レシート販売金額',
  tradingCard: 'トレーディングカード販売量',
  powerGeneration: '発電量',
  employee: '従業員数',
  enterprise: 'エンタープライズ',
};

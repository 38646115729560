<template>
  <b-container>
    <div v-show="isLoading" class="text-center my-5 py-5">
      <b-spinner></b-spinner>
    </div>
    <div v-show="!isLoading">
      <h1 class="mb-5">契約情報</h1>
      <div v-if="!userSubscriptioin.length" class="mb-10">
        <h5>ご契約中のプランはありません</h5>
      </div>
      <div class="group mb-10">
        <b-list-group v-for='subsc in userSubscriptioin' :key='subsc.recurringID' class="panel">
          <Subscription :subsc="subsc" class="component" />
        </b-list-group>
      </div>

      <div class="mb-20">
        <NotificationModal :isSuccess ="isSuccess" :isError="isError"/>
        <h3 class="mb-3">クレジットカード</h3>
        <div class="card">
          <div class="d-flex">
            <MaskedCard v-show="currentCard.CardNo" :cardNo="currentCard.CardNo" class="my-2" />
            <h5 v-show="!currentCard.CardNo" class="my-auto">クレジットカードが登録されていません</h5>
            <div class="my-auto text-right">
              <b-button v-b-toggle.card-form variant="info" style="border-radius: 3px;">
                <b>{{ currentCard.CardNo ? '変更' : '登録' }}</b>
              </b-button>
              <b-button
                v-if="currentCard.CardNo && !userSubscriptioin.length"
                @click="showDelCardModal = true" class="ml-3"
                style="border-radius: 3px;"
              >
                <b>削除</b>
              </b-button>
              <transition name="modal">
                <Modal v-show="showDelCardModal" :message="'ご登録のクレジットカードを削除しますか？'" :isCancel="true" @close="showDelCardModal = false" @done="deleteCard"/>
              </transition>
            </div>
          </div>
          <b-collapse id="card-form">
            <b-row class="mb-3">
              <b-col>
                <div>
                  <hr color="#888" class="mb-3">
                  <CardForm
                    v-if="rendering"
                    ref="cardForm"
                    @get-token="updateCard"
                  />
                  <div class="pt-5 text-right">
                    <b-button @click="confirmUpdate" variant="info" :disabled="false"><b>確認</b></b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-collapse>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex';
import router from '@/router';
import * as Http from '@/lib/http/http';
import Session from '@/lib/model/user/session';
import NotificationModal from '@/components/utility/NotificationModal.vue';
import Modal from '@/components/utility/Modal.vue';
import MaskedCard from '@/components/payment/MaskedCard.vue';
import Subscription from '@/components/user/Subscription.vue';
import CardForm from '@/components/payment/CardForm.vue';

export default {
  name: 'UserSubscription',
  components: {
    NotificationModal,
    Modal,
    MaskedCard,
    Subscription,
    CardForm,
  },
  props: ['successChangeCard'],
  data: () => ({
    isSuccess: false,
    isError: false,
    sessionToken: '',
    currentCard: {},
    showDelCardModal: false,
    isLoading: true,
    rendering: true,
    userSubscriptioin: [],
  }),
  async mounted() {
    await this.init();
  },
  methods: {
    ...mapActions({
      resetCard: 'Payment/resetCard',
    }),
    async init() {
      const token = await Session.getSession();
      if (!token) {
        console.error('Session Error.');
        return;
      }
      this.sessionToken = token;

      // カード変更画面からの遷移後の結果をポップアップで表示
      if (this.successChangeCard) {
        this.isSuccess = true;
        setTimeout(() => {
          this.isSuccess = false;
        }, 10000);
      }

      // サブスク、クレカ取得
      const [subscriptions, currentCard] = await Promise.all([
        Http.get(
          `https://${process.env.VUE_APP_BACKEND_USER_DOMAIN}/${process.env.VUE_APP_BACKEND_USER_VERSION}/user/subscriptions`,
          this.sessionToken,
        ).catch((e) => {
          console.error(e);
          return [];
        }),
        Http.get(
          `https://${process.env.VUE_APP_BACKEND_PAYMENT_DOMAIN}/${process.env.VUE_APP_BACKEND_PAYMENT_VERSION}/payment/card/current`,
          this.sessionToken,
        ).catch((e) => {
          console.error(e);
          return {};
        }),
      ]);

      this.userSubscriptioin = subscriptions;
      this.currentCard = currentCard;

      this.isLoading = false;
    },
    async deleteCard() {
      const res = await Http.post(
        `https://${process.env.VUE_APP_BACKEND_PAYMENT_DOMAIN}/${process.env.VUE_APP_BACKEND_PAYMENT_VERSION}/payment/card/delete`,
        this.sessionToken,
        {
          cardSeq: this.currentCard.CardSeq,
        },
      ).catch((e) => {
        console.error(e);
        console.log(e.response.data);
        this.isError = true;
        return e.response.data;
      });

      this.showDelCardModal = false;

      if (!res) {
        this.rendering = false;
        this.currentCard = '';
        this.$nextTick(() => {
          this.rendering = true;
        });
        this.isSuccess = true;
        setTimeout(() => {
          this.isSuccess = false;
        }, 10000);
      }
      window.scroll({ top: 0 });
    },
    goto(link) {
      router.push({ path: link });
    },
    closeForm() {
      document.querySelector('button.not-collapsed').click();
    },
    async confirmUpdate() {
      await this.$refs.cardForm.getToken();
    },
    async updateCard(token, holdername) {
      // eslint-disable-next-line
      if (!window.confirm('クレジットカードを登録しますか？')) return;
      const result = await Http.post(
        `https://${process.env.VUE_APP_BACKEND_PAYMENT_DOMAIN}/${process.env.VUE_APP_BACKEND_PAYMENT_VERSION}/payment/card/change`,
        this.sessionToken,
        {
          holdername,
          token,
        },
      ).catch((e) => {
        console.error(e);
        this.isError = true;
      });

      window.scroll({ top: 0 });
      if (!result) {
        this.isSuccess = true;
        document.querySelector('button.not-collapsed').click();
        setTimeout(() => {
          this.isSuccess = false;
        }, 5000);
        this.rendering = false;
        this.resetCard();
        const newCrad = await Http.get(
          `https://${process.env.VUE_APP_BACKEND_PAYMENT_DOMAIN}/${process.env.VUE_APP_BACKEND_PAYMENT_VERSION}/payment/card/current`,
          this.sessionToken,
        ).catch((e) => {
          console.error(e);
          return '';
        });
        this.currentCard = newCrad;
        this.$nextTick(() => {
          this.rendering = true;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';
.group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 2%;
}
.panel {
  width: 32%;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
.component {
  display: flex;
  height: 100%;
  border: 1px solid #444;
  border-radius: 10px;
  background-color: #303030;
  padding: 1rem;
}
.card {
  background-color: #333;
  padding: 1rem 1.5rem;
  border: 1px solid #444;
}
</style>

/* eslint-disable no-restricted-globals */
import * as moment from 'moment';

const yearStartPoint = (date) => Number(moment(date)
  .month(0)
  .date(1)
  .hours(0)
  .minutes(0)
  .seconds(0)
  .milliseconds(0)
  .format('x'));

const quarterStartPoint = (fiscalEndMonth) => {
  // -1 することでmoment.jsのmonthの表記に合わせる
  const firstQuarterStartMonth = (Number(fiscalEndMonth) - 1 + 1) % 12;
  const secondQuarterStartMonth = (Number(fiscalEndMonth) - 1 + 4) % 12;
  const thirdQuarterStartMonth = (Number(fiscalEndMonth) - 1 + 7) % 12;
  const forthQuarterStartMonth = (Number(fiscalEndMonth) - 1 + 10) % 12;
  // 四半期始まりの月を小さい順に並べ、それぞれの四半期の時間幅に当てはまる月にデータを集約できるようにする、一番小さい月よりも小さいものは昨年始まりの四半期となるように調整してい計算
  const orderedMonth = [firstQuarterStartMonth, secondQuarterStartMonth, thirdQuarterStartMonth, forthQuarterStartMonth]
    .sort((a, b) => (a > b ? 1 : -1));
  return (date) => {
    const targetDateMonth = moment(date).month();
    if (orderedMonth[0] <= targetDateMonth && targetDateMonth < orderedMonth[1]) {
      return Number(moment(date)
        .subtract(targetDateMonth - orderedMonth[0], 'months')
        .date(1)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .format('x'));
    }
    if (orderedMonth[1] <= targetDateMonth && targetDateMonth < orderedMonth[2]) {
      return Number(moment(date)
        .subtract(targetDateMonth - orderedMonth[1], 'months')
        .date(1)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .format('x'));
    }
    if (orderedMonth[2] <= targetDateMonth && targetDateMonth < orderedMonth[3]) {
      return Number(moment(date)
        .subtract(targetDateMonth - orderedMonth[2], 'months')
        .date(1)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .format('x'));
    }
    if (orderedMonth[3] <= targetDateMonth) {
      return Number(moment(date)
        .subtract(targetDateMonth - orderedMonth[3], 'months')
        .date(1)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .format('x'));
    }
    if (targetDateMonth < orderedMonth[0]) {
      return Number(moment(date)
        .subtract(targetDateMonth + 12 - orderedMonth[3], 'months')
        .date(1)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .format('x'));
    }
    console.log(moment(date).format('YYYY/MM/DD'));
    return 0;
  };
};

const monthStartPoint = (date) => Number(moment(date)
  .date(1)
  .hours(0)
  .minutes(0)
  .seconds(0)
  .milliseconds(0)
  .format('x'));

const dateStartPoint = (date) => Number(moment(date)
  .hours(0)
  .minutes(0)
  .seconds(0)
  .milliseconds(0)
  .format('x'));

const weekStartPoint = (date) => {
  const weekNo = moment(date).week();
  return Number(moment(date)
    .week(weekNo)
    .day(0)
    .hours(0)
    .minutes(0)
    .seconds(0)
    .milliseconds(0)
    .format('x'));
};

const toSlice = (array, fn) => {
  const sortedArray = array.sort((a, b) => (a.date > b.date ? 1 : -1));
  return Object.entries(
    sortedArray.reduce((ac, cur) => {
      const curStartPoint = fn(cur.date);
      if (!ac[Number(curStartPoint)]) return Object.assign(ac, { [Number(curStartPoint)]: [cur] });
      ac[Number(curStartPoint)].push(cur);
      return ac;
    }, {}),
  )
    .map(([date, metric]) => ([Number(date), metric]))
    .sort((a, b) => (a[0] > b[0] ? 1 : -1));
};

export const formatDate = (dateOrg) => {
  if (isFinite(dateOrg)) return Number(moment(Number(dateOrg)).format('x'));
  console.log('dateOrg', dateOrg);
  const matched = dateOrg.match(/(?<year>\d{4})(\D)?(?<month>\d{1,2})(\D)?(?<date>\d{1,2})(\D(?<hour>\d{1,2}):(?<minute>\d{1,2}).*)?/);
  if (matched.groups) {
    const {
      year,
      month,
      date,
      hour,
      minute,
    } = matched.groups;
    return Number(moment()
      .year(Number(year))
      .month(Number(month) - 1)
      .date(Number(date))
      .hour(Number(hour) || 0)
      .minute(Number(minute) || 0)
      .seconds(0)
      .milliseconds(0));
  }
  return 0;
};

export const timestampToFormatDate = (timestamp, format) => moment(timestamp).format(format);

export const timestampToQuarterlyFormat = (timestamp, fiscalEndMonthString) => {
  const date = new Date(timestamp);
  let year = date.getFullYear();
  let month = date.getMonth() + 1; // fiscalEndMonthとのずれを合わせる
  const fiscalEndMonth = Number(fiscalEndMonthString);
  year += 1;
  if (month <= fiscalEndMonth) {
    month += 12;
    year -= 1;
  }
  const quarter = Math.ceil((month - fiscalEndMonth) / 3);
  return `FY ${year} ${quarter}Q`;
};

export const yearly = (array) => toSlice(array, yearStartPoint);
export const quarterly = (array, fiscalEndMonth) => toSlice(array, quarterStartPoint(fiscalEndMonth));
export const monthly = (array) => toSlice(array, monthStartPoint);
export const weekly = (array) => toSlice(array, weekStartPoint);
export const daily = (array) => toSlice(array, dateStartPoint);

<template>
  <b-container>
    <div v-show="isLoading" class="text-center my-5 py-5">
      <b-spinner></b-spinner>
    </div>
    <div v-show="!isLoading">
      <h1 class="mb-5">ご契約の確認</h1>
      <b-row>
        <b-col cols="12" md="8">
          <div class="d-flex flex-column h-100 desc-panel">
            <div v-show="plan" class="h-100">
              <div class="mb-5">
                <h5 class="font-weight-bold mb-3">プラン名</h5>
                <h5>{{ plan.planName }}</h5>
              </div>
              <div class="mb-5">
                <h5 class="font-weight-bold mb-3">プラン詳細</h5>
                <div v-if="planCode === 'employee'">
                  <EmployeeDetail />
                </div>
                <div v-else-if="planCode === 'salary'">
                  <SalaryDetail />
                </div>
                <div v-else>
                  <UnitDetail :planCode="planCode" :category="category" />
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="4">
          <div class="d-flex flex-column h-100 desc-panel">
            <div class="h-100">
              <div class="mb-5">
                <h5 class="font-weight-bold mb-3">お支払い方法</h5>
                <div v-if="currentCard" class="d-flex">
                  <MaskedCard :cardNo="currentCard" />
                  <div class="my-auto text-right">
                    <b-button size="sm" @click="goto('/user/subscription')">変更</b-button>
                  </div>
                </div>
                <b-form v-else class="mt-3">
                  <CardForm
                    ref="cardForm"
                    @get-token="requestPayment"
                  />
                </b-form>
              </div>
              <div class="mb-5">
                <h5 class="font-weight-bold mb-3">クーポン</h5>
                <b-form @submit.prevent>
                  <div>
                    <div v-if="!coupon.couponCode" class="d-flex">
                      <input
                        v-model="couponCode"
                        placeholder="クーポンコード"
                        class="coupon-form"
                      />
                      <div class="my-auto text-right">
                        <b-button @click="applyCoupon" variant="info">
                          <div v-show="!isLoadingCoupon" style="width:30px">適用</div>
                          <div v-show="isLoadingCoupon" class="h-100" style="width:30px"><b-spinner small></b-spinner></div>
                        </b-button>
                      </div>
                    </div>
                    <div v-else>
                      <div class="d-flex mb-2">
                        <v-icon color="white" class="mr-2 my-auto">mdi-tag-outline</v-icon>
                        <h5 class="font-weight-bold w-100 m-0 my-auto">{{ coupon.couponCode }}</h5>
                        <div class="text-right" @click="cancelCoupon">
                          <v-icon color="grey" class="my-auto click-hover">mdi-close-circle-outline</v-icon>
                        </div>
                      </div>
                      <div class="d-flex">
                        <div>割引</div>
                        <div class="text-right">- {{ amountFormatter(coupon.discount) }} <small>円</small></div>
                      </div>
                    </div>
                  </div>
                </b-form>
              </div>
              <div class="mb-5">
                <h5 class="font-weight-bold mb-3">料金</h5>
                <div v-if="!coupon.couponCode">
                  <div class="d-flex">
                    <h6>月額</h6>
                    <h6 class="text-right">{{ calcTotalAmount(plan.amount) }} <small>円</small></h6>
                  </div>
                </div>
                <div v-else>
                  <div class="d-flex">
                    <h6>初月</h6>
                    <h6 class="text-right">{{ calcTotalAmount(plan.amount, coupon.discount) }} <small>円</small></h6>
                  </div>
                  <div class="d-flex">
                    <h6>２ヶ月目〜</h6>
                    <h6 class="text-right">{{ calcTotalAmount(plan.amount) }} <small>円</small></h6>
                  </div>
                </div>
                <div class="text-right">
                  <small class="text-secondary">１ヶ月ごとに自動更新</small>
                </div>
              </div>
            </div>
            <div class="flex-shrink-1">
              <b-form-checkbox v-model="isTermsAgreed" class="mb-5">
                <b-link v-on:click='gotoPrivacyPolicy' class="text-light"><u>プライバシーポリシー</u></b-link>
                ・
                <b-link v-on:click='gotoTerms' class="text-light"><u>利用規約</u></b-link>
                に同意
              </b-form-checkbox>
              <b-alert v-model="isError" variant="danger" dismissible class="my-3">
                <b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{ errorMessage }}
              </b-alert>
              <div class="text-right">
                <b-button @click="checkout" variant="info" style="width:95px;">
                  <div v-show="!isLoadingPayment">決済を確定</div>
                  <div v-show="isLoadingPayment"><b-spinner small></b-spinner></div>
                </b-button>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex';
import router from '@/router';
import * as Http from '@/lib/http/http';
import Session from '@/lib/model/user/session';
import Init from '@/components/mixins/Init.vue';
import MaskedCard from '@/components/payment/MaskedCard.vue';
import CardForm from '@/components/payment/CardForm.vue';
import * as Analytics from '@/lib/infrastructure/google/analytics';
import UnitDetail from '@/components/planDetails/UnitDetail.vue';
import EmployeeDetail from '@/components/planDetails/EmployeeDetail.vue';
import SalaryDetail from '@/components/planDetails/SalaryDetail.vue';

export default {
  name: 'Payment',
  components: {
    MaskedCard,
    CardForm,
    UnitDetail,
    EmployeeDetail,
    SalaryDetail,
  },
  mixins: [
    Init,
  ],
  data: () => ({
    ...mapActions({
      resetCard: 'Payment/resetCard',
    }),
    sessionToken: '',
    currentCard: '',
    planType: '',
    category: '',
    planCode: '',
    plan: {},
    couponCode: '',
    coupon: {},
    isTermsAgreed: false,
    isLoading: true,
    isLoadingCoupon: false,
    isLoadingPayment: false,
    isError: false,
    errorMessage: '',
    subscription: {},
  }),
  created() {
    const { planCode, securityCode, category } = this.$route.params;
    if (planCode) {
      this.planType = 'package';
      this.planCode = planCode;
    } else if (securityCode && category) {
      this.planType = 'unit';
      this.planCode = `${securityCode}(${category})`;
      this.category = category;
    } else {
      router.push({ name: 'top' });
    }
  },
  async mounted() {
    const token = await Session.getSession();
    if (!token) {
      console.error('Session Error.');
      return;
    }
    this.sessionToken = token;
    await this.getPlan();
    await this.getCurrentCard();

    this.isLoading = false;
  },
  methods: {
    async getPlan() {
      this.plan = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_PAYMENT_DOMAIN}/${process.env.VUE_APP_BACKEND_PAYMENT_VERSION}/payment/plan/fetch/${this.planCode}`,
        this.sessionToken,
      );
    },
    async getCurrentCard() {
      const { CardNo } = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_PAYMENT_DOMAIN}/${process.env.VUE_APP_BACKEND_PAYMENT_VERSION}/payment/card/current`,
        this.sessionToken,
      ).catch((e) => {
        console.error(e);
        return {};
      });
      this.currentCard = CardNo;
    },
    async applyCoupon() {
      this.isLoadingCoupon = true;
      this.coupon = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_PAYMENT_DOMAIN}/${process.env.VUE_APP_BACKEND_PAYMENT_VERSION}/payment/coupon/${this.couponCode}`,
        this.sessionToken,
      );
      if (!this.coupon.couponCode) {
        this.isError = true;
        this.errorMessage = 'クーポンは存在しません。';
        window.scroll(0, 0);
      }
      this.couponCode = '';
      this.isLoadingCoupon = false;
    },
    cancelCoupon() {
      this.coupon = {};
    },
    goto(link) {
      router.push({ path: link });
    },
    gotoPrivacyPolicy() {
      window.open(process.env.VUE_APP_PRIVACY_POLICY);
    },
    gotoTerms() {
      window.open('/terms');
    },
    calcTotalAmount(amount, discount = 0) {
      const includeTax = Math.floor(amount * 1.1);
      const sum = includeTax - discount > 0 ? includeTax - discount : 0;
      return this.amountFormatter(sum);
    },
    amountFormatter(amount) {
      return `${String(amount).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
    },
    async checkout() {
      this.isError = false;
      this.errorMessage = '';

      if (!this.isTermsAgreed) {
        this.isError = true;
        this.errorMessage = '利用規約の同意が必要です。';
        return;
      }

      this.isLoadingPayment = true;
      if (!this.currentCard) {
        const isSuccess = await this.$refs.cardForm.getToken();
        if (!isSuccess) {
          this.isError = true;
          this.errorMessage = 'クレジットカード情報が正しくありません。';
          this.isLoadingPayment = false;
        }
      } else {
        await this.requestPayment();
      }
    },
    async requestPayment(token, holdername) {
      this.subscription = await Http.post(
        `https://${process.env.VUE_APP_BACKEND_PAYMENT_DOMAIN}/${process.env.VUE_APP_BACKEND_PAYMENT_VERSION}/payment/register/plan`,
        this.sessionToken,
        {
          holdername,
          token,
          planCode: this.planCode,
          couponCode: this.coupon.couponCode,
        },
      ).catch((e) => {
        console.log(e.response);
        this.isError = true;
      });

      this.isLoadingPayment = false;

      if (this.isError) {
        this.errorMessage = '決済に失敗しました。クレジットカード情報をご確認ください。';
        return;
      }

      this.resetCard();
      Analytics.sendSubscribeEvent(this.planCode);

      const pathName = this.planType === 'unit' ? 'paymentUnitComplete' : 'paymentPackageComplete';
      router.push({
        name: pathName,
        params: {
          planCode: this.planCode,
          category: this.category,
          subscription: this.subscription,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';

.desc-panel {
  padding: 2rem;
  background-color: #303030;
  border: 1px solid #444;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.coupon-form {
  background-color: white;
  color: black;
  padding: 0.4rem;
  margin-right: 1rem;
  border-radius: 5px;
  font-size: 15px;
  width: 100%;
}

.click-hover {
  cursor: pointer;
}
</style>

/* eslint-disable no-shadow,no-unused-vars */
import Project from '../../../lib/model/project/project';

import * as LocalStorage from '../../../lib/infrastructure/local-storage/local-storage';

const storageKeyName = `${process.env.NODE_ENV}-ContractList`;

const state = {
  contractList: [],
};

const getters = {
  getContractList: (
    state,
    _getters,
    _rootState,
    _rootGetters,
  ) => state.contractList
    .map((p) => new Project(p)),
};

const actions = {
  getAllContractFromLocal({ commit, state, getters }) {
    const list = LocalStorage.get(storageKeyName);
    commit('setContractList', list || []);
    return getters.getContractList;
  },
  setContractList({ commit, state }, contractList) {
    commit('setContractList', contractList);
    try {
      LocalStorage.upsert(storageKeyName, state.contractList);
    } catch (e) {
      console.log(e);
      commit('reset');
      LocalStorage.upsert(storageKeyName, state.contractList);
    }
  },
  removeContractFromList({ commit, state }, companyName) {
    commit('removeContractFromList', companyName);
    LocalStorage.upsert(storageKeyName, state.contractList);
  },
  resetContractList({ commit, state }) {
    commit('reset');
    LocalStorage.upsert(storageKeyName, state.contractList);
  },
};

const mutations = {
  setContractList(state, contractList) {
    state.contractList = contractList.map((p) => new Project(p));
  },
  reset(state) {
    state.contractList = [];
  },
  removeContractFromList(state, companyName) {
    state.contractList = state.contractList.filter((p) => p.companyName !== companyName);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div :id="type + '-echarts'" :ref="type" style='width:auto;height:400px;background-color:#303030;'></div>
</template>

<script>

import * as echarts from 'echarts';
import { timestampToQuarterlyFormat, timestampToFormatDate } from '../../lib/helper/time-helper';

export default {
  name: 'MetricsGraphEcharts',
  props: ['type', 'data', 'term', 'fiscalEndMonth', 'deficientList', 'scrapeInfo'],
  data: () => ({
    initialized: false,
    metricsChart: {},
    balancedMetricsList: [],
    deficientDisplayList: [],
    isDeficientVisible: true,
    observer: null,
    renderedDeficientDate: {},
  }),
  methods: {
    createObserver() {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.init();
            this.observer.unobserve(this.$refs[`${this.type}`]);
          }
        });
      }, { threshold: 0.01 });
      this.observer.observe(this.$refs[`${this.type}`]);
    },
    init() {
      if (!this.type || !this.data || !Array.isArray(this.data) || this.data.length === 0) return;

      this.metricsChart = echarts.init(document.getElementById(`${this.type}-echarts`));
      this.update();

      this.metricsChart.on('click', (e) => {
        if (e.name === 'x-legend') {
          this.isDeficientVisible = !this.isDeficientVisible;
          this.update();
        }
      });
      window.addEventListener('resize', () => {
        this.metricsChart.resize();
      });
      this.initialized = true;
    },
    update() {
      this.createBalanceData();
      this.balancedMetricsList = this.data;
      this.deficientDisplayList = (this.deficientList) ? this.deficientList.split('、').map((elm) => elm.split('~')) : [];

      const option = {
        title: {
          // text: this.type,
          text: '',
        },
        tooltip: {
          trigger: 'axis',
          extraCssText: 'max-width:75%; white-space:pre-wrap;',
          axisPointer: {
            type: 'cross',
            label: {
              formatter(obj) {
                if (obj.axisDimension === 'x') {
                  return timestampToFormatDate(obj.value, 'YYYY/MM/DD');
                }
                return obj.value.toFixed(2);
              },
            },
          },
          formatter: (params) => {
            // console.dir(params);
            let content = this.term === 'quarterly' ? timestampToQuarterlyFormat(params[0].axisValue, this.fiscalEndMonth) : timestampToFormatDate(params[0].axisValue, 'ddd, YYYY/MM/DD');
            params.forEach((param) => {
              const currentIndex = param.dataIndex;
              const currentValue = (!param.value[1] && param.value[1] !== 0) ? '' : (Number.isInteger(param.value[1]) ? param.value[1] : Number(param.value[1].toFixed(2))).toLocaleString();
              let lastYearValue = null;
              let yoyText = '';
              if (this.scrapeInfo) {
                if (this.term === 'quarterly' || (this.term === 'origin' && this.scrapeInfo.intervalNum + this.scrapeInfo.intervalUnit === '1quarter')) {
                  lastYearValue = (this.balancedMetricsList[currentIndex - 4]) ? this.balancedMetricsList[currentIndex - 4].metrics[param.seriesName] : null;
                  yoyText = `(前年同期比:${((lastYearValue) ? ((param.value[1] / lastYearValue) * 100).toFixed(2) : '-')}%)`;
                } else if (this.term === 'monthly' || (this.term === 'origin' && this.scrapeInfo.intervalNum + this.scrapeInfo.intervalUnit === '1month')) {
                  lastYearValue = (this.balancedMetricsList[currentIndex - 12]) ? this.balancedMetricsList[currentIndex - 12].metrics[param.seriesName] : null;
                  yoyText = `(前年同月比:${((lastYearValue) ? ((param.value[1] / lastYearValue) * 100).toFixed(2) : '-')}%)`;
                }
              }
              content += `<br />${param.marker} ${param.seriesName} <span style="float:right;margin-left:20px;font-size:14px;color:#00000;font-weight:900">${currentValue}${yoyText}</span>`;
            });
            return content;
          },
        },
        legend: {
          bottom: 10,
          textStyle: {
            color: '#eee',
          },
          inactiveColor: '#999',
          type: 'scroll',
        },
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: 'none',
            },
            // restore: {},
            saveAsImage: {},
          },
        },
        xAxis: {
          type: 'time',
          axisLabel: {
            formatter: '{yyyy}/{MM}/{dd}',
            hideOverlap: true,
            fontSize: 11,
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: (val) => {
              if (val >= 1000000000) {
                return `${(val / 1000000000).toLocaleString()} G`;
              }
              if (val >= 1000000) {
                return `${(val / 1000000).toLocaleString()} M`;
              }
              if (val >= 10000) {
                return `${(val / 1000).toLocaleString()} k`;
              }
              return val.toLocaleString();
            },
          },
        },
        series: this.balancedMetricsList.reduce((array, e, idx) => {
          if (idx === 0) {
            return Object.entries(e.metrics).map(([k, v]) => ({
              name: k,
              data: [[this.data[idx].date, v]],
            }));
          }
          this.renderedDeficientDate = {};
          return Object.entries(e.metrics).map(([k, v]) => {
            const name = k;
            if (!array.find((a) => a.name === k)) {
              array.push({ name, data: [this.data[idx].date, v] });
              return { name, data: [[this.data[idx].date, v]] };
            }
            return ({
              name,
              symbolSize: 2,
              data: [...array.find((a) => a.name === k).data, [this.data[idx].date, v]],
              type: 'line',
              markArea: {
                itemStyle: {
                  color: 'rgba(200, 200, 200, 0.1)',
                  borderColor: '#BBB',
                  borderWidth: '1',
                  borderType: 'dashed',
                  borderDashOffset: '0.5',
                },
                data: this.isDeficientVisible
                  ? this.deficientDisplayList
                    .map((v2) => {
                      // 一つのグラフにメトリクスが複数存在すると欠損表示のレンジ部分が濃くなって見づらいため、一度だけレンダリングするように
                      if (!this.renderedDeficientDate[`${v2[0]}_${v2[1]}`]) {
                        this.renderedDeficientDate[`${v2[0]}_${v2[1]}`] = 1;
                        return [{ name: 'x', xAxis: `${v2[0]} 00:00` }, { xAxis: (v2.length > 1) ? `${v2[1]} 23:59` : `${v2[0]} 23:59` }];
                      }
                      return null;
                    })
                    .filter((d) => d)
                  : [],
                tooltip: {
                  trigger: 'item',
                  formatter: (params) => `データ欠落: ${params.data.coord[0][0]} 〜 ${params.data.coord[1][0]}`,
                },
              },
            });
          });
        }, []),
        graphic: this.deficientList
          ? [
            {
              name: 'x-legend',
              tooltip: {
                show: false,
              },
              type: 'text',
              z: 100,
              right: 3,
              top: 30,
              style: {
                fill: this.isDeficientVisible ? '#eee' : '#999',
                width: 100,
                overflow: 'break',
                text: 'x:データ欠落',
                font: '12px Roboto',
              },
            },
          ] : [],
      };

      this.metricsChart.setOption(option);
    },
    createBalanceData() {
      const metricsKeysList = this.data.map((e) => Object.keys(e.metrics));
      // console.log(metricsKeysList);
      const balanceFlag = metricsKeysList.every((keys, idx) => {
        if (idx === 0) return true;
        return keys.length === metricsKeysList[idx - 1].length;
      });
      this.balancedMetricsList = this.data;
      if (balanceFlag) {
        return;
      }
      this.deficientDisplayList = this.deficientList;
      const positionOfMaxMetricsKeysInData = metricsKeysList.reduce((maxPosition, cur, idx) => {
        if (cur.length > maxPosition.keys.length) return { idx, keys: cur };
        return maxPosition;
      }, { idx: 0, keys: metricsKeysList[0] });
      const emptyEntity = positionOfMaxMetricsKeysInData.keys
        .reduce((entity, key) => Object.assign(entity, { [key]: null }), {});
      const copy = JSON.parse(JSON.stringify(this.data));
      this.balancedMetricsList.map((entity, idx) => {
        const assigned = Object.assign(JSON.parse(JSON.stringify(emptyEntity)), copy[idx].metrics);
        return this.$set(
          entity,
          'metrics',
          assigned,
        );
      });
    },
  },
  created() {
  },
  mounted() {
    this.createObserver();
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  watch: {
    data() {
      if (this.observer && this.initialized) this.update();
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <b-container class="for-personal">
    <h1 class="mb-5">FAQ よくある質問</h1>
    <div v-for="(collapse, index) in collapses" :key="index">
      <b-card class="bg-dark card-style">
        <!--他を閉じる -->
        <!-- <div
          v-b-toggle="collapse.id"
          @click="closeOther(index)"
        > -->
        <div
          v-b-toggle="collapse.id"
          class="d-flex"
        >
          <div class="qa-title col-11 p-0">
            <div class="qa mr-2 float-left">Q.</div>
            <div>{{ collapse.question }}</div>
          </div>
          <div class="coll-1 d-flex align-items-center">
            <v-icon color="white" class="when-closed justify-content-end">mdi-chevron-down</v-icon>
            <v-icon color="white" class="when-open justify-content-end">mdi-chevron-up</v-icon>
          </div>
        </div>
        <b-collapse v-model="collapse.show" :id="collapse.id" class="">
          <hr class="border-secondary">
          <div class="d-flex fit">
            <div class="qa mr-2">A.</div>
            <div v-html="collapse.answer"></div>
          </div>
        </b-collapse>
      </b-card>
    </div>
  </b-container>
</template>

<script>
import Init from '@/components/mixins/Init.vue';

export default {
  name: 'Faq',
  mixins: [
    Init,
  ],
  components: {
  },
  data: () => ({
    // 参考 https://tutorialmore.com/questions-1707858.htm
    collapses: [
      {
        id: 'collapse-0',
        question: 'PERAGARUとは、どのようなものですか？',
        answer:
`日本株に紐づいたオルタナティブデータを閲覧できるサービスです。
データをチャートで視覚的に捉えることや、CSVでダウンロードして財務モデルに組み込むなど、多様な使い方が可能です。`,
        show: false,
      },
      {
        id: 'collapse-1',
        question: 'PERAGARUの優れているところを教えてください。',
        answer:
`個別銘柄ごとにKPIに関するデータを閲覧できる点が挙げられます。
オルタナティブデータの収集はマクロ経済や業界・業種など広い単位で行われることが多いですが、本サービスのデータはデータ収集アイデアの考案から収集までを1銘柄ずつ行っています。`,
        show: false,
      },
      {
        id: 'collapse-2',
        question: 'PERAGARUはどのような人が活用していますか？',
        answer: '運用資産が大きい個人投資家、ヘッジファンドのマネージャーなど、ご自身で資産運用を行う立場の方が活用して下さっています。',
        show: false,
      },
      {
        id: 'collapse-3',
        question: 'PERAGARUはどのように作られていますか？',
        answer: 'Web上に公開されているデータを、個別銘柄ごとにデータ収集システムを作成して取得しています。',
        show: false,
      },
      {
        id: 'collapse-4',
        question: '閲覧できるデータの総期間を教えてください。',
        answer:
`期間は各データによって異なりますが、2019年以降に取得したデータが中心です。
データ取得時点以降のデータは基本的に全ての期間の閲覧が可能です。`,
        show: false,
      },
      {
        id: 'collapse-5',
        question: 'データの粒度と更新頻度を教えてください。',
        answer: '各データによって異なりますが、多くが日次または週次でデータ取得し、同様の頻度で更新されます。',
        show: false,
      },
      {
        id: 'collapse-6',
        question: 'PERAGARUではどのようなセクターが閲覧できるのでしょうか？',
        answer: 'カバーセクターは様々ですが、オンラインで顧客接点を持つ銘柄が多い特徴があります。',
        show: false,
      },
    ],
  }),
  created() {},
  mounted() {},
  methods: {
    closeOther(index) {
      this.collapses.forEach((e, i) => {
        if (i !== index) e.show = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';
.collapsed > div > .when-open,
.not-collapsed > div > .when-closed {
  display: none;
}

.card-style {
  margin-bottom: 2rem;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.qa-title {
  font-size: 18px;
  display: flex; /* 1~2文字下にはみ出す場合があるのを防ぐ */
}

.fit {
  width: fit-content;
}

</style>

<template>
  <b-container>
    <div class="panel-form wide">
      <div class="panel-form-content">
        <template v-if="phase === 'INPUT'">
          <div class="text-center">
            <h5 class="mb-4">新規会員登録</h5>
          </div>
          <b-form>
            <b-form-group
            description="携帯キャリアのドメインはご利用いただけません(ezweb.ne.jp, docomo.ne.jp, softbank.ne.jpなど)">
              <b-form-input
                type="email"
                v-model="email"
                id="email"
                class="form-control-lg"
                placeholder="メールアドレス"
                :state="emailValidation"></b-form-input>
              <b-form-invalid-feedback :state="emailValidation">{{ emailValidationMessage }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
              <b-form-input
                type="text"
                v-model="confirmedEmail"
                id="confirmedEmail"
                class="form-control-lg"
                placeholder="メールアドレス（再入力）"
                @focusout="checkConfirmedEmail"></b-form-input>
            </b-form-group>
            <b-form-group
              description="大文字・小文字・数字を含む10文字以上">
              <b-form-input
                type="password"
                v-model="password"
                id="password"
                class="form-control-lg"
                placeholder="パスワード"
                :state='passwordValidation'></b-form-input>
              <b-form-invalid-feedback :state="passwordValidation">{{ passwordValidationMessage }}</b-form-invalid-feedback>
              <b-form-invalid-feedback :state="passwordValidation">{{ passwordValidationMessageIncludeUppercase }}</b-form-invalid-feedback>
              <b-form-invalid-feedback :state="passwordValidation">{{ passwordValidationMessageIncludeLowercase }}</b-form-invalid-feedback>
              <b-form-invalid-feedback :state="passwordValidation">{{ passwordValidationMessageIncludeNumber }}</b-form-invalid-feedback>
              <b-form-invalid-feedback :state="passwordValidation">{{ passwordValidationMessageLength }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
              <b-form-input
                type="password"
                v-model="confirmedPassword"
                id="confirmedPassword"
                class="form-control-lg"
                placeholder="パスワード（再入力）"
                @focusout="checkConfirmedPassword"></b-form-input>
            </b-form-group>
            <b-form-group>
              <b-form-radio v-model="investorType" value="individual">個人投資家</b-form-radio>
              <b-form-radio v-model="investorType" value="institutional">機関投資家</b-form-radio>
              <b-form-invalid-feedback :state="!!investorType">{{ investorTypeValidationMessage }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
              <b-form-select
                id="investAmount"
                v-model="investAmount"
                :options="investAmountOptions"
              ></b-form-select>
              <b-form-invalid-feedback :state="!!investAmount">{{ investAmountValidationMessage }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              class="pt-2"
            >
              <div>
                PERAGARUに登録することで、
                <b-link v-on:click='gotoPrivacyPolicy'>プライバシーポリシー</b-link>
                ・
                <b-link v-on:click='gotoTerms'>利用規約</b-link>
                に同意したものとみなします。
              </div>
            </b-form-group>
            <div>
              <b-button
                v-on:click="next"
                class="btn btn-primary btn-block signup-next-btn">次へ</b-button>
            </div>
          </b-form>
        </template>

        <template v-else>
          <loading
            :active.sync="isLoading"
            loader="spinner"
            color="#E22B6F"
            opacity=0.3
          ></loading>
          <div class="text-center">
            <h5 class="mb-4">新規会員登録（確認）</h5>
          </div>
          <b-row>
            <b-col cols="3" class="mt-5">メールアドレス</b-col>
            <b-col cols="9" class="mt-5">{{ email }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3" class="mt-5">投資家タイプ</b-col>
            <b-col cols="9" class="mt-5">{{ investorType == 'individual' ? '個人投資家' : '機関投資家' }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3" class="mt-5">投資金額</b-col>
            <b-col cols="9" class="mt-5">{{ investAmountOptions.find((element) => element.value == investAmount).text }}</b-col>
          </b-row>
          <b-row>
            <b-col class="mt-5">
              <b-button
              v-on:click="back"
              :disabled="isSending"
              class="btn btn-secondary back-btn w-100">戻る</b-button>
            </b-col>
            <b-col class="mt-5">
              <b-button
                v-on:click="doSignup"
                :disabled="isSending"
                class="btn btn-primary execute-btn w-100"
              >アカウント作成</b-button>
            </b-col>
          </b-row>
        </template>
      </div>
    </div>
  </b-container>
</template>

<script>
import Loading from 'vue-loading-overlay';
import { emailToUsername } from '@/lib/helper/username-converter';
import Signup from '@/lib/model/user/signup';
import router from '@/router';

import * as Http from '@/lib/http/http';

import Init from '@/components/mixins/Init.vue';
import * as Analytics from '@/lib/infrastructure/google/analytics';

export default {
  name: 'Signup',
  mixins: [
    Init,
  ],
  components: {
    Loading,
  },
  data: () => ({
    phase: 'INPUT',
    isSending: false,
    isSuccess: false,
    isExistingError: false,
    inquiryFormLink: process.env.VUE_APP_GOOGLE_FORM_LINK,
    email: '',
    emailValidationMessage: '',
    confirmedEmail: '',
    password: '',
    passwordValidationMessage: '',
    passwordValidationMessageIncludeUppercase: '',
    passwordValidationMessageIncludeLowercase: '',
    passwordValidationMessageIncludeNumber: '',
    passwordValidationMessageLength: '',
    confirmedPassword: '',
    loginErrorMessage: '',
    status: '',
    signInStatus: {},
    investorType: '',
    investorTypeValidationMessage: '',
    investAmount: null,
    investAmountOptions: [
      { text: '運用金額', value: null, disabled: true },
      { text: '~1000万円', value: 1 },
      { text: '1000万~5000万円', value: 2 },
      { text: '5000万~1億円', value: 3 },
      { text: '1億円以上', value: 4 },
    ],
    investAmountValidationMessage: '',
    isLoading: false,
  }),
  mounted() {},
  methods: {
    showMsgModal(title, msg) {
      this.$bvModal.msgBoxOk(msg, {
        title: `${title}`,
        size: 'mid',
        buttonSize: 'mid',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      });
    },
    showCompleteModal(title, msg) {
      this.$bvModal.msgBoxOk(msg, {
        title: `${title}`,
        size: 'mid',
        buttonSize: 'mid',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      }).then(() => {
        router.push({ path: '/verify' });
      });
    },
    next() {
      if (this.emailValidation
      && this.passwordValidation
      && this.investorType
      && this.investAmount) {
        this.phase = 'CONFIRM';
        this.scrollTop();
      }
      if (!this.investorType) {
        this.investorTypeValidationMessage = '※必須です';
      }
      if (!this.investAmount) {
        this.investAmountValidationMessage = '※必須です';
      }
    },
    back() {
      this.phase = 'INPUT';
      this.isExistingError = false;
    },
    async doSignup() {
      this.isLoading = true;
      this.isSending = true;
      let flag = false;
      flag = flag || await this.checkEmailExisting();
      if (flag) {
        this.isLoading = false;
        this.isExistingError = true;
        this.isSending = false;
        this.showMsgModal('確認してください', '名前かメールアドレスが既に登録されています');
        return;
      }
      const username = emailToUsername(this.email);
      const res = await Signup.doSignup({
        username,
        email: this.email,
        password: this.password,
      });
      let isSuccessRegisterAccount = true;
      await Http.post(
        `https://${process.env.VUE_APP_BACKEND_USER_DOMAIN}/${process.env.VUE_APP_BACKEND_USER_VERSION}/user/registerAccount`,
        '',
        {
          username,
          email: this.email,
          investorType: this.investorType,
          investAmount: this.investAmount,
        },
      ).catch((err) => {
        console.error(err);
        isSuccessRegisterAccount = false;
        return err;
      });

      this.isLoading = false;
      this.isSending = false;
      if (res && isSuccessRegisterAccount) {
        Analytics.sendSignUpEvent();
        this.showCompleteModal('登録完了', 'あなたのメールアドレスに認証コードを送りました。\nメールのリンクをクリックしてメールアドレスの認証を完了させてください。\nメールが届かない場合はお問い合わせよりご連絡ください。');
      } else {
        this.showMsgModal('登録エラー', 'エラーが発生しました。\nお手数ですがお問い合わせよりご連絡ください。');
      }
    },
    checkUsernameExisting() {
      const username = emailToUsername(this.email);
      return Http.post(
        `https://${process.env.VUE_APP_BACKEND_USER_DOMAIN}/${process.env.VUE_APP_BACKEND_USER_VERSION}/personal/user/check/username`,
        '',
        { username },
      )
        .catch((err) => {
          console.error(err);
          return [];
        });
    },
    async checkEmailExisting() {
      return Http.post(
        `https://${process.env.VUE_APP_BACKEND_USER_DOMAIN}/${process.env.VUE_APP_BACKEND_USER_VERSION}/personal/user/check/email`,
        '',
        { email: this.email },
      ).catch((err) => {
        console.error(err);
        return [];
      });
    },
    checkConfirmedEmail() {
      if (this.confirmedEmail !== this.email) this.showMsgModal('確認してください', 'メールアドレスが再入力されたメールアドレスと一致していません');
    },
    checkConfirmedPassword() {
      if (this.confirmedPassword !== this.password) this.showMsgModal('確認してください', 'パスワードが再入力されたパスワードと一致していません');
    },
    gotoPrivacyPolicy() {
      window.open(process.env.VUE_APP_PRIVACY_POLICY);
    },
    gotoTerms() {
      window.open('/terms');
    },
  },
  computed: {
    emailValidation() {
      // 必須 && email形式 && 空白文字が含まれない
      // eslint-disable-next-line no-control-regex
      return this.email !== '' && !!this.email.match(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i) && !this.email.match(/[^\x01-\x7E\xA1-\xDF]+/);
    },
    passwordValidation() {
      // ref) https://qiita.com/mpyw/items/886218e7b418dfed254b#%E5%8D%8A%E8%A7%92%E8%8B%B1%E6%95%B0%E5%AD%97%E8%A8%98%E5%8F%B7%E3%82%92%E3%81%9D%E3%82%8C%E3%81%9E%E3%82%8C1%E7%A8%AE%E9%A1%9E%E4%BB%A5%E4%B8%8A%E5%90%AB%E3%82%808%E6%96%87%E5%AD%97%E4%BB%A5%E4%B8%8A100%E6%96%87%E5%AD%97%E4%BB%A5%E4%B8%8B%E3%81%AE%E6%AD%A3%E8%A6%8F%E8%A1%A8%E7%8F%BE
      // 必須 && 半角英数(記号も含めてOK) && 10文字以上
      return this.password !== '' && !!this.password.match(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d!-/:-@[-`{}-~\]]{10,}$/);
    },
  },
  watch: {
    email() {
      if (this.email === '') {
        this.emailValidationMessage = '※必須です';
      }
      if (this.email !== ''
        && (
          !this.email.match(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i)
          // eslint-disable-next-line no-control-regex
          || !!this.email.match(/[^\x01-\x7E\xA1-\xDF]+/)
        )
      ) {
        this.emailValidationMessage = 'メールアドレスの形式に誤りがあります';
      }
    },
    password() {
      if (this.password === '') {
        this.passwordValidationMessage = '※必須です';
        this.passwordValidationMessageIncludeUppercase = '';
        this.passwordValidationMessageIncludeLowercase = '';
        this.passwordValidationMessageIncludeNumber = '';
        this.passwordValidationMessageLength = '';
      }
      if (this.password !== '' && !this.password.match(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d!-/:-@[-`{}-~\]]{10,}$/)) {
        this.passwordValidationMessage = 'パスワードの形式に誤りがあります';
        if (!this.password.match(/[A-Z]/)) {
          this.passwordValidationMessageIncludeUppercase = '大文字が含まれていません';
        } else {
          this.passwordValidationMessageIncludeUppercase = '';
        }
        if (!this.password.match(/[a-z]/)) {
          this.passwordValidationMessageIncludeLowercase = '小文字が含まれていません';
        } else {
          this.passwordValidationMessageIncludeLowercase = '';
        }
        if (!this.password.match(/\d/)) {
          this.passwordValidationMessageIncludeNumber = '数字が含まれていません';
        } else {
          this.passwordValidationMessageIncludeNumber = '';
        }
        if (this.password.length < 10) {
          this.passwordValidationMessageLength = '10文字以上にしてください';
        } else {
          this.passwordValidationMessageLength = '';
        }
      }
    },
  },
};
</script>

<style scoped>

</style>

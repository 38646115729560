<template>
  <div>
    <div>
      <h5>PERAGARUデータ閲覧</h5>
      <ul>
        <li>個別銘柄画面上で、上場企業とその子会社の合計被保険者数の時系列データの閲覧権限</li>
        <li>従業員数一覧画面上で、上場企業とその子会社の合計被保険者数の時系列データの閲覧権限</li>
        <li>従業員数データのファイルダウンロード（csv、json形式）権限</li>
        <li>従業員数一覧データのファイルダウンロード（csv形式）権限</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmployeeDetail',
  data: () => ({}),
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';
</style>

export const setUserId = (userhash) => sendEvent({ user_id: userhash.substring(0, 36) });
export const resetUserId = () => sendEvent({ user_id: '' });
export const sendSignUpEvent = () => {
  const cookies = window.document.cookie.split(';').map((c) => {
    const kv = c.trim().split('=');
    return { [kv[0]]: kv[1] };
  });
  const refCookie = cookies.find((c) => c.ref);
  const ref = refCookie ? refCookie.ref : '';
  window.document.cookie = 'ref=; max-age=0';
  sendEvent({ event: 'gmt_sign_up', ref });
};
export const sendVerifyEvent = () => sendEvent({ event: 'gmt_verify' });
export const sendSubscribeEvent = (planCode) => sendEvent({ event: 'gmt_subscribe', plan_code: planCode });
export const sendIsPaidUserEvent = () => sendEvent({ event: 'gmt_is_paid_user' });
export const sendIsUserEvent = () => sendEvent({ event: 'gmt_is_user' });

export const sendEvent = (events) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ...events });
};

/* eslint-disable no-shadow,no-unused-vars,no-param-reassign */
import Metrics from '../../../lib/model/project/metrics';

const state = {
  contractedPlanList: [],
};

const getters = {
  getContractedPlanList: (
    state,
    _getters,
    _rootState,
    _rootGetters,
  ) => () => state.contractedPlanList,
  getContractedPlanCodeList: (state, _getters, _rootState, _rootGeters) => state.contractedPlanList.map((plan) => plan.planCode),
};

const actions = {
  getContractedPlanList({ commit, state, getters }) {
    return getters.getContractedPlanList();
  },
  setContractedPlanList({ commit, state, getters }, list) {
    commit('setContractedPlanList', list);
  },
  resetContractedPlanList({ commit, state, getters }) {
    commit('reset');
  },
  checkContractedWithPlanCode({ commit, state, getters }, planCode) {
    return state.contractedPlanList.some((plan) => plan.planCode === planCode);
  },
  checkContractedWithPlanCodeForIncludingIndividual({ commit, state, getters }, planCode) {
    return state.contractedPlanList.some((plan) => plan.planCode.includes(planCode));
  },
};

const mutations = {
  setContractedPlanList(state, list) {
    state.contractedPlanList = list;
  },
  reset(state) {
    state.contractedPlanList = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

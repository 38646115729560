import * as moment from 'moment';
import { keyCheck } from '../helper/object-helper';

export default class BaseEntity {
  constructor(obj) {
    this.id = keyCheck(obj, 'id') ? obj.id : '';
    this.date = keyCheck(obj, 'date') ? obj.date : Number(moment().format('x'));
    this.key = keyCheck(obj, 'key') ? obj.key : '';
    this.value = keyCheck(obj, 'value') ? obj.value : '';
    this.deleteFlag = keyCheck(obj, 'deleteFlag') ? obj.deleteFlag : false;
    this.createdDate = keyCheck(obj, 'createdDate') ? obj.createdDate : Number(moment().format('x'));
    this.updatedDate = keyCheck(obj, 'updatedDate') ? obj.updatedDate : Number(moment().format('x'));
    this.deletedDate = keyCheck(obj, 'deletedDate') ? obj.deletedDate : 0;
  }

  static default() {
    return new BaseEntity();
  }
}

import axios from 'axios';

export const get = (url, token, headers = {}) => {
  const params = {
    method: 'get',
    url,
  };
  params.headers = headers;
  if (token) {
    params.headers.Authorization = token;
  }
  params.headers['Content-Type'] = 'application/json';
  return axios(params)
    .then((res) => res.data)
    .catch((e) => {
      console.error(e);
      return [];
    });
};

export const post = (url, token, data, headers = {}) => {
  const params = {
    method: 'post',
    url,
    data,
  };
  params.headers = headers;
  if (token) {
    params.headers.Authorization = token;
  }
  params.headers['Content-Type'] = 'application/json';
  return axios(params)
    .then((res) => res.data)
    .catch((e) => {
      console.error(e);
      throw e;
    });
};

<template>
  <b-row align-v="start">
    <b-col cols="12" order-lg="0">
      <b-alert v-model="isSuccess" variant="success" dismissible>
        <b-icon-patch-exclamation-fill></b-icon-patch-exclamation-fill> 更新が完了しました。
      </b-alert>
      <b-alert v-model="isError" variant="danger" dismissible>
        <b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> 変更に失敗しました。お手数ですが、下記のリンクよりお問い合わせください。
        <a href="#"
          @click="goto('/contact')">
          お問い合わせ
        </a>
      </b-alert>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'NotificationModal',
  props: ['isSuccess', 'isError'],
};
</script>
<style>
</style>

<template>
  <b-container class="payment">
    <b-row align-v="start" class="">
      <b-col cols="12" order-lg="0">
        <h1 class="my-4 h1-title">退会手続きが完了しました</h1>
      </b-col>
    </b-row>
    <b-row class="">
      <b-col cols="12" order-lg="0">
        <h2 class="my-4">PERAGARUをご利用いただきありがとうございます</h2>
        <h2 class="my-4">またのご利用をお待ちしております</h2>
      </b-col>
    </b-row>
    <b-button
      @click="goBackSignin"
      class="btn btn-block btn-info mt-5">戻る
    </b-button>
  </b-container>
</template>

<script>
import router from '@/router';

export default {
  components: {},
  props: ['isDeleted'],
  name: 'UserDeleteComplate',
  data: () => ({}),
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      if (!this.isDeleted) {
        router.push({ path: '/' }).catch(() => {});
      }
    },
    goBackSignin() {
      router.push({ path: '/' }).catch(() => {});
    },
  },
};
</script>

<template>
  <div class="group mb-10">
    <div v-for='plan in projectPlanList' :key='plan.id' class="panel">
      <div class="component d-flex flex-column">
        <div class="h-100">
          <h6 class="text-secondary">個別銘柄カテゴリープラン</h6>
          <h5 class="mb-4">{{ plan.planName }}</h5>
        </div>
        <div class="d-flex">
          <h5 class="m-0">月額： {{ amountFormatter(plan.amount) }} <small>円</small></h5>
          <div class="text-right">
            <b-button
              @click="goto(`/payment/unit/${getUnitPaymentPath(plan.planCode)}`)"
              cols="auto" class="buy-button"
              :variant="!isMember || isContracted(plan.planCode) ? 'secondary' : 'info'"
              :disabled="!isMember || isContracted(plan.planCode)"
            >選択</b-button>
          </div>
        </div>
      </div>
    </div>
    <div v-for='plan in packagePlanList' :key='plan.id' class="panel">
      <div class="component d-flex flex-column">
        <div class="h-100">
          <h6 class="text-secondary">パッケージプラン</h6>
          <h5 class="mb-4">{{ plan.planName }}</h5>
        </div>
        <div class="d-flex">
          <h5 class="m-0">月額： {{ amountFormatter(plan.amount) }} <small>円</small></h5>
          <div class="text-right">
            <b-button
              @click="goto(`/payment/package/${plan.planCode}`)"
              cols="auto" class="buy-button"
              :variant="!isMember || isContracted(plan.planCode) ? 'secondary' : 'info'"
              :disabled="!isMember || isContracted(plan.planCode)"
            >選択</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router';

export default {
  name: 'ProjectPlan',
  props: ['isMember', 'userPlans', 'projectPlanList', 'packagePlanList'],
  components: {},
  methods: {
    isContracted(planCode) {
      return this.userPlans.find((plan) => plan.planCode === planCode);
    },
    amountFormatter(amount) {
      const includeTax = Math.floor(amount * 1.1);
      return String(includeTax).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    },
    getUnitPaymentPath(planCode) {
      return planCode.replace(/\(/, '/').replace(/\)/, '');
    },
    goto(link) {
      router.push({ path: link });
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';
.group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 2%;
}
.panel {
  width: 23%;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
.component {
  height: 100%;
  border: 1px solid #444;
  background-color: #303030;
  padding: 1.5rem;
}
.buy-button {
  margin: 0 auto;
  padding: 0 0.5rem;
  border-radius: 0;
}
</style>

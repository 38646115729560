<template>
  <div class="plan-panel">
    <h3 class="font-weight-bold mb-4">エンタープライズプラン</h3>
    <div class="mb-5 h-100">
      <div>全銘柄を対象としたPERAGARUデータ閲覧権限を提供するプランです。</div>
      <div>対象カテゴリーについてはご要望ください。</div>
    </div>
    <!-- <div class="mb-4 h-100">
      <div class="d-flex flex-column flex-xl-row align-items-start">
        <img src="/logo-white.png" class="h-auto w-100 px-20 py-10">
      </div>
    </div> -->
    <div class="mb-5">
      <h5 class="mb-3 font-weight-bold">料金</h5>
      <h5>ASK</h5>
    </div>
    <div class="text-right">
      <b-button class="top-button" @click="goto('contact')">お問い合わせ</b-button>
    </div>
  </div>
</template>

<script>
import router from '../../router';

export default {
  name: 'Enterprise',
  methods: {
    goto(link) {
      router.push({ path: link });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';
.plan-panel {
  display: flex;
  flex-direction: column;
  background-color: #333;
  border: 1px solid #6c757d;
  padding: 2rem;
}
</style>

<template>
  <b-container>
    <div class="panel-form">
      <div class="panel-form-content">
        <div class="text-center">
          <h5 class="mb-4">{{ titleForStatus }}</h5>
        </div>
        <b-form>
          <b-form-group>
            <label for="username" class="sr-only">お名前またはメールアドレス</label>
            <b-form-input
              type="text"
              v-model="username"
              id="username"
              :readonly="status === 'newPasswordRequired' || status === 'passwordResetRequired'"
              class="form-control-lg"
              placeholder="お名前またはメールアドレス"
              :state="usernameValidation" required></b-form-input>
            <b-form-invalid-feedback :state="usernameValidation">※必須です</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group>
            <label for="password" class="sr-only">パスワード</label>
            <b-form-input
              type="password"
              v-model="password"
              id="password"
              :readonly="status === 'newPasswordRequired' || status === 'passwordResetRequired'"
              class="form-control-lg"
              placeholder="パスワード"
              :state='passwordValidation' required></b-form-input>
            <b-form-invalid-feedback :state="passwordValidation">※必須です</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            v-if="status === 'newPasswordRequired' || status === 'passwordResetRequired'">
            <label for="newPassword" class="sr-only">新しいパスワード</label>
            <b-form-input
              type="password"
              v-model="newPassword"
              id="newPassword"
              class="form-control-lg"
              placeholder="新しいパスワード"
              :state='newPasswordValidation' required></b-form-input>
            <b-form-invalid-feedback :state="newPasswordValidation">{{ passwordValidationMessageIncludeUppercase }}</b-form-invalid-feedback>
            <b-form-invalid-feedback :state="newPasswordValidation">{{ passwordValidationMessageIncludeLowercase }}</b-form-invalid-feedback>
            <b-form-invalid-feedback :state="newPasswordValidation">{{ passwordValidationMessageIncludeNumber }}</b-form-invalid-feedback>
            <b-form-invalid-feedback :state="newPasswordValidation">{{ passwordValidationMessageLength }}</b-form-invalid-feedback>

          </b-form-group>
          <b-form-group
            v-if="status === 'newPasswordRequired' || status === 'passwordResetRequired'">
            <label for="password" class="sr-only">新しいパスワード（再入力）</label>
            <b-form-input
              type="password"
              v-model="retypedNewPassword"
              id="password"
              class="form-control-lg"
              placeholder="新しいパスワード（再入力）"
              :state='retypedNewPasswordValidation' required></b-form-input>
            <b-form-invalid-feedback :state="retypedNewPasswordValidation">上記の入力と一致しません</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            v-if="status === 'passwordResetRequired'">
            <label for="password" class="sr-only">認証コード</label>
            <b-form-input
              type="text"
              v-model="verificationCode"
              id="verificationCode"
              class="form-control-lg"
              placeholder="認証コード"
              :state='verificationCodeValidation' required></b-form-input>
            <b-form-invalid-feedback :state="verificationCodeValidation">※必須です</b-form-invalid-feedback>
          </b-form-group>
          <div>
            <b-button
              v-on:click="doSubmit"
              v-if="status === 'Signin'"
              class="btn btn-primary btn-block execute-btn">ログインする</b-button>
            <b-button
              v-on:click="setNewPassword"
              v-else-if="status === 'newPasswordRequired'"
              class="btn btn-primary btn-block execute-btn">新しいパスワードに変更</b-button>
            <b-button
              v-on:click="resetPassword"
              v-else-if="status === 'passwordResetRequired'"
              class="btn btn-primary btn-block execute-btn">パスワードをリセットする</b-button>
          </div>
        </b-form>
      </div>
      <p class="text-center mt-4">アカウントをお持ちでない方は
        <b-link to="signup">新規登録</b-link>
      </p>
      <p class="text-center mt-4">パスワードをお忘れの方は
        <b-link to="/forget/password">パスワード変更</b-link>
      </p>
    </div>
  </b-container>
</template>

<script>
import router from '@/router';
import Signin from '@/lib/model/user/signin';
import Session from '@/lib/model/user/session';
import * as Http from '@/lib/http/http';

export default {
  name: 'UserSign',
  props: [
    'type',
  ],
  data: () => ({
    username: '',
    password: '',
    newPassword: '',
    retypedNewPassword: '',
    invalidNewPasswordMessage: '',
    passwordValidationMessageIncludeUppercase: '',
    passwordValidationMessageIncludeLowercase: '',
    passwordValidationMessageIncludeNumber: '',
    passwordValidationMessageLength: '',
    loginErrorMessage: '',
    verificationCode: '',
    status: '',
    titleForStatus: '',
    signInStatus: {},
    isSubmited: false,
  }),
  methods: {
    async doSubmit() {
      this.isSubmited = true;
      switch (this.status) {
        case 'Signin':
          if (this.usernameValidation
          && this.passwordValidation) {
            await this.doSignin();
          }
          break;
        case 'newPasswordRequired':
          if (this.newPasswordValidation
          && this.retypedNewPasswordValidation) {
            await this.setNewPassword();
          }
          break;
        case 'passwordResetRequired':
          if (this.newPasswordValidation
          && this.retypedNewPasswordValidation
          && this.verificationCodeValidation) {
            await this.resetPassword();
          }
          break;
        default:
          break;
      }
    },
    showMsgModal(title, msg) {
      this.$bvModal.msgBoxOk(msg, {
        title: `${title}`,
        size: 'mid',
        buttonSize: 'mid',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      });
    },
    async doSignin() {
      const signInStatus = await Signin.doSignin({
        username: this.username,
        password: this.password,
      })
        .catch((err) => {
          // ログイン失敗時にここを通ってない？
          console.error(err);
          console.error('Signin failed.');
          return '';
        });
      this.loginErrorMessage = '';
      if (signInStatus.success()) {
        await this.registerUserLogin();
        if ((await this.getSubscription()).length) {
          router.push({ path: 'top' });
        } else {
          router.push({ path: 'list' });
        }
      }
      if (signInStatus.failed()) {
        this.loginErrorMessage = '名前かパスワードが間違っています';
        this.showMsgModal('ログインエラー', this.loginErrorMessage);
      }
      if (signInStatus.newPasswordRequired()) {
        this.status = signInStatus.getStatus();
        this.signInStatus = signInStatus;
      }
      if (signInStatus.passwordResetRequired()) {
        this.status = signInStatus.getStatus();
        this.signInStatus = signInStatus;
      }
    },
    async setNewPassword() {
      if (this.newPassword !== this.retypedNewPassword) {
        this.invalidNewPasswordMessage = '新しいパスワードが正しくありません';
        this.showMsgModal('確認してください', this.invalidNewPasswordMessage);
        return;
      }
      this.invalidNewPasswordMessage = '';
      const setStatus = await this.signInStatus.setNewPassword(this.newPassword)
        .catch((err) => {
          console.error(err);
          this.showMsgModal('Error', '失敗しました');
          return '';
        });
      if (!setStatus) return;
      if (setStatus.success()) router.push({ path: 'top' });
    },
    async resetPassword() {
      if (this.newPassword !== this.retypedNewPassword) {
        this.invalidNewPasswordMessage = '新しいパスワードが正しくありません';
        this.showMsgModal('確認してください', this.invalidNewPasswordMessage);
        return;
      }
      this.invalidNewPasswordMessage = '';
      const status = await this.signInStatus.setResetPassword(
        this.newPassword,
        this.verificationCode,
      )
        .catch((err) => {
          console.error(err);
          this.showMsgModal('Error', '失敗しました');
          return '';
        });
      if (!status) return;
      if (status.success()) {
        this.password = this.newPassword;
        this.doSignin();
      }
    },
    async registerUserLogin() {
      const token = await Session.getSession();
      if (!token) {
        console.error('Session Error.');
        return false;
      }
      this.sessionToken = token;
      return Http.post(
        `https://${process.env.VUE_APP_BACKEND_USER_DOMAIN}/${process.env.VUE_APP_BACKEND_USER_VERSION}/personal/user/login/register`,
        token,
      ).catch((e) => {
        console.error(e);
      });
    },
    async getSubscription() {
      return Http.get(
        `https://${process.env.VUE_APP_BACKEND_USER_DOMAIN}/${process.env.VUE_APP_BACKEND_USER_VERSION}/user/subscriptions`,
        this.sessionToken,
      ).catch((e) => {
        console.error(e);
        return [];
      });
    },
  },
  created() {
    this.status = this.type;
    // this.status = 'newPasswordRequired'; // test
    // this.status = 'passwordResetRequired'; // test
    this.titleForStatus = (this.status === 'Signin') ? 'ログイン' : this.titleForStatus;
    this.titleForStatus = (this.status === 'newPasswordRequired') ? 'パスワード再設定' : this.titleForStatus;
    this.titleForStatus = (this.status === 'passwordResetRequired') ? 'パスワードリセット' : this.titleForStatus;
  },
  mounted() {
  },
  watch: {
    newPassword() {
      if (this.newPassword === '') {
        this.passwordValidationMessage = '※必須です';
        this.passwordValidationMessageIncludeUppercase = '';
        this.passwordValidationMessageIncludeLowercase = '';
        this.passwordValidationMessageIncludeNumber = '';
        this.passwordValidationMessageLength = '';
      }
      if (this.newPassword !== '' && !this.newPassword.match(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d!-/:-@[-`{}-~\]]{10,}$/)) {
        this.passwordValidationMessage = 'パスワードの形式に誤りがあります';
        if (!this.newPassword.match(/[A-Z]/)) {
          this.passwordValidationMessageIncludeUppercase = '大文字が含まれていません';
        } else {
          this.passwordValidationMessageIncludeUppercase = '';
        }
        if (!this.newPassword.match(/[a-z]/)) {
          this.passwordValidationMessageIncludeLowercase = '小文字が含まれていません';
        } else {
          this.passwordValidationMessageIncludeLowercase = '';
        }
        if (!this.newPassword.match(/\d/)) {
          this.passwordValidationMessageIncludeNumber = '数字が含まれていません';
        } else {
          this.passwordValidationMessageIncludeNumber = '';
        }
        if (this.newPassword.length < 10) {
          this.passwordValidationMessageLength = '10文字以上にしてください';
        } else {
          this.passwordValidationMessageLength = '';
        }
      }
    },
  },
  computed: {
    usernameValidation() {
      return this.isSubmited ? this.username !== '' : null;
    },
    passwordValidation() {
      return this.isSubmited ? this.password !== '' : null;
    },
    newPasswordValidation() {
      return this.newPassword !== '' && !!this.newPassword.match(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d!-/:-@[-`{}-~\]]{10,}$/);
    },
    retypedNewPasswordValidation() {
      return this.retypedNewPassword !== '' && this.newPassword === this.retypedNewPassword;
    },
    verificationCodeValidation() {
      return this.isSubmited ? this.verificationCode !== '' : null;
    },
  },
};
</script>

<style scoped>

</style>

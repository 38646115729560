<template>
  <b-container>
    <div v-if="isLoading" class="text-center my-5 py-5">
      <b-spinner></b-spinner>
    </div>
    <div v-if="!isLoading" class="mb-20">
      <h2 class="mb-5">従業員数データ一覧</h2>
      <p>上場企業とその子会社の従業員数を合計した数字を一覧にしています</p>
      <EmployeeTable
        :employeeData='employeeData'
        :industryDataList='industryDataList'
        :startDate='startDate'
        :lastDate='lastDate'
        :updateAt='updateAt'
      />
    </div>
  </b-container>
</template>

<script>
import Init from '@/components/mixins/Init.vue';
import EmployeeTable from '@/components/project/EmployeeTable.vue';
import router from '@/router';
import * as Http from '@/lib/http/http';
import { csvToJson } from '@/lib/helper/csv-helper';
import Session from '@/lib/model/user/session';
import * as Analytics from '@/lib/infrastructure/google/analytics';

export default {
  name: 'Employee',
  mixins: [
    Init,
  ],
  components: {
    EmployeeTable,
  },
  data: () => ({
    employeeData: [],
    industryDataList: [],
    startDate: null,
    lastDate: null,
    updateAt: '',
    isLoading: true,

  }),
  async created() {
    await this.init();
    this.scrollTop();
  },
  methods: {
    async init() {
      this.sessionToken = await Session.getSession();
      const contracted = await this.checkUserPlans();
      if (!contracted) {
        await router.push({ path: '/' });
        return;
      }
      const succeeded = await this.fetchEmployeeData();
      if (!succeeded) {
        await router.push({ path: '/' });
        return;
      }
      this.isLoading = false;
      await this.sendPaidUserEvent();
    },
    async checkUserPlans() {
      if (!this.sessionToken) return false;
      const planList = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_DOMAIN}/2/personal/plan`,
        this.sessionToken,
      )
        .catch((e) => {
          console.log(e);
          return [];
        });
      return planList.some((plan) => plan.planCode.includes('employee'));
    },
    async sendPaidUserEvent() {
      // （GTM上で）subscribeイベントで付与されるis_paid_userフラグを
      // 過去に課金したユーザーにもユーザープロパティを設定するために、一時的に送信する。
      // 半年程度で削除する。（2025年になったら）
      Analytics.sendIsPaidUserEvent();
    },
    async fetchEmployeeData() {
      const {
        url, updateAt, startDate, lastDate,
      } = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_DOMAIN}/2/personal/employee/metrics`,
        this.sessionToken,
      );
      if (url) {
        const [csvData, industryDataList] = await Promise.all([Http.get(url), Http.get(
          `https://${process.env.VUE_APP_BACKEND_DOMAIN}/2/personal/list/peragaru-industry`,
          this.sessionToken,
        )]);
        this.startDate = startDate;
        this.lastDate = lastDate;
        this.updateAt = updateAt;
        this.employeeData = await csvToJson(csvData);
        this.industryDataList = industryDataList;
        return true;
      }
      return false;
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';

.non-member {
  margin: 0 auto;
  padding: 3rem 0;
}
.card-style {
  background-color: #222;
}
</style>

export const keyCheck = (rootObject, key) => {
  if (!rootObject || !key) return false;
  const chainedKeyList = key.split('.');
  const k = chainedKeyList.find((e) => !!e);
  // eslint-disable-next-line no-prototype-builtins
  if (!rootObject.hasOwnProperty(k)) return false;
  if (chainedKeyList.length <= 1) return true;
  return keyCheck(rootObject[k], chainedKeyList.splice(1).join('.'));
};

export const mergeObjects = (array, key, value) => array.map((obj) => ({
  [obj[key]]: obj[value],
}))
  .reduce((ac, obj) => Object.assign(ac, obj), {});

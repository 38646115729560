/* eslint-disable no-shadow,no-unused-vars */

// cvvのみ機密認証データに該当
// https://pcireadycloud.com/blog/2018/06/15/2524/
const state = {
  card: {
    cardNo: '',
    holdername: '',
    expireYear: '',
    expireMonth: '',
  },
};

const getters = {
  getCard: (
    state,
    _getters,
    _rootState,
    _rootGetters,
  ) => state.card,
};

const actions = {
  getCard({ getters }) {
    return getters.getCard;
  },
  async setCard({ commit }, card) {
    commit('setCard', card);
  },
  async resetCard({ commit }) {
    commit('resetCard');
  },
};

const mutations = {
  setCard(state, card) {
    state.card.cardNo = card.cardNo;
    state.card.holdername = card.holdername;
    state.card.expireYear = card.expireYear;
    state.card.expireMonth = card.expireMonth;
  },
  resetCard(state) {
    state.card.cardNo = '';
    state.card.holdername = '';
    state.card.expireYear = '';
    state.card.expireMonth = '';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

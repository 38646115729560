<template>
  <div id="overlay">
    <b-container>
      <div id="content" class="mx-auto">
        <b-row>
          <b-col cols="12" class="modal-body pl-4 text-left">
            {{ message }}
          </b-col>
        </b-row>
        <b-row align-v="end" class="justify-content-end">
          <div class="d-block">
            <b-col cols="12" class="d-flex">
              <b-button class="modal-btn btn btn-success" @click="$emit('done')">OK</b-button>
              <b-button v-if="isCancel" class="modal-btn btn btn-secondary" @click="$emit('close')">Cancel</b-button>
            </b-col>
          </div>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: ['message', 'isCancel'],
};
</script>
<style>
#content {
  z-index: 2;
  padding: 1rem;
  border-radius: 3px;
  background: white;
  color: black;
  width: 60%;
}
#overlay {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-title {
  text-decoration: underline 1px #aaa;
  text-underline-offset: 0.5rem;
}
.modal-body {
  min-height: 5rem;
}
.modal-btn {
  margin: 0 0.5rem;
  width: 5rem;
}
.modal-enter-active {
  transition: opacity .3s ease;
}
.modal-enter {
  opacity: 0;
}
</style>

<template>
  <div>
    <transition name="modal">
      <Modal v-show="showModal" :message="modalMessage" :isCancel="true" @close="closeModal" @done="changeAutoUpdate(!subsc.autoUpdateFlag)"/>
    </transition>

    <div class="w-100 d-flex flex-column">
      <div class="h-100">
        <h4 class="mb-4 font-weight-bold">{{ subsc.planName || 'No Name' }}</h4>
        <div class="mb-4">
          <div class="mb-3">
            <h6 class="font-weight-bold">契約期間</h6>
            <div class="d-flex">
              <div>開始日</div>
              <div class="text-right">{{ startDate }}</div>
            </div>
            <div class="d-flex">
              <div>{{ subsc.autoUpdateFlag ? '更新日' : '解約日' }}</div>
              <div class="text-right">{{ subsc.autoUpdateFlag ? updateDate : endDate }}</div>
            </div>
          </div>
          <div>
            <h6 class="font-weight-bold">料金</h6>
            <div v-if="subsc.couponCode">
              <div class="d-flex">
                <div>クーポン</div>
                <div class="text-right">{{ subsc.couponCode }}</div>
              </div>
              <div class="d-flex">
                <div>初月</div>
                <div class="text-right">{{ amountFormatter(subsc.initAmountTax) }} <small>円</small></div>
              </div>
            </div>
            <div class="d-flex">
              <div>月額</div>
              <div class="text-right">{{ amountFormatter(amount) }} <small>円</small></div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right flex-shrink-1">
        <b-button @click="openModal" :variant="`${subsc.autoUpdateFlag ? 'primary' : 'info'}`" size="sm">
          <b>{{ subsc.autoUpdateFlag ? '解約申込' : '解約取消' }}</b>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import * as Http from '@/lib/http/http';
import Session from '@/lib/model/user/session';
import Modal from '../utility/Modal.vue';

export default {
  name: 'Subscription',
  components: {
    Modal,
  },
  props: ['subsc'],
  data: () => ({
    sessionToken: '',
    startDate: '',
    updateDate: '',
    endDate: '',
    amount: 0,
    showModal: false,
    modalMessage: '',
  }),
  mounted() {
    console.log(this.subsc);
    this.startDate = moment(this.subsc.startDate).format('YYYY/MM/DD');
    this.updateDate = moment(this.subsc.value).format('YYYY/MM/DD');
    this.endDate = moment(this.subsc.value).subtract(1, 'd').format('YYYY/MM/DD');
    this.amount = Number(this.subsc.amount) + Number(this.subsc.tax);
  },
  methods: {
    async changeAutoUpdate(flag) {
      const token = await Session.getSession();
      if (!token) {
        console.error('Session Error.');
        return;
      }
      this.sessionToken = token;

      const subscription = await Http.post(
        `https://${process.env.VUE_APP_BACKEND_PAYMENT_DOMAIN}/${process.env.VUE_APP_BACKEND_PAYMENT_VERSION}/payment/change-auto-update`,
        this.sessionToken,
        {
          recurringID: this.subsc.recurringID,
          autoUpdateFlag: flag,
        },
      ).catch((e) => { console.error(e); });

      if (subscription) {
        this.subsc = subscription;
      }

      this.showModal = false;
    },
    openModal() {
      this.showModal = true;
      this.modalMessage = this.subsc.autoUpdateFlag ? '解約申込しますか？' : '解約申込を取り消しますか？';
    },
    closeModal() {
      this.showModal = false;
      this.modalMessage = '';
    },
    amountFormatter(amount) {
      return `${String(amount).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';
</style>

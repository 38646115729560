<template>
  <div>
    <div class="item-count">Total: {{ numberFormat(filteredItemCount) }}</div>
    <div  class="panel">
      <b-table
        hover
        no-border-collapse
        sort-icon-left
        :items="projects"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="displayWidth > 768 ? fields : fieldsForSmall"
        filter="null"
        :filter-function="customFilter"
        @filtered="onFiltered"
        @row-clicked="onRowClicked"
      >
        <template #cell(companyDisplayName)="data">{{ displayName(data) }}</template>
        <template #cell(fiscalEndMonth)="data">{{ fiscalEndMonth(data.value) }}</template>
        <template #cell(categoryList)="data">
          <b-list-group v-for="category in categoryList(data.value)" :key='category' class="d-inline-flex">
            <b-badge class="badge">{{ category }}</b-badge>
          </b-list-group>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="filteredItemCount"
        :per-page="perPage"
        first-text="<<"
        prev-text="<"
        next-text=">"
        last-text=">>"
        class="customPagination"
        align="right"
      ></b-pagination>
      <h5 v-if="!filteredItemCount" class="text-secondary font-weight-bold">条件に一致する銘柄はありません</h5>
    </div>
  </div>
</template>

<script>
import DataCategory from '@/lib/model/project/data-category';
import router from '@/router';

export default {
  name: 'ProjectList',
  props: ['projects', 'searchConditions'],
  data: () => ({
    displayWidth: 0,
    fields: [
      {
        key: 'securityCode', label: '銘柄コード', sortable: true, thClass: 'code-column',
      },
      {
        key: 'companyDisplayName', label: '銘柄名', sortable: true, thClass: 'name-column',
      },
      {
        key: 'fiscalEndMonth', label: '決算月', sortable: true, thClass: 'fiscal-column',
      },
      {
        key: 'categoryList', label: 'カテゴリー',
      },
    ],
    fieldsForSmall: [
      {
        key: 'securityCode', label: '銘柄コード', sortable: true, thClass: 'code-column',
      },
      {
        key: 'companyDisplayName', label: '銘柄名', sortable: true, thClass: 'name-column',
      },
      {
        key: 'fiscalEndMonth', label: '決算月', sortable: true, thClass: 'fiscal-column',
      },
    ],
    filteredItemCount: 0,
    currentPage: 1,
    perPage: 30,
  }),
  async created() {
    this.displayWidth = window.innerWidth;
    this.filteredItemCount = this.projects.length;
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.displayWidth = window.innerWidth;
    },
    displayName(data) {
      return data.value || data.item.companyName;
    },
    fiscalEndMonth(fiscalEndMonth) {
      return fiscalEndMonth ? `${Number(fiscalEndMonth)}月` : '-';
    },
    categoryList(categoryList) {
      return categoryList.map((c) => DataCategory[c]);
    },
    onRowClicked(item) {
      if (!item.securityCode || item.delisted) return;
      this.toDetail(item.securityCode);
    },
    toDetail(securityCode) {
      router.push({ path: `/project/detail/${securityCode}` });
    },
    customFilter(item) {
      const { inputCondtion, categoryCondition, fiscalEndMonthCondition } = this.searchConditions;
      return this.searchFilter(item, inputCondtion)
        && this.categoryFilter(item, categoryCondition)
        && this.fiscalEndMonthFilter(item, fiscalEndMonthCondition);
    },
    searchFilter(item, conditions) {
      if (!conditions.length) return true;
      return conditions.every((c) => `${item.securityCode}__${item.companyDisplayName}__${item.companyName}`.includes(c));
    },
    categoryFilter(item, conditions) {
      if (conditions.every((c) => !c.selected)) return true;
      const { categoryList } = item;
      return conditions.every((c) => !c.selected || categoryList.includes(c.key));
    },
    fiscalEndMonthFilter(item, conditions) {
      if (!conditions.thisMonth && !conditions.nextMonth) return true;
      const fiscalEndMonth = Number(item.fiscalEndMonth);
      const thisMonth = new Date().getMonth() + 1;
      const nextMonth = thisMonth + 1;
      const isMonth = conditions.thisMonth && fiscalEndMonth === thisMonth;
      const isNextMonth = conditions.nextMonth && fiscalEndMonth === nextMonth;
      return isMonth || isNextMonth;
    },
    onFiltered(filteredItems) {
      this.filteredItemCount = filteredItems.length;
    },
    numberFormat(num) {
      return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';
::v-deep .table {
  border-bottom: 1px solid #6c757d !important;
  .code-column {
    width: 15% !important;
    @include media-breakpoint-down(md) {
      width: 30% !important;
    }
  }
  .fiscal-column {
    width: 15% !important;
    @include media-breakpoint-down(md) {
      width: 25% !important;
    }
  }
  .name-column {
    @include media-breakpoint-down(md) {
      width: 45% !important;
    }
  }
  th {
    border: none;
    border-bottom: 1px solid #6c757d;
    font-weight: bold;
    color: white !important;
    background-color: #303030 !important;
    padding-left: 0;
    @include media-breakpoint-down(md) {
      font-size: 0.8rem;
    }
    vertical-align: middle;
  }
  td {
    color: whitesmoke;
    border-color: #555;
    font-weight: bold;
    font-size: 1rem;
    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
  }
  tbody {
    td {
      vertical-align: middle;
      padding: 15px 0;
    }
    tr:hover {
      background-color: #444;
      cursor: pointer;
    }
  }
  [aria-sort=none] {
    background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='rgb(108, 117, 125)' opacity='0.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='rgb(108, 117, 125)' opacity='0.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e") !important;
  }
  [aria-sort=ascending] {
    background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='rgb(108, 117, 125)' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='rgb(108, 117, 125)' opacity='0.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e")!important;
  }
  [aria-sort=descending] {
    background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='rgb(108, 117, 125)' opacity='0.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='rgb(108, 117, 125)' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e")!important;
  }
}
.badge {
  width: fit-content;
  padding: 0.4rem;
  margin: 0.1rem 0.5rem 0.1rem 0;
  @include media-breakpoint-down(md) {
    margin: 0.1rem 0.3rem 0.1rem 0;
  }
  background-color: #555;
}
p {
  margin: 0;
}
.item-count {
  font-weight: bold;
  font-size: 17.5px;
  text-align: right;
}
.panel {
  padding: 0.5rem 2rem;
  @include media-breakpoint-down(md) {
    padding: 0.5rem 1rem;
  }
  background-color: #303030;
  border: 1px solid #555;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

::v-deep .customPagination {
  .page-item {
    border-color:  whitesmoke;
  }
  .page-item.active .page-link {
    background-color: #555;
    border-color: #555;
    border: 1px solid #555;
  }
}
</style>

<template>
  <b-form class="mt-3">
    <label>ご利用可能ブランド</label>
    <div>
      <img src="/card_5brand.png" class="brand-logo-list mb-3">
    </div>
    <b-form-group>
      <label>カード番号(CardNo)</label>
      <div>
        <input
          @input="validateCardNo"
          :value="cardForm.cardNo"
          :maxlength="cardNoMaxLength"
          id="cardNo"
          class="cardno-form"
          placeholder="newCardNo"
        />
        <div style="height: 16.8px;">
          <b-form-invalid-feedback v-if="isCheckInvalid" :state="cardNoValidation">カード番号(cardNo)を入力してください。</b-form-invalid-feedback>
        </div>
      </div>
    </b-form-group>
    <b-form-group>
      <label>有効期限(Expire)</label>
      <div>
        <b-form-select
          id="expire"
          v-model="cardForm.expireYear"
          :options="yearList"
          class="bg-white text-dark expire-form"
        ></b-form-select>{{ " / " }}
        <b-form-select
          id="expire"
          v-model="cardForm.expireMonth"
          :options="monthList"
          class="bg-white text-dark expire-form"
        ></b-form-select>
        <div style="height: 16.8px;">
          <b-form-invalid-feedback v-if="isCheckInvalid" :state="expireValidation">有効期限(Expire)を入力してください。</b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="isCheckInvalid" :state="expirePastValidation">有効期限(Expire)は現在年月以降で入力してください。</b-form-invalid-feedback>
        </div>
      </div>
    </b-form-group>
    <b-form-group>
      <label>カード名義人(HolderName)<small> （半角英数字、半角空白）</small></label>
      <div>
        <input
          @input="validateHolderName"
          :value="cardForm.holdername"
          id="holdername"
          class="holdername-form form-control"
          placeholder="newHoldername"
        />
        <div style="height: 16.8px;">
          <b-form-invalid-feedback v-if="isCheckInvalid" :state="holderNameValidation">カード名義人(HolderName)を入力してください。</b-form-invalid-feedback>
        </div>
      </div>
    </b-form-group>
    <b-form-group>
      <label>セキュリティーコード(CVV)</label>
      <div>
        <input
          @input="changeSecurityCode"
          :value="cardForm.securityCode"
          class="cvv-form"
          :maxlength="securityCodeLength"
          id="securityCode"
          placeholder="cvv"
          autocomplete="off"
        />
        <div style="height: 16.8px;">
          <b-form-invalid-feedback v-if="isCheckInvalid" :state="securityCodeValidation">セキュリティーコード(CVV)を入力してください。</b-form-invalid-feedback>
        </div>
      </div>
    </b-form-group>
  </b-form>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import router from '@/router';

export default {
  name: 'CardForm',
  head: {
    script: () => [{ src: process.env.VUE_APP_GMO_TOKENJS, async: true }],
  },
  data: () => ({
    cardForm: {
      cardNo: '',
      expireYear: '',
      expireMonth: '',
      holdername: '',
      securityCode: '',
    },
    cardNoMaxLength: 19,
    securityCodeLength: 3,
    storeCard: {},
    yearList: [
      { value: '', text: 'Year' },
    ],
    monthList: [
      { value: '', text: 'Month' },
      { value: '01', text: '01' },
      { value: '02', text: '02' },
      { value: '03', text: '03' },
      { value: '04', text: '04' },
      { value: '05', text: '05' },
      { value: '06', text: '06' },
      { value: '07', text: '07' },
      { value: '08', text: '08' },
      { value: '09', text: '09' },
      { value: '10', text: '10' },
      { value: '11', text: '11' },
      { value: '12', text: '12' },
    ],
    now: moment().format('YYMM'),
    isCheckInvalid: false,
  }),
  async created() {
    for (let i = 0; i < 10; i += 1) {
      const datetime = moment().add(i, 'year');
      this.yearList.push({ value: datetime.format('YY'), text: datetime.format('YYYY') });
    }
  },
  async mounted() {
    this.storeCard = await this.getCard();
    if (this.storeCard.cardNo) {
      this.cardForm.cardNo = this.storeCard.cardNo;
      this.cardForm.holdername = this.storeCard.holdername;
      this.cardForm.expireYear = this.storeCard.expireYear;
      this.cardForm.expireMonth = this.storeCard.expireMonth;
    }
  },
  methods: {
    ...mapActions({
      getCard: 'Payment/getCard',
      setCard: 'Payment/setCard',
    }),
    validateCardNo(e) {
      this.cardForm.cardNo = e.target.value;
      const value = this.cardForm.cardNo.replace(/\D/g, ''); // 数字以外を除外している
      // https://github.com/muhammed/vue-interactive-paycard
      // http://php.o0o0.jp/article/php-creditcard
      if ((/^(?:2131|1800)\d{0,11}$/).test(value)) { // jcb, 15 digits 4,4,4,3区切り(カードサポート問い合わせにより)
        this.cardForm.cardNo = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{4})/, '$1 $2 ').replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
        this.cardNoMaxLength = 18;
        this.securityCodeLength = 3;
      } else if ((/^3[47]\d{0,13}$/).test(value)) { // american express, 15 digits
        this.cardForm.cardNo = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
        this.cardNoMaxLength = 17;
        this.securityCodeLength = 4;
      } else if ((/^3(?:0[0-5]|[68]\d)\d{0,11}$/).test(value)) { // diner's club, 14 digits
        this.cardForm.cardNo = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
        this.cardNoMaxLength = 16;
        this.securityCodeLength = 3;
      } else if ((/^\d{0,16}$/).test(value)) { // visa /^4\d{0,15}$/ master /^5[1-5]\d{0,14}$/ jcb /^35\d{0,14}$/
        this.cardForm.cardNo = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{4})/, '$1 $2 ').replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
        this.cardNoMaxLength = 19;
        this.securityCodeLength = 3;
      }
      // これがないとdel押下時に空白で止まる
      if (e.inputType === 'deleteContentBackward') {
        const lastChar = this.cardForm.cardNo.substring(this.cardForm.cardNo.length, this.cardForm.cardNo.length - 1);
        if (lastChar === ' ') { this.cardForm.cardNo = this.cardForm.cardNo.substring(0, this.cardForm.cardNo.length - 1); }
      }
    },
    validateHolderName(e) {
      // 名義人の入力可能文字「半角英数字 + 半角空白 + 半角記号（コンマ、ハイフン、ピリオド、スラッシュ）」
      this.cardForm.holdername = e.target.value;
      const value = this.cardForm.holdername.replace(/[^a-zA-Z0-9 ,-./]/g, '');
      this.cardForm.holdername = value;
    },
    changeSecurityCode(e) { // this.cardForm.securityCode = e.target.value.replace(/\D/g, '');は効かない。
      this.cardForm.securityCode = e.target.value;
      const value = this.cardForm.securityCode.replace(/\D/g, '');
      this.cardForm.securityCode = value;
    },
    async getToken() {
      this.isCheckInvalid = false;
      if (!this.cardForm.cardNo.match(/^\d{4}\s(?:\d{4}\s\d{4}\s\d{3,4}|\d{6}\s\d{4,5})$/)
        || !this.cardForm.expireYear.match(/^\d{2}$/)
        || !this.cardForm.expireMonth.match(/^\d{2}$/)
        || this.cardForm.expireYear + this.cardForm.expireMonth < this.now
        || !this.cardForm.holdername
        || this.cardForm.securityCode.length < 3
      ) {
        this.isCheckInvalid = true;
        return false;
      }
      window.Multipayment.init(process.env.VUE_APP_GMO_SHOP_ID);
      try {
        await window.Multipayment.getToken({
          cardno: this.cardForm.cardNo.replace(/\s/g, ''),
          expire: this.cardForm.expireYear + this.cardForm.expireMonth,
          holdername: this.cardForm.holdername,
          securitycode: this.cardForm.securityCode,
        }, ((res) => this.$emit('get-token', res.tokenObject.token, this.cardForm.holdername)));
      } catch (e) {
        console.error(e);
        return false;
      }
      return true;
    },
    goto(link) {
      router.push({ path: link });
    },
  },
  computed: {
    cardNoValidation() {
      return !!this.cardForm.cardNo.match(/^\d{4}\s(?:\d{4}\s\d{4}\s\d{3,4}|\d{6}\s\d{4,5})$/);
    },
    expireValidation() {
      return !!this.cardForm.expireYear.match(/^\d{2}$/) && !!this.cardForm.expireMonth.match(/^\d{2}$/);
    },
    expirePastValidation() {
      return !this.cardForm.expireYear || !this.cardForm.expireMonth || this.cardForm.expireYear + this.cardForm.expireMonth >= this.now;
    },
    holderNameValidation() {
      return !!this.cardForm.holdername;
    },
    securityCodeValidation() {
      return this.cardForm.securityCode.length === this.securityCodeLength;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';

.brand-logo-list {
  background-color: white;
  padding: 0.2rem;
  max-width: 250px;
  width: 100%;
  border-radius: 5px;
}
.cardno-form {
  @extend .form;
  width: 50%;
}
.holdername-form {
  @extend .form;
  width: 100%;
}
.cvv-form {
  @extend .form;
  width: 15%;
}
.form {
  background-color: white;
  color: black;
  padding: 0.4rem;
  border-radius: 5px;
  font-size: 15px;
}
.expire-form {
  width: 80px;
}
.invalid-feedback {
  // color: gray;
  padding-top: 3.5px;
  margin: 0;
}
</style>

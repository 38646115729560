import * as moment from 'moment';

import { keyCheck } from '../../helper/object-helper';

import BaseEntity from '../base-entity';

export default class Project extends BaseEntity {
  constructor(obj) {
    super(obj);
    this.companyName = keyCheck(obj, 'companyName') ? obj.companyName : '';
    this.companyDisplayName = keyCheck(obj, 'companyDisplayName') && !!obj.companyDisplayName ? obj.companyDisplayName : this.companyName;
    this.securityCode = keyCheck(obj, 'securityCode') && !!obj.securityCode ? obj.securityCode : '';
    this.industryCategory = keyCheck(obj, 'industryCategory') ? obj.industryCategory : '';
    this.companyImageUrl = keyCheck(obj, 'companyImageUrl') ? obj.companyImageUrl : '';
    this.companyUrl = keyCheck(obj, 'companyUrl') ? obj.companyUrl : '';
    this.foundationDate = keyCheck(obj, 'foundationDate') ? Number(moment(obj.foundationDate).format('x')) : 0;
    this.personalInfoFlag = keyCheck(obj, 'personalInfoFlag') ? obj.personalInfoFlag : false;
    this.freePublicFlag = keyCheck(obj, 'freePublicFlag') ? obj.freePublicFlag : false;
    this.sampleFlag = keyCheck(obj, 'sampleFlag') ? obj.sampleFlag : false;
    this.caseStudy = keyCheck(obj, 'caseStudy') ? obj.caseStudy : '';
    // eslint-disable-next-line no-restricted-globals
    this.fiscalEndMonth = keyCheck(obj, 'fiscalEndMonth') && obj.fiscalEndMonth && isFinite(obj.fiscalEndMonth) ? obj.fiscalEndMonth : '';
    this.available = !(keyCheck(obj, 'available') && obj.available === false);
    this.delisted = keyCheck(obj, 'delisted') && !!obj.delisted;
    this.categoryList = keyCheck(obj, 'categoryList') ? obj.categoryList : [];
    this.planCodeList = keyCheck(obj, 'planCodeList') ? obj.planCodeList : [];
  }

  static default() {
    return new Project();
  }
}

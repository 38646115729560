import Vue from 'vue';
import VueHead from 'vue-head';
import VueScrollTo from 'vue-scrollto';
import App from './App.vue';
import router from './router';
import store from './store';
import 'vuetify/dist/vuetify.min.css';
import 'ag-grid-community/styles/ag-theme-material.css'; // 'ag-theme-material-dark'を使用するため
import './plugins/vuetify';
import './plugins/bootstrap';

Vue.config.productionTip = false;
Vue.use(VueHead);
Vue.use(VueScrollTo, { offset: -100 });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

// ref) https://github.com/AurityLab/recaptcha-v3/blob/master/src/ReCaptchaLoader.ts
export const loadScript = (sitekey = process.env.VUE_APP_GOOGLE_RECAPTCH_SITEKEY) => {
  const scriptElement = document.createElement('script');
  scriptElement.setAttribute('recaptcha-v3-script', '');
  scriptElement.src = `https://www.google.com/recaptcha/api.js?render=${sitekey}`;
  return new Promise((resolve, reject) => {
    scriptElement.addEventListener(
      'load',
      () => {
        waitForScriptLoaded(() => {
          resolve(scriptElement);
        });
      },
      false,
    );
    scriptElement.onerror = (err) => {
      reject(err);
    };
    document.head.appendChild(scriptElement);
  });
};

export const execute = () => window.grecaptcha
  .execute(process.env.VUE_APP_GOOGLE_RECAPTCH_SITEKEY, {
    action: 'homepage',
  })
  .then((token) => token);

const waitForScriptLoaded = (callback) => {
  if (window.grecaptcha === undefined) {
    setTimeout(
      () => waitForScriptLoaded(callback),
      Number(process.env.VUE_APP_GOOGLE_RECAPCHA_SCRIPT_LOAD_DELAY),
    );
  } else {
    window.grecaptcha.ready(() => callback());
  }
};

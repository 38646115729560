<template>
  <div>
    <UserSetting />
  </div>
</template>

<script>
import Init from '@/components/mixins/Init.vue';
import UserSetting from '@/components/user/UserSetting.vue';

export default {
  name: 'User',
  mixins: [
    Init,
  ],
  components: {
    UserSetting,
  },
  data: () => ({}),
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>

</style>

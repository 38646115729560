<template>
  <b-row>
    <b-col cols="12" md="3">
      <ProjectFilter @searchConditions="receiveConditions" class="sticky" />
    </b-col>
    <b-col cols="12" md="9" class="px-3 mt-5 mt-md-0">
      <ProjectList :projects="projects" :searchConditions="searchConditions" />
    </b-col>
  </b-row>
</template>

<script>
import ProjectFilter from './ProjectFilter.vue';
import ProjectList from './ProjectList.vue';

export default {
  name: 'ProjectTable',
  props: ['projects'],
  components: {
    ProjectFilter,
    ProjectList,
  },
  data: () => ({
    searchConditions: {
      inputCondtion: [],
      categoryCondition: [],
      fiscalEndMonthCondition: {
        thisMonth: false,
        nextMonth: false,
      },
    },
  }),
  methods: {
    receiveConditions(conditions) {
      this.searchConditions = conditions;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';
.sticky {
  position: -webkit-sticky; /* safari対応 */
  position: sticky;
  top: 100px; /* 上端から10pxのところで固定 */
}
</style>

<template>
  <b-container>
    <h1 class="mb-10">プラン</h1>
    <b-row>
      <b-col cols="12" md="6" class="mb-5">
        <PlanUnit class="h-100"/>
      </b-col>
      <b-col cols="12" md="6" class="mb-5">
        <PlanEmployee class="h-100"/>
      </b-col>
      <!-- <b-col cols="12" md="6" class="mb-5">
        <PlanEmployeePremium class="h-100"/>
      </b-col> -->
      <b-col cols="12" md="6" class="mb-5">
        <PlanSalary class="h-100"/>
      </b-col>
      <b-col cols="12" md="6" class="mb-5">
        <Enterprise class="h-100"/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Init from '@/components/mixins/Init.vue';
import PlanUnit from '@/components/plan/PlanUnit.vue';
import PlanEmployee from '@/components/plan/PlanEmployee.vue';
// import PlanEmployeePremium from '@/components/plan/PlanEmployeePremium.vue';
import PlanSalary from '@/components/plan/PlanSalary.vue';
import Enterprise from '@/components/plan/Enterprise.vue';

export default {
  name: 'Plan',
  mixins: [
    Init,
  ],
  components: {
    PlanUnit,
    PlanEmployee,
    // PlanEmployeePremium,
    PlanSalary,
    Enterprise,
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';
</style>

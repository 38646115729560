<template>
  <div id="app">

    <header>
      <b-navbar
        toggleable="lg"
        type="dark"
        fixed="top">
        <b-container class="for-personal py-0">
          <b-navbar-brand href="#" @click='toHome'></b-navbar-brand>
          <b-navbar-toggle target="navbarNavDropdown"></b-navbar-toggle>
          <b-collapse id="navbarNavDropdown" is-nav>
            <b-navbar-nav class="mr-auto"></b-navbar-nav>
            <b-navbar-nav>
              <div class="d-lg-flex align-items-center">
                <b-nav-item
                  v-if="!signinStatus"
                  @click='doSignin'>
                  ログイン
                </b-nav-item>
                <b-nav-item
                  v-if="!signinStatus"
                  @click='doSignup'>
                  無料会員登録
                </b-nav-item>
                <b-nav-item
                  v-if="signinStatus"
                  @click='goto("/top")'>
                  契約一覧
                </b-nav-item>
                <b-nav-item
                  @click='goto("/list")'>
                  銘柄一覧
                </b-nav-item>
                <b-nav-item
                  v-if="signinStatus && contractedSalaryPlan"
                  @click='goto("/salary")'>
                  PERAGARU<br />
                  賃金データ
                </b-nav-item>
                <b-nav-item
                  v-if="signinStatus && contractedEmployeePlan"
                  @click='goto("/employee")'>
                  従業員数一覧データ
                </b-nav-item>
                <b-nav-item
                  @click='goto("/plan")'>
                  プラン
                </b-nav-item>
                <b-nav-item
                  @click='goto("/contact")'
                >
                  お問い合わせ
                </b-nav-item>
              </div>
              <div
                v-if="signinStatus"
                class="d-flex align-items-center"
              >
                <b-nav-item-dropdown
                  class="user-menu"
                  text="">
                  <b-dropdown-item
                    @click='goto("/user")'>
                    アカウント情報
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click='goto("/user/subscription")'>
                    契約情報
                  </b-dropdown-item>
                  <div class="dropdown-divider"></div>
                  <b-dropdown-item
                  @click='doSignout'>
                    ログアウト
                  </b-dropdown-item>
                </b-nav-item-dropdown>
              </div>
              <div
                v-if="signinStatus"
                class="user-menu-sp"
              >
                <b-nav-item
                  @click='goto("/user")'>
                  アカウント情報
                </b-nav-item>
                <b-nav-item
                  @click='goto("/user/subscription")'>
                  契約情報
                </b-nav-item>
                <b-nav-item
                  @click='doSignout'>
                  ログアウト
                </b-nav-item>
              </div>
            </b-navbar-nav>
          </b-collapse>
        </b-container>
      </b-navbar>
    </header>

    <b-container fluid id="main-content">
      <router-view @signout="doSignout" :key="$route.fullPath" />
    </b-container>

    <Footer />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import * as Http from './lib/http/http';
import Footer from './components/Footer.vue';
import router from './router';
import Singin from './lib/model/user/signin';
import Session from './lib/model/user/session';
import * as Analytics from './lib/infrastructure/google/analytics';

export default {
  name: 'App',
  components: {
    Footer,
  },
  data: () => ({
    signinStatus: false,
    menues: [],
    userStatus: '00',
    contractedSalaryPlan: false,
    contractedEmployeePlan: false,
    companyListMenu: {
      title: 'Company List',
      link: '/top',
    },
    originalMenues: [
      {
        title: 'About',
        link: '/about',
      },
      {
        title: 'Search',
        link: '/search',
      },
    ],
  }),
  async created() {
    this.menues = JSON.parse(JSON.stringify(this.originalMenues));
    this.signinStatus = await this.checkSigninStatus();
    // 下記をmethodで共通化すると無限ループになる
    if (this.signinStatus) {
      const token = await Session.getSession();
      if (token) {
        const user = await Http.get(
          `https://${process.env.VUE_APP_BACKEND_USER_DOMAIN}/${process.env.VUE_APP_BACKEND_USER_VERSION}/personal/user/current`,
          token,
        );
        if ('userStatus' in user || user.userStatus) await this.setUserStatus(user.userStatus);
        this.userStatus = user.userStatus;
        const contractedPlanList = await Http.get(
          `https://${process.env.VUE_APP_BACKEND_DOMAIN}/2/personal/plan`,
          token,
        );
        await this.setContractedPlanList(contractedPlanList);
        this.contractedSalaryPlan = await this.checkContractedWithPlanCode('salary');
        this.contractedEmployeePlan = await this.checkContractedWithPlanCodeForIncludingIndividual('employee');
      }
    } else {
      await this.resetContractedPlanList();
    }
    if (this.signinStatus && !this.menues.some((m) => m.title === 'Company List')) {
      this.menues.push(this.companyListMenu);
    }
  },
  async updated() {
    this.signinStatus = await this.checkSigninStatus();
    if (this.signinStatus) {
      const token = await Session.getSession();
      if (token) {
        const user = await Http.get(
          `https://${process.env.VUE_APP_BACKEND_USER_DOMAIN}/${process.env.VUE_APP_BACKEND_USER_VERSION}/personal/user/current`,
          token,
        );
        if ('userStatus' in user || user.userStatus) await this.setUserStatus(user.userStatus);
        this.userStatus = user.userStatus;
        const contractedPlanList = await Http.get(
          `https://${process.env.VUE_APP_BACKEND_DOMAIN}/2/personal/plan`,
          token,
        );
        await this.setContractedPlanList(contractedPlanList);
        this.contractedSalaryPlan = await this.checkContractedWithPlanCode('salary');
        this.contractedEmployeePlan = await this.checkContractedWithPlanCodeForIncludingIndividual('employee');
      }
    } else {
      await this.resetContractedPlanList();
    }
    if (this.signinStatus && !this.menues.some((m) => m.title === 'Company List')) {
      this.menues.push(this.companyListMenu);
    }
  },
  methods: {
    ...mapActions({
      getUserStatus: 'UserInfo/getUserStatus',
      setUserStatus: 'UserInfo/setUserStatus',
      resetUserStatus: 'UserInfo/resetUserStatus',
      getContractedPlanList: 'ContractedPlan/getContractedPlanList',
      setContractedPlanList: 'ContractedPlan/setContractedPlanList',
      resetContractedPlanList: 'ContractedPlan/resetContractedPlanList',
      checkContractedWithPlanCode: 'ContractedPlan/checkContractedWithPlanCode',
      checkContractedWithPlanCodeForIncludingIndividual: 'ContractedPlan/checkContractedWithPlanCodeForIncludingIndividual',
    }),
    toHome() {
      router.push({ path: '/' }).catch(() => {});
    },
    async checkSigninStatus() {
      const token = await Session.getSession();
      if (!token) {
        console.error('Session Error.');
        return false;
      }
      return true;
    },
    goto(link) {
      // eslint-disable-next-line no-restricted-globals
      router.push({ path: link });
    },
    doSignin() {
      router.push({ path: '/signin' }).catch(() => {});
    },
    doSignup() {
      router.push({ path: '/signup' }).catch(() => {});
    },
    async doSignout() {
      const isLogout = await this.resetUserLogin();
      if (!isLogout) return; // 更新できなかった場合はログアウトしない。
      await Singin.doSignout();
      this.signinStatus = false;
      this.menues = JSON.parse(JSON.stringify(this.originalMenues));
      await this.resetContractedPlanList();
      Analytics.resetUserId();
      router.push({ path: '/' }).catch(() => {});
    },
    async resetUserLogin() {
      const token = await Session.getSession();
      if (!token) {
        console.error('Session Error.');
        return false;
      }
      return Http.get(
        `https://${process.env.VUE_APP_BACKEND_USER_DOMAIN}/${process.env.VUE_APP_BACKEND_USER_VERSION}/personal/user/login/reset`,
        token,
      ).catch((e) => {
        console.error(e);
        return e.response.data;
      });
    },
  },
};
</script>

import BaseEntity from '../base-entity';
import { keyCheck } from '../../helper/object-helper';

export const DurationType = {
  yearly: 'yearly',
  quarterly: 'quarterly',
  monthly: 'monthly',
  weekly: 'weekly',
  daily: 'daily',
};

export const CalculationType = {
  sum: 'sum',
  avg: 'avg',
  accumulate: 'accumulate',
};

export default class MetricsSetting extends BaseEntity {
  constructor(obj) {
    super(obj);
    this.duration = keyCheck(obj, 'duration') ? DurationType[obj.duration] : DurationType.monthly;
    this.calcType = keyCheck(obj, 'calcType') ? CalculationType[obj.calcType] : CalculationType.sum;
    // eslint-disable-next-line no-restricted-globals
    this.fiscalEndMonth = keyCheck(obj, 'fiscalEndMonth') && obj.fiscalEndMonth && isFinite(obj.fiscalEndMonth) ? Number(obj.fiscalEndMonth) : -1;
  }
}

<template>
  <b-container>
    <div v-show="isLoading" class="text-center my-5 py-5">
      <b-spinner></b-spinner>
    </div>
    <div v-show="!isLoading">
      <div class="mb-10">
        <ProjectInfo :projectInfo="projectInfo" />
      </div>

      <div v-show="(projectPlanList.length + packagePlanList.length)" class="mb-20">
        <h2 id="plan" class="mb-5">Plans</h2>
        <ProjectPlan
          :isMember='isMember'
          :userPlans='userPlans'
          :projectPlanList='projectPlanList'
          :packagePlanList='packagePlanList'
        />
      </div>

      <div class="mb-20">
        <h2 class="mb-5">Metrics</h2>
        <div v-show="isMetricsLoading" class="text-center my-5">
          <b-spinner></b-spinner>
        </div>
        <Metrics
          :companyName='projectInfo.companyName'
          :metrics='metrics'
          :dataDeficiency='dataDeficiency'
          :typeWithScrapeInfo='typeWithScrapeInfo'
          :fiscalEndMonth='projectInfo.fiscalEndMonth'
          :isMember='isMember'
          :isPaidDefault='isPaidDefault'
          :isPaidEmployee='isPaidEmployee'
          :isOP='isOP'
          :allowedCategories='allowedCategories'
        />
      </div>

      <div v-show="relatedMetricsExisting" class="mb-20">
        <h2 class="mb-5">Related Data</h2>
        <RelatedMetrics
          :companyName='projectInfo.companyName'
          :metrics='relatedMetrics'
          :dataDeficiency='relatedTypeWithDeficientList'
          :typeWithScrapeInfo='relatedTypeWithScrapeInfo'
          :fiscalEndMonth='projectInfo.fiscalEndMonth'
          :isMember='isMember'
          :isFreeMember='isFreeMember'
        />
      </div>
    </div>
  </b-container>
</template>

<script>
import router from '@/router';
import * as Http from '@/lib/http/http';
import Session from '@/lib/model/user/session';
import Project from '@/lib/model/project/project';
import DataCategory from '@/lib/model/project/data-category';
import Init from '@/components/mixins/Init.vue';
import ProjectInfo from '@/components/project/ProjectInfo.vue';
import Metrics from '@/components/project/Metrics.vue';
import RelatedMetrics from '@/components/project/RelatedMetrics.vue';
import ProjectPlan from '@/components/project/ProjectPlan.vue';
import * as Analytics from '@/lib/infrastructure/google/analytics';

export default {
  name: 'ProjectDetail',
  mixins: [
    Init,
  ],
  components: {
    ProjectInfo,
    Metrics,
    RelatedMetrics,
    ProjectPlan,
  },
  data: () => ({
    securityCode: '',
    projectInfo: new Project(),
    isMember: false,
    isFreeMember: false,
    isPaidDefault: false,
    isOP: false,
    userPlans: [],
    allowedCategories: [],
    metrics: [],
    dataDeficiency: {},
    typeWithScrapeInfo: {},
    relatedMetricsExisting: false,
    relatedMetrics: [],
    relatedTypeWithDeficientList: {},
    relatedTypeWithScrapeInfo: {},
    projectPlanList: [],
    packagePlanList: [],
    isLoading: true,
    isMetricsLoading: true,
  }),
  async mounted() {
    await this.init();
    this.scrollTop();
  },
  methods: {
    async init() {
      this.securityCode = decodeURIComponent(this.$route.params.securityCode);
      this.sessionToken = await Session.getSession();
      await Promise.all([
        this.fetchProjectInfo(),
        this.fetchUserPlans(),
      ]);
      this.isLoading = false;
      await Promise.all([
        this.fetchMetrics(),
        this.fetchRelatedMetrics(),
        this.fetchPlans(),
      ]);
      this.isMetricsLoading = false;
    },
    async fetchProjectInfo() {
      const infos = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_DOMAIN}/${process.env.VUE_APP_BACKEND_VERSION}/personal/api/company/${this.securityCode}`,
      ).catch((e) => {
        console.error(e);
        return [];
      });
      // console.log('infos', infos);
      if (!infos.length) router.push({ path: '/' });
      this.projectInfo = new Project(infos[0]);
    },
    async fetchUserPlans() {
      if (!this.sessionToken) return;
      this.isMember = true;
      this.userPlans = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_DOMAIN}/2/personal/plan`,
        this.sessionToken,
      ).catch((e) => {
        console.error(e);
        return [];
      });
      // console.log('userPlans', this.userPlans);
      this.isFreeMember = this.userPlans.every((plan) => plan.planCode === 'PFPfree');
      this.isPaidDefault = this.userPlans.some((plan) => plan.planCode === `${this.securityCode}(default)` || plan.planCode.includes('enterprise'));
      this.isPaidEmployee = this.userPlans.some((plan) => plan.planCode.includes('employee'));
      this.isOP = this.userPlans.some((plan) => plan.planCode === `${this.securityCode}(openHouse)`);
      this.allowedCategories = this.userPlans.reduce((ac, plan) => {
        if (plan.planCode.includes(`${this.securityCode}(`)) {
          const category = plan.planCode.replace(/(.*\(|\))/g, '');
          ac.push(category);
        }
        // packagePlan用
        if (plan.planCode.includes('enterprise')) {
          ac.push('default');
        }
        if (plan.planCode.includes('employee')) {
          ac.push('employee');
        }
        // 社内用
        if (plan.planCode.includes('hands')) {
          ac.push(...Object.keys(DataCategory));
        }

        return ac;
      }, []);
      this.allowedCategories = Array.from(new Set(this.allowedCategories));

      // （GTM上で）subscribeイベントで付与されるis_paid_userフラグを
      // 過去に課金したユーザーにもユーザープロパティを設定するために、一時的に送信する。
      // 半年程度で削除する。（2025年になったら）
      if (!this.isFreeMember) Analytics.sendIsPaidUserEvent();

      /* パッケージのダウンロードはパッケージプランが増えたら検討する */
    },
    async fetchMetrics() {
      if (!this.projectInfo.companyName) return;
      const translatedMetrics = this.sessionToken
        ? await Http.get(
          `https://${process.env.VUE_APP_BACKEND_DOMAIN}/2/personal/translated/metrics/${this.projectInfo.companyName}`,
          this.sessionToken,
        )
        : await Http.get(
          `https://${process.env.VUE_APP_BACKEND_DOMAIN}/2/personal/translated/metrics/public/${this.projectInfo.companyName}`,
        );

      // console.log('translatedMetrics', translatedMetrics);

      if (
        translatedMetrics.metrics
        && translatedMetrics.typeWithDeficientList
        && translatedMetrics.typeWithScrapeInfo
      ) {
        this.metrics = translatedMetrics.metrics;
        this.dataDeficiency = translatedMetrics.typeWithDeficientList;
        this.typeWithScrapeInfo = translatedMetrics.typeWithScrapeInfo;
      }
    },
    async fetchRelatedMetrics() {
      if (!this.sessionToken || this.isFreeMember) return;
      const { relatedMetrics, relatedTypeWithDeficientList, relatedTypeWithScrapeInfo } = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_DOMAIN}/2/personal/translated/related-project-metrics/${this.projectInfo.companyName}`,
        this.sessionToken,
      );
      if (
        relatedMetrics
        && Array.isArray(relatedMetrics)
        && relatedMetrics.length
      ) {
        this.relatedMetricsExisting = !!relatedMetrics.length;
        this.relatedMetrics = JSON.parse(JSON.stringify(relatedMetrics));
        this.relatedTypeWithDeficientList = relatedTypeWithDeficientList;
        this.relatedTypeWithScrapeInfo = relatedTypeWithScrapeInfo;
      }
    },
    async fetchPlans() {
      if (!this.projectInfo.companyName) return;
      const planList = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_DOMAIN}/2/personal/plan/${this.projectInfo.companyName}`,
      );
      // console.log('planList', planList);

      this.projectPlanList = planList.filter((plan) => plan.planCode.includes(this.securityCode));
      this.packagePlanList = planList.filter((plan) => !plan.planCode.includes(this.securityCode));
    },
    goto(link) {
      router.push({ path: link });
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';
.non-member {
  margin: 0 auto;
  padding: 3rem 0;
}
.card-style {
  background-color: #222;
}
</style>

import * as AWS from 'aws-sdk';
import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js';

const CognitoErrorMessage = {
  UserNotFoundException: 'Username is wrong.',
  CodeMismatchException: 'Invalid verification code provided, please try again.',
  NotAuthorizedException: 'Incorrect username or password.',
};

const poolData = {
  UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
  ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
};
const userPool = new CognitoUserPool(poolData);
// cognitoUser is stateful.
// https://github.com/amazon-archives/amazon-cognito-identity-js/issues/365
let cognitoUser;

// eslint-disable-next-line no-unused-vars
const getCredentialsFromCognito = (result) => {
  AWS.config.region = process.env.VUE_APP_AWS_REGION;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: '...',
    Logins: {
      'cognito-idp.ap-northeast-1.amazonaws.com/YOUR_USER_POOL_ID': result.getIdToken().getJwtToken(),
    },
  });
  return AWS.config.credentials.refresh((err) => {
    if (err) {
      console.error(err);
      return Promise.reject(err);
    }
    return Promise.resolve();
  });
};

export const registerUser = ({ username, email, password }) => new Promise((resolve, reject) => {
  const attributeList = [];
  const dataEmail = {
    Name: 'email',
    Value: email,
  };
  const attributeEmail = new CognitoUserAttribute(dataEmail);
  attributeList.push(attributeEmail);
  userPool.signUp(username, password, attributeList, attributeList, (err, result) => {
    if (err) {
      console.error(err);
      return reject(err);
    }
    return resolve(result);
  });
});

export const confirmRegistration = ({ username, code }) => new Promise((resolve, reject) => {
  const userData = {
    Username: username,
    Pool: userPool,
  };
  cognitoUser = new CognitoUser(userData);
  cognitoUser.confirmRegistration(code, true, (err, result) => {
    if (err) {
      console.error(err);
      return reject(err);
    }
    return resolve(result);
  });
});

export const resendConfirmationCode = ({ username }) => new Promise((resolve, reject) => {
  if (!cognitoUser) {
    const userData = {
      Username: username,
      Pool: userPool,
    };
    cognitoUser = new CognitoUser(userData);
  }
  cognitoUser.resendConfirmationCode((err, result) => {
    if (err) {
      console.error(err);
      return reject(err);
    }
    return resolve(result);
  });
});

export const login = ({ username, password }) => new Promise((resolve) => {
  const authenticationData = {
    Username: username,
    Password: password,
  };
  const authenticationDetails = new AuthenticationDetails(authenticationData);
  const userData = {
    Username: username,
    Pool: userPool,
  };
  cognitoUser = new CognitoUser(userData);
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess(result) {
      // const accessToken = result.getAccessToken().getJwtToken();
      result.getAccessToken().getJwtToken();
      return resolve({ status: 'onSuccess' });
    },
    onFailure(err) {
      console.error(err);
      if (err.code === 'PasswordResetRequiredException') {
        return resolve({ status: 'passwordResetRequired' });
      }
      return resolve({ status: 'onFailure' });
    },
    newPasswordRequired(userAttributes, requiredAttributes) {
      // eslint-disable-next-line no-param-reassign
      delete userAttributes.email_verified;
      return resolve({ status: 'newPasswordRequired', requiredAttributes, userAttributes });
    },
  });
});

export const checkCurrentUserValid = () => new Promise((resolve) => {
  cognitoUser = userPool.getCurrentUser();
  if (!cognitoUser) {
    console.log('Current User is not logged in.');
    resolve(false);
    return;
  }
  // eslint-disable-next-line consistent-return
  return cognitoUser.getSession((err, result) => {
    if (err) {
      console.error(err);
      resolve(false);
      return;
    }
    if (result) {
      console.log('Current User is valid.');
      resolve(result);
      return;
    }
    resolve(false);
  });
});

export const setNewPassword = (
  newPassword,
  sessionUserAttributes,
) => new Promise((resolve) => {
  if (!cognitoUser) {
    console.log('User is not authenticated.');
    resolve(false);
    return;
  }

  cognitoUser.completeNewPasswordChallenge(newPassword, sessionUserAttributes, {
    onSuccess(result) {
      result.getAccessToken().getJwtToken();
      return resolve({ status: 'onSuccess' });
    },
    onFailure(err) {
      console.error('CompleteNewPasswordChallenge Error');
      console.error(err);
      return resolve({ status: 'onFailure' });
    },
  });
});

// verification codeがmailに飛ばすことができる
// inputVerificationCode内でprompt使ってこのメソッド内で処理させる必要がある
export const forgetPassword = (username) => new Promise((resolve) => {
  const userData = {
    Username: username,
    Pool: userPool,
  };
  cognitoUser = new CognitoUser(userData);

  if (!cognitoUser) {
    console.error('User is not properly status.');
    resolve({ status: 'onFailure' });
    return;
  }

  cognitoUser.forgotPassword({
    onSuccess(result) {
      console.log(result);
      return resolve({ status: 'onSuccess' });
    },
    onFailure(err) {
      console.error(err);
      const errMessage = CognitoErrorMessage[err.code] || err;
      return resolve({ status: 'onFailure', err: errMessage });
    },
    // inputVerificationCode() {
    //   console.log(verificationCode, newPassword, this);
    //   return cognitoUser.confirmPassword(verificationCode, newPassword, this);
    // },
  });
});

export const confirmPassword = (
  newPassword,
  verificationCode,
) => new Promise((resolve) => {
  if (!cognitoUser) {
    console.error('User is not properly status.');
    resolve(false);
    return;
  }
  cognitoUser.confirmPassword(verificationCode, newPassword, {
    onSuccess(result) {
      console.log(result);
      return resolve({ status: 'onSuccess' });
    },
    onFailure(err) {
      console.error(err);
      const errMessage = CognitoErrorMessage[err.code] || err;
      return resolve({ status: 'onFailure', err: errMessage });
    },
  });
});

export const changePassword = (currentPassword, newPassword) => new Promise((resolve) => {
  if (!cognitoUser) {
    console.error('User is not properly status.');
    resolve({ status: 'onFailure', err: 'User is not properly status. Please Signin again.' });
    return;
  }
  cognitoUser.changePassword(currentPassword, newPassword, (err, result) => {
    if (err) {
      console.error(err);
      let errMessage = CognitoErrorMessage[err.code] || err;
      if (err.code === 'NotAuthorizedException') {
        errMessage = 'Current Password is incorrect.';
      }
      return resolve({ status: 'onFailure', err: errMessage });
    }
    console.log(result);
    return resolve({ status: 'onSuccess' });
  });
});

export const getSession = () => new Promise((resolve) => {
  if (!cognitoUser) {
    console.error('User is not authenticated.');
    resolve(false);
    return;
  }
  cognitoUser.getSession((err, session) => {
    if (err) {
      console.error(err);
      resolve(false);
    }
    resolve(session.getIdToken().getJwtToken());
  });
});

export const logout = () => {
  if (!cognitoUser) {
    console.log('User is not authenticated.');
    return;
  }
  cognitoUser.signOut();
  console.log('logout.');
};

<script>
import * as Http from '@/lib/http/http';
import Session from '@/lib/model/user/session';
import Singin from '@/lib/model/user/signin';
import router from '@/router/index';

export default {
  components: {},
  name: 'LoginRestriction',
  data: () => ({}),
  async beforeCreate() {
    const token = await Session.getSession();
    if (!token) {
      console.error('Session Error.');
      return;
    }
    const isLoginAllowed = await Http.get(
      `https://${process.env.VUE_APP_BACKEND_USER_DOMAIN}/${process.env.VUE_APP_BACKEND_USER_VERSION}/personal/user/login/restrict`,
      token,
    ).catch((e) => {
      console.error(e);
      return false;
    });
    if (!isLoginAllowed) {
      Singin.doSignout();
      router.push({
        name: 'about',
        params: {
          isRestricted: true,
        },
      }).catch(() => {});
    }
  },
  methods: {},
};
</script>

/* eslint-disable no-shadow,no-unused-vars */
import Project from '../../../lib/model/project/project';

import * as LocalStorage from '../../../lib/infrastructure/local-storage/local-storage';

const storageKeyName = `${process.env.NODE_ENV}-ProjectList`;

const state = {
  projectList: [],
};

const getters = {
  getProjectList: (
    state,
    _getters,
    _rootState,
    _rootGetters,
  ) => state.projectList
    .map((p) => new Project(p)),
};

const actions = {
  getAllFromLocal({ commit, state, getters }) {
    const list = LocalStorage.get(storageKeyName);
    commit('setProjectList', list || []);
    return getters.getProjectList;
  },
  setProjectList({ commit, state }, projectList) {
    commit('setProjectList', projectList);
    try {
      LocalStorage.upsert(storageKeyName, state.projectList);
    } catch (e) {
      console.log(e);
      commit('reset');
      LocalStorage.upsert(storageKeyName, state.projectList);
    }
  },
  removeProjectFromList({ commit, state }, companyName) {
    commit('removeProjectFromList', companyName);
    LocalStorage.upsert(storageKeyName, state.projectList);
  },
  resetProjectList({ commit, state }) {
    commit('reset');
    LocalStorage.upsert(storageKeyName, state.projectList);
  },
};

const mutations = {
  setProjectList(state, projectList) {
    state.projectList = projectList.map((p) => new Project(p));
  },
  reset(state) {
    state.projectList = [];
  },
  removeProjectFromList(state, companyName) {
    state.projectList = state.projectList.filter((p) => p.companyName !== companyName);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

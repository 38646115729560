<script>
export default {
  components: {},
  name: 'Init',
  data: () => ({
  }),
  created() {
    this.scrollTop();
  },
  mounted() {},
  methods: {
    scrollTop() {
      window.scroll(0, 0);
    },
  },
};
</script>

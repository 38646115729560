/* eslint-disable no-shadow,no-unused-vars */
import * as LocalStorage from '../../../lib/infrastructure/local-storage/local-storage';

const statusStorageKeyName = `${process.env.NODE_ENV}-userInfoStatus`;
const referralStorageKeyName = `${process.env.NODE_ENV}-userInfoReferral`;

const state = {
  userStatus: '',
  userReferral: '',
};

const getters = {
  getUserStatus: (
    state,
    _getters,
    _rootState,
    _rootGetters,
  ) => state.userStatus,
  getUserReferral: (
    state,
    _getters,
    _rootState,
    _rootGetters,
  ) => state.userReferral,
};

const actions = {
  getUserStatus({ commit, state, getters }) {
    const userStatus = LocalStorage.get(statusStorageKeyName);
    commit('setUserStatus', userStatus || '');
    return getters.getUserStatus;
  },
  getUserReferral({ commit, state, getters }) {
    const userReferral = LocalStorage.get(referralStorageKeyName);
    commit('setUserReferral', userReferral || '');
    return getters.getUserReferral;
  },
  async setUserStatus({ commit, state }, userStatus) {
    commit('setUserStatus', userStatus);
    LocalStorage.upsert(statusStorageKeyName, state.userStatus);
  },
  async setUserReferral({ commit, state }, userReferral) {
    commit('setUserReferral', userReferral);
    LocalStorage.upsert(referralStorageKeyName, state.userReferral);
  },
  resetUserStatus({ commit, state }) {
    commit('resetUserStatus');
    LocalStorage.upsert(statusStorageKeyName, state.userStatus);
  },
  resetUserReferral({ commit, state }) {
    commit('resetUserReferral');
    LocalStorage.upsert(referralStorageKeyName, state.userReferral);
  },
};

const mutations = {
  setUserStatus(state, userStatus) {
    state.userStatus = userStatus;
  },
  setUserReferral(state, userReferral) {
    state.userReferral = userReferral;
  },
  resetUserStatus(state) {
    state.userStatus = '';
  },
  resetUserReferral(state) {
    state.userReferral = '';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

/* eslint-disable no-shadow,no-unused-vars */

const state = {
  selects: [],
};

const getters = {
  getSelected: (
    state,
    _getters,
    _rootState,
    _rootGetters,
  ) => state.selects.concat([]),
};

const actions = {
  get({ getters }) {
    return getters.getSelected;
  },
  async set({ commit }, list) {
    commit('setSelected', list);
  },
  async reset({ commit }) {
    commit('resetSelected');
  },
};

const mutations = {
  setSelected(state, list) {
    state.selects = list.concat([]);
  },
  resetSelected(state) {
    state.selects = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import Vue from 'vue';
import VueRouter from 'vue-router';
import { setUserId } from '../lib/infrastructure/google/analytics';
import Login from '../lib/model/user/signin';
import * as Crypto from '../lib/helper/crypto-helper';

import About from '../views/About.vue';
import Top from '../views/Top.vue';

import List from '../views/public/List.vue';
import Plan from '../views/public/Plan.vue';
import ProjectDetail from '../views/public/ProjectDetail.vue';

import Terms from '../views/common/Terms.vue';
import Law from '../views/common/Law.vue';
import Faq from '../views/common/Faq.vue';
import ContactForm from '../views/common/ContactForm.vue';

import Signup from '../views/user/Signup.vue';
import Verify from '../views/user/Verify.vue';
import Signin from '../views/user/Signin.vue';
import ForgetPassword from '../views/user/ForgetPassword.vue';

import User from '../views/user/User.vue';
import UserDelete from '../views/user/UserDelete.vue';
import UserDeleteComplate from '../views/user/UserDeleteComplate.vue';
import UserSubscription from '../views/user/UserSubscription.vue';

import Payment from '../views/payment/Payment.vue';
import PaymentComplete from '../views/payment/PaymentComplete.vue';
import PaymentCustom from '../views/payment/PaymentCustom.vue';
import PaymentCustomComplete from '../views/payment/PaymentCustomComplete.vue';

import JobSalary from '../views/member/JobSalary.vue';
import Employee from '../views/member/Employee.vue';

Vue.use(VueRouter);

const sendAnalytics = async (cognitoUser) => {
  const { email } = cognitoUser.idToken.payload;
  const userId = await Crypto.sha256(email);
  setUserId(userId);
};

const singinCheck = async (to, next, ngPath) => {
  const cognitoUser = await Login.checkValid();
  if (cognitoUser) {
    sendAnalytics(cognitoUser);
    next();
  } else {
    next({ path: ngPath });
  }
};

const singinCheckPublic = async (to, next) => {
  const cognitoUser = await Login.checkValid();
  if (cognitoUser) sendAnalytics(cognitoUser);
  next();
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'about',
      component: About,
      props: true,
      beforeEnter: async (to, from, next) => {
        const cognitoUser = await Login.checkValid();
        if (cognitoUser) {
          sendAnalytics(cognitoUser);
          next({ path: '/top' });
        } else {
          next();
        }
      },
    },
    {
      path: '/signin',
      name: 'signin',
      component: Signin,
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup,
    },
    {
      path: '/forget/password',
      name: 'forgetPassword',
      component: ForgetPassword,
    },
    {
      path: '/verify',
      name: 'verify',
      component: Verify,
    },
    {
      path: '/terms',
      name: 'terms',
      component: Terms,
    },
    {
      path: '/law',
      name: 'law',
      component: Law,
    },
    {
      path: '/faq',
      name: 'faq',
      component: Faq,
    },
    {
      path: '/contact',
      name: 'contactForm',
      component: ContactForm,
      props: true,
    },
    {
      path: '/list',
      name: 'list',
      component: List,
      beforeEnter: async (to, from, next) => {
        await singinCheckPublic(to, next);
      },
    },
    {
      path: '/top',
      name: 'top',
      component: Top,
      beforeEnter: async (to, from, next) => {
        await singinCheck(to, next, '/signin');
      },
    },
    {
      path: '/project/detail/:securityCode',
      name: 'detail',
      component: ProjectDetail,
      beforeEnter: async (to, from, next) => {
        await singinCheckPublic(to, next);
      },
    },
    {
      path: '/plan',
      name: 'plan',
      component: Plan,
      beforeEnter: async (to, from, next) => {
        await singinCheckPublic(to, next);
      },
    },
    {
      path: '/salary',
      name: 'salary',
      component: JobSalary,
      beforeEnter: async (to, from, next) => {
        await singinCheckPublic(to, next);
      },
    },
    {
      path: '/employee',
      name: 'employee',
      component: Employee,
      beforeEnter: async (to, from, next) => {
        await singinCheck(to, next, '/signin');
      },
    },
    {
      path: '/user',
      name: 'user',
      component: User,
      beforeEnter: async (to, from, next) => {
        await singinCheck(to, next, '/signin');
      },
    },
    {
      path: '/user/delete',
      name: 'userDelete',
      component: UserDelete,
      beforeEnter: async (to, from, next) => {
        await singinCheck(to, next, '/signin');
      },
    },
    {
      path: '/user/delete/complate',
      name: 'userDeleteComplate',
      component: UserDeleteComplate,
      beforeEnter: async (to, from, next) => {
        await singinCheck(to, next, '/signin');
      },
    },
    {
      path: '/user/subscription',
      name: 'userSubscription',
      component: UserSubscription,
      beforeEnter: async (to, from, next) => {
        await singinCheck(to, next, '/signin');
      },
    },
    {
      path: '/payment/package/:planCode',
      name: 'paymentPackage',
      component: Payment,
      beforeEnter: async (to, from, next) => {
        await singinCheck(to, next, '/signup');
      },
    },
    {
      path: '/payment/unit/:securityCode/:category',
      name: 'paymentUnit',
      component: Payment,
      beforeEnter: async (to, from, next) => {
        await singinCheck(to, next, '/signin');
      },
    },
    {
      path: '/payment/package/:planCode/complete',
      name: 'paymentPackageComplete',
      component: PaymentComplete,
      beforeEnter: async (to, from, next) => {
        if (to.params.planCode !== from.params.planCode) next({ path: '/top' });
        await singinCheck(to, next, '/sign');
      },
      props: true,
    },
    {
      path: '/payment/unit/:securityCode/:category/complete',
      name: 'paymentUnitComplete',
      component: PaymentComplete,
      beforeEnter: async (to, from, next) => {
        if (
          to.params.securityCode !== from.params.securityCode
          && to.params.category !== from.params.category
        ) next({ path: '/top' });
        await singinCheck(to, next, '/signin');
      },
      props: true,
    },
    {
      path: '/payment/custom/:planCode',
      name: 'PaymentCustom',
      component: PaymentCustom,
      beforeEnter: async (to, from, next) => {
        await singinCheck(to, next, '/signup');
      },
    },
    {
      path: '/payment/custom/:planCode/complete',
      name: 'paymentCustomComplete',
      component: PaymentCustomComplete,
      beforeEnter: async (to, from, next) => {
        if (to.params.planCode !== from.params.planCode) next({ path: '/top' });
        await singinCheck(to, next, '/sign');
      },
      props: true,
    },
  ],
  scrollBehavior() {
    const selector = window.location.hash;
    return selector ? { selector } : { x: 0, y: 0 };
  },
});

export default router;

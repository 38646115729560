import Vue from 'vue';
import Vuex from 'vuex';

import ContractList from './modules/project/project-contract-list';
import ProjectList from './modules/project/project-list';
import Metrics from './modules/project/metrics';
import Login from './modules/user/login';
import UserInfo from './modules/user/userInfo';
import Payment from './modules/user/payment';
import ContractedPlan from './modules/user/contracted-plan';
import SelectedUnits from './modules/project/selected-units';
import SelectedCmUnits from './modules/project/selected-cm-units';
import SelectedSmartReceiptsUnits from './modules/project/selected-smart-receipts-units';
import SelectedGeolocationUnits from './modules/project/selected-geolocation-units';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    ContractList,
    ProjectList,
    Metrics,
    Login,
    UserInfo,
    Payment,
    ContractedPlan,
    SelectedUnits,
    SelectedCmUnits,
    SelectedSmartReceiptsUnits,
    SelectedGeolocationUnits,
  },
});

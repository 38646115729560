/* eslint-disable no-restricted-globals */
import BaseEntity from '../base-entity';

import { keyCheck } from '../../helper/object-helper';

export default class Metrics extends BaseEntity {
  constructor(obj) {
    super(obj);
    this.date = keyCheck(obj, 'date') && isFinite(obj.date) ? Number(obj.date) : 0;
    this.displayType = keyCheck(obj, 'displayType') ? obj.displayType : '';
    this.metrics = keyCheck(obj, 'metrics') ? obj.metrics : {};
    this.type = keyCheck(obj, 'type') ? obj.type : '';
    this.typeInsights = keyCheck(obj, 'typeInsights') ? obj.typeInsights : '';
  }
}

<template>
  <section>
    <div class="metrics-group">
      <div v-for='(value, type) in metricsEveryType' :key='type' class="metrics-panel">
        <div class="metrics-component">
          <h5>{{ type }}</h5>
          <div>
            <div v-if="isMember">
<!--              <MetricsGraph style="height:300px;" :type='type' :data='value.metrics' :term='value.term' :fiscalEndMonth='fiscalEndMonth'/>-->
              <MetricsGraphEcharts
                style="height:300px;"
                :type='type'
                :data='value.metrics'
                :term='value.term'
                :fiscalEndMonth='fiscalEndMonth'
                :deficientList='dataDeficiency[type]'
                :scrapeInfo='typeWithScrapeInfo[type]'
              />
            </div>
            <div v-else class="dummy-metrics">
              <img src="/dummy_chart.svg" class="blur-img">
              <div class="dummy-metrics-text">
                <a href="/signup" class="text-light">無料会員登録いただくとご覧いただけます</a>
              </div>
            </div>
            <b-form-group>
              <b-button-group>
                <b-button
                  class="py-1 px-2"
                  :variant="durationSelected[type] === 'origin' ? 'info' : null"
                  @click="selectDuration('origin', type)"
                >
                  Origin
                </b-button>
                <b-button
                  class="py-1 px-2"
                  v-if="!!fiscalEndMonth && showableDuration('quarterly', typeWithScrapeInfo, type)"
                  :variant="durationSelected[type] === 'quarterly' ? 'info' : null"
                  @click="selectDuration('quarterly', type)"
                >
                  Quarterly
                </b-button>
                <b-button
                  class="py-1 px-2"
                  v-if="showableDuration('monthly', typeWithScrapeInfo, type)"
                  :variant="durationSelected[type] === 'monthly' ? 'info' : null"
                  @click="selectDuration('monthly', type)"
                >
                  Monthly
                </b-button>
                <b-button
                  class="py-1 px-2"
                  v-if="showableDuration('weekly', typeWithScrapeInfo, type)"
                  :variant="durationSelected[type] === 'weekly' ? 'info' : null"
                  @click="selectDuration('weekly', type)"
                >
                  Weekly
                </b-button>
              </b-button-group>
              <b-button-group class="ml-3" v-show="!clickableCalcType(type)">
                <b-button
                  class="py-1 px-2"
                  :variant="calcTypeSelected[type] === 'avg' ? 'info' : null"
                  @click="selectCalcType('avg', type)"
                >
                  Avg
                </b-button>
                <b-button
                  class="py-1 px-2"
                  :variant="calcTypeSelected[type] === 'sum' ? 'info' : null"
                  @click="selectCalcType('sum', type)"
                >
                  Sum
                </b-button>
              </b-button-group>
            </b-form-group>
          </div>
          <b-row class="metrics-desc">
            <b-col cols="6" md="4" class="px-3">
              <label>取得開始日</label>
              <p>{{ fetchStartDate(typeWithScrapeInfo, type) }}</p>
            </b-col>
            <b-col cols="6" md="4" class="px-3">
              <label>データ粒度</label>
              <p>{{ fetchIntervalNum(typeWithScrapeInfo, type) }}{{ fetchIntervalUnit(typeWithScrapeInfo, type) }}</p>
            </b-col>
            <b-col v-if="insightsByType[type]" cols="12" class="p-3">
              <label>備考</label>
              <p>{{ insightsByType[type] }}</p>
            </b-col>
          </b-row>
          <div class="text-right mt-auto">
            <div>
              DOWNLOAD:
              <b-link class="mx-2" v-on:click='downloadCSV(type, value)' :disabled='disableDownload()' :style="disableDownload() && 'color:grey'">CSV</b-link>
              /
              <b-link class="mx-2" v-on:click='downloadJSON(type, value)' :disabled='disableDownload()' :style="disableDownload() && 'color:grey'">JSON</b-link>
            </div>
            <div class="small text-secondary">
              <div>CSVファイルをExcelで開く際、Excelの仕様により文字化けや表示形式の変換がされる場合がございます。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment';
import { summarize } from '@/lib/service/metrics-summarizer';
import MetricsSetting from '@/lib/model/project/metrics-setting';
import MetricsGraphEcharts from './MetricsGraphEcharts.vue';

export default {
  name: 'RelatedMetrics',
  components: {
    MetricsGraphEcharts,
  },
  props: ['companyName', 'metrics', 'dataDeficiency', 'typeWithScrapeInfo', 'fiscalEndMonth', 'isMember', 'isFreeMember'],
  methods: {
    disableDownload() {
      return this.isFreeMember;
    },
    async downloadCSV(type, data) {
      const format = 'text/csv';
      const csvData = this.toCSV(data);
      this.download(type, csvData, format);
    },
    async downloadJSON(type, data) {
      const format = 'application/json';
      this.download(type, JSON.stringify(data), format);
    },
    download(type, data, format) {
      const link = window.document.createElement('a');
      link.href = URL.createObjectURL(new Blob([data], { type: format }));
      link.download = `${this.companyName}_${type}_metrics_exported_${moment().format('x')}`;
      document.body.appendChild(link);
      link.click();
    },
    toCSV(data) {
      const header = ['date', ...Object.keys(data.metrics.find((d) => d).metrics)].join(',');
      const body = data.metrics.reduce((ac, content) => `${ac}\n${[moment(Number(content.date)).format('YYYY/MM/DD'), ...Object.values(content.metrics)].join(',')}`, '');
      return `\ufeff${header}${body}`;
    },
    selectDuration(type, metricsType) {
      this.durationSelected[metricsType] = type;
      if (this.durationSelected[metricsType] === 'origin') {
        this.calcTypeSelected[metricsType] = 'avg';
        this.resetMetrics(metricsType);
      }
      if (this.durationSelected[metricsType] !== 'origin') this.summarize(type, metricsType);
    },
    selectCalcType(type, metricsType) {
      this.calcTypeSelected[metricsType] = type;
      if (this.durationSelected[metricsType] !== 'origin') this.summarize(type, metricsType);
    },
    resetMetrics(metricsType) {
      this.metricsEveryType[metricsType] = {
        metrics: JSON.parse(
          JSON.stringify(this.originMetricsEveryType[metricsType].metrics),
        ),
        term: 'origin',
      };
    },
    summarize(type, metricsType) {
      const metricsSettings = {
        duration: this.durationSelected[metricsType],
        calcType: this.calcTypeSelected[metricsType],
      };
      if (this.durationSelected[metricsType] === 'quarterly' && this.fiscalEndMonth) metricsSettings.fiscalEndMonth = this.fiscalEndMonth;
      this.metricsEveryType[metricsType] = {
        term: this.durationSelected[metricsType],
        metrics: summarize(
          new MetricsSetting(metricsSettings),
          JSON.parse(JSON.stringify(this.originMetricsEveryType[metricsType].metrics)),
        ),
      };
    },
    clickableCalcType(metricsType) {
      return this.durationSelected[metricsType] === 'origin';
    },
    fetchStartDate(typeWithScrapeInfo, type) {
      return typeWithScrapeInfo && typeWithScrapeInfo[type] && typeWithScrapeInfo[type].startDate;
    },
    fetchIntervalNum(typeWithScrapeInfo, type) {
      return typeWithScrapeInfo && typeWithScrapeInfo[type] && typeWithScrapeInfo[type].intervalNum;
    },
    fetchIntervalUnit(typeWithScrapeInfo, type) {
      const intervalUnit = typeWithScrapeInfo && typeWithScrapeInfo[type] && typeWithScrapeInfo[type].intervalUnit;
      if (intervalUnit === 'day') return '日';
      if (intervalUnit === 'week') return '週';
      return 'ヶ月';
    },
    showableDuration(duration, typeWithScrapeInfo, type) {
      if (!typeWithScrapeInfo || !typeWithScrapeInfo[type] || !typeWithScrapeInfo[type].intervalUnit) return true;
      const { intervalUnit } = typeWithScrapeInfo[type];
      if (intervalUnit === 'day') return true;
      if (intervalUnit === 'week') return this.durationOrder[duration] > this.durationOrder.weekly;
      if (intervalUnit === 'month') return this.durationOrder[duration] > this.durationOrder.monthly;
      if (intervalUnit === 'quarter') return this.durationOrder[duration] > this.durationOrder.quarterly;
      return true;
    },
  },
  data: () => ({
    sessionToken: '',
    originMetricsEveryType: {},
    metricsEveryType: {},
    insightsByType: {},
    durationSelected: {},
    calcTypeSelected: {},
    editedDeficientListByType: {},
    durationOrder: {
      daily: 0,
      weekly: 1,
      monthly: 2,
      quarterly: 3,
    },
  }),
  watch: {
    metrics(metrics) {
      this.metricsEveryType = metrics.reduce((ac, metric) => {
        const params = {
          date: metric.date,
          metrics: metric.metrics,
        };
        if (!ac[metric.type]) {
          // 副作用がここであるのはどうかというのはあるが、今のデータ構造だと一番効率的
          this.insightsByType[metric.type] = !!metric.typeInsights && metric.typeInsights !== 'Null' ? metric.typeInsights : '';
          const matchedList = Array.from(new Set(this.insightsByType[metric.type].match(/((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g)))
            .sort((a, b) => (a.length < b.length ? 1 : -1));
          if (matchedList && matchedList.length) {
            matchedList.forEach((matched) => {
              const escapedMatched = matched.replace('?', '\\?').replace('&', '\\&');
              const regex = new RegExp(`(?!(.*href=|.*\\/a>))${escapedMatched}`, 'g');
              this.insightsByType[metric.type] = this.insightsByType[metric.type].replace(
                regex,
                `<a href=${matched} target="_blank">${matched}</a>`,
              );
            });
          }
          this.editedDeficientListByType[metric.type] = this.dataDeficiency && this.dataDeficiency[metric.type] ? this.dataDeficiency[metric.type] : '';
          return Object.assign(ac, {
            [metric.type]: {
              metrics: [params],
              term: 'origin',
            },
          });
        }
        ac[metric.type].metrics.push(params);
        return ac;
      }, {});
      this.originMetricsEveryType = JSON.parse(JSON.stringify(this.metricsEveryType));
      Object.keys(this.metricsEveryType).forEach((key) => {
        this.durationSelected = Object.assign(JSON.parse(JSON.stringify(this.durationSelected)), { [key]: 'origin' });
        this.calcTypeSelected = Object.assign(JSON.parse(JSON.stringify(this.calcTypeSelected)), { [key]: 'avg' });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';
.btn {
  border-radius: 0;
}
.metrics-group {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 2%;
}
.metrics-panel {
  width: 49%;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
.metrics-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid #444;
  background-color: #303030;
  padding: 2rem;
  @include media-breakpoint-down(md) {
    padding: 1rem;
  }
}
.metrics-desc {
  font-size: 15px;
  padding-bottom: 1rem;
  label {
    margin-bottom: 0;
    font-size: small;
    color: #6c757d;
  }
}
.dummy-metrics {
  position: relative;
  height: 300px;
}
.blur-img {
  filter: blur(5px);
}
.dummy-metrics-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: x-large;
  text-align: center;
  padding: 0 3rem;
}
</style>

<template>
  <b-container>
    <h1 class="text-center mb-5">ご契約ありがとうございます</h1>
    <b-row class="justify-center">
      <b-col cols="12" md="6">
        <div class="desc-panel mb-5">
          <div class="mb-5">
            <h5 class="font-weight-bold mb-3">プラン名</h5>
            <h5>{{ subscription.planName }}</h5>
          </div>
          <div class="">
            <h5 class="font-weight-bold mb-3">料金</h5>
            <div class="d-flex">
              <h5>月額</h5>
              <h5 class="text-right">{{ amountFormatter(subscription.amount + subscription.tax) }} <small>円</small></h5>
            </div>
            <div class="text-right">
              <small class="text-secondary">１ヶ月ごとに自動更新</small>
            </div>
          </div>
        </div>
        <div class="text-right">
          <b-button @click="goto('/user/subscription')" variant="info" class="mr-3">契約情報</b-button>
          <b-button @click="goto('/top')" variant="info">TOP</b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import router from '@/router';
import Init from '@/components/mixins/Init.vue';

export default {
  name: 'PaymentCustomComplete',
  mixins: [Init],
  props: ['planCode', 'subscription'],
  components: {},
  data: () => ({}),
  methods: {
    goto(link) {
      router.push({ path: link });
    },
    amountFormatter(amount) {
      return `${String(amount).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';

.desc-panel {
  padding: 2rem;
  background-color: #303030;
  border: 1px solid #444;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
</style>

<template>
  <b-container>
    <h1 class="mb-5">特定商取引</h1>
    <div class="pera-panel law">
      <h2>販売業者</h2>
      <hr color="#aaa">
      <div class="h4 mt-3 mb-5">株式会社hands</div>
      <h2>所在地</h2>
      <hr color="#aaa">
      <div class="h5 mt-3">〒150-0001</div>
      <div class="h4 mb-5">東京都渋谷区神宮前5-53-67  青山コスモスサウス3 階</div>
      <h2>運営統括責任者</h2>
      <hr color="#aaa">
      <div class="mt-3">カドワキ マキト</div>
      <div class="h4 mb-5">門脇 蒔人</div>
      <h2>お問い合わせ先</h2>
      <hr color="#aaa">
      <div class="mt-3">&lt;TEL&gt;</div>
      <div class="h4 mb-3">03-6555-4237</div>
      <div class="">&lt;MAIL&gt;</div>
      <div class="h4 mb-3">contact-pfp@hands-inc.jp</div>
      <div class="">&lt;HP&gt;</div>
      <div class="h4 mb-5">https://hands-inc.jp/</div>
      <h2>販売価格帯</h2>
      <hr color="#aaa">
      <div class="h5 mt-3">本サイト上に記載</div>
      <div class="h4 mb-5">25,000 円~100,000 円 (税抜)</div>
      <h2>商品等の引き渡し時期（日数）・発送方法</h2>
      <hr color="#aaa">
      <div class="h4 mt-3 mb-5">お申込翌営業日までにweb 上で提供</div>
      <h2>代金の支払時期および方法</h2>
      <hr color="#aaa">
      <div class="h4 mt-3 mb-5">サービス契約時/ クレジットカード</div>
      <h2>商品以外の必要料金</h2>
      <hr color="#aaa">
      <div class="h4 mt-3 mb-5">消費税</div>
      <h2>返品の取扱条件／返品期限、返品時の送料負担または解約や退会条件</h2>
      <hr color="#aaa">
      <div class="h4 mt-3 mb-5">解約や退会の場合は期間満了日までに専用の画面にて申し込み</div>
      <h2>不良品の取扱条件</h2>
      <hr color="#aaa">
      <div class="h4 mt-3 mb-5">
        デジタルコンテンツのため不良品はございませんが、
        提供データに明らかな間違いがあった場合に、契約者様からご連絡をいただいた後、
        返金手続きを進めさせていただく場合がございます。
      </div>
    </div>
  </b-container>
</template>

<script>
import Init from '@/components/mixins/Init.vue';

export default {
  name: 'Law',
  mixins: [
    Init,
  ],
  components: {
  },
  data: () => ({}),
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';
</style>

<template>
  <div class="panel-desc form-row align-items-start">
    <img v-show="logo" :src="logo" class="brand-logo col-1 my-auto">
    <h4 class="ml-2 my-auto">{{ shortenCardNo }}</h4>
  </div>
</template>

<script>
export default {
  name: 'MaskedCard',
  props: ['cardNo'],
  data: () => ({
    logo: '',
    shortenCardNo: '',
  }),
  mounted() {
    this.update();
  },
  methods: {
    update() {
      this.shortenCardNo = this.cardNo.replace('*******', '');
      if (/^(4\d{2}\*|\*)/g.test(this.cardNo)) { // visa
        this.logo = '/Visa_Brandmark_White_RGB_2021.png';
      } else if (/^5[1-5]\d\*/g.test(this.cardNo)) { // ms
        this.logo = '/mc_symbol_opt_73_3x.png';
      } else if (/^3[47]\*/g.test(this.cardNo)) { // amex
        this.logo = '/AXP_1C_solid_2.jpg';
      } else if (/^3\*/g.test(this.cardNo)) { // diners
        this.logo = '/Diners_Logo_for_Merchant_2.jpg';
      } else if (/(35\d|21|18)\*/g.test(this.cardNo)) { // jcb
        this.logo = '/JCB(B-3).gif';
      } else {
        this.logo = '';
      }
    },
  },
  watch: {
    cardNo() {
      this.update();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';
.panel-desc {
  font-size: 1.5vw;
}
.brand-logo {
  max-width: 50px;
}
</style>

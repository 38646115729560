<template>
  <div class="plan-panel">
    <h3 class="font-weight-bold mb-4">PERAGARU賃金データプラン</h3>
    <div class="mb-4">
      <div>PERAGARU 賃金データを閲覧できるプランです。ファイルのダウンロードも可能です。</div>
    </div>
    <div class="mb-5">
      <h6 class="mb-3 font-weight-bold">閲覧できるメトリクス：</h6>
      <div class="mb-3">
        <h6 class="font-weight-bold">求人件数（指数）</h6>
<!--        <div>-->
<!--          上場企業とその子会社の従業員数の増減を追跡することで、企業や業界の成長トレンドを把握。これにより、成長軌道にある企業や業界を見極めることができます。-->
<!--        </div>-->
      </div>
      <div class="mb-3">
        <h6 class="font-weight-bold">平均募集賃金（実数値）</h6>
<!--        <div>-->
<!--          従業員数のデータから、将来の売り上げや利益の増加を予測。報告書が公開される前に、業績が良好な企業を見つけ出すことができます。-->
<!--        </div>-->
      </div>
      <div>
        <h6 class="font-weight-bold">平均募集賃金（指数）</h6>
<!--        <div>-->
<!--          従業員数の急激な減少は、企業や業界が直面している潜在的な問題を示唆します。これをもとにリスクを避け、投資の安全性を高めることができます。-->
<!--        </div>-->
      </div>
    </div>
    <div class="mb-5">
      <h5 class="mb-3 font-weight-bold">料金</h5>
      <h5>3,850 <small>円</small></h5>
    </div>
    <div class="text-right">
      <b-button
        @click="gotoPaymentPackage('salary')"
        :disabled='isContracted'
        :variant="isContracted ? 'secondary' : 'info'">
        ご契約はこちら
      </b-button>
      <div class="text-secondary">非会員の方は会員登録に移動します</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import router from '../../router';

export default {
  name: 'PlanSalary',
  data: () => ({
    isContracted: false,
  }),
  async mounted() {
    this.isContracted = await this.checkContractedWithPlanCode('salary');
  },
  methods: {
    ...mapActions({
      checkContractedWithPlanCode: 'ContractedPlan/checkContractedWithPlanCode',
    }),
    goto(link) {
      router.push({ path: link });
    },
    gotoPaymentPackage(planId) {
      router.push({
        path: `/payment/package/${planId}`,
      });
    },
    priceFormatter(price) {
      return `${String(price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')} 円/税込`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';

.plan-panel {
  display: flex;
  flex-direction: column;
  background-color: #333;
  border: 1px solid #6c757d;
  padding: 2rem;
}
</style>

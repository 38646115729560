<template>
  <div class="plan-panel">
    <h3 class="font-weight-bold mb-4">個別銘柄カテゴリープラン</h3>
    <div class="mb-4">
      <div>１銘柄の中からカテゴリー単位で、メトリクスを閲覧できるプランです。</div>
      <div class="text-secondary">※TVCM出稿量を除くカテゴリーではファイルのダウンロードも可能です。</div>
    </div>
    <div class="mb-5">
      <h6 class="mb-3 font-weight-bold">カテゴリー：</h6>
      <div class="d-inline-flex">
        <div class="flex-category">
          <div>Webスクレイピング</div>
          <div>TVCM出稿量</div>
          <div>レシート販売金額</div>
          <div>拠点滞在時間</div>
          <div>発電量</div>
          <div>トレーディングカード販売量</div>
        </div>
      </div>
    </div>
    <div class="mb-4 h-100">
      <h5 class="mb-3 font-weight-bold">ご契約の流れ</h5>
      <div class="payment-flow">
        <div>
          <label>1. 銘柄一覧から銘柄選択</label>
          <img src="/list_view.png" class="shadow" style="width:100%;border:1px solid #6c757d">
        </div>
        <div>
          <label>2. データ確認</label>
          <img src="/metrics_view.png" class="shadow" style="width:100%;border:1px solid #6c757d">
        </div>
        <div>
          <label>3. プラン選択</label>
          <img src="/detail_top_view.png" class="shadow" style="width:100%;border:1px solid #6c757d">
        </div>
        <div>
          <label>4. 決済確認</label>
          <img src="/payment_view.png" class="shadow" style="width:100%;border:1px solid #6c757d">
        </div>
      </div>
      <div class="text-secondary text-right mt-2">※画像はイメージです</div>
    </div>
    <div class="mb-5">
      <h5 class="mb-3 font-weight-bold">料金</h5>
      <h5>4,950 <small>円〜</small></h5>
    </div>
    <div class="text-right">
      <b-button @click="goto('/list')" variant="info">銘柄一覧へ</b-button>
    </div>
  </div>
</template>

<script>
import router from '../../router';

export default {
  name: 'PlanUnit',
  methods: {
    goto(link) {
      router.push({ path: link });
    },
    priceFormatter(price) {
      return `${String(price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')} 円/税込`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';
.flex-category {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  & > div {
    border-bottom: #6c757d 1px solid;
  }
}
.payment-flow {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 2%;
  div {
    width: 49%;
  }
  @include media-breakpoint-down(md) {
    flex-direction: column;
    div {
      width: 100%;
    }
  }
}
.plan-panel {
  display: flex;
  flex-direction: column;
  background-color: #333;
  border: 1px solid #6c757d;
  padding: 2rem;
}
</style>

<template>
  <div class="panel-form wide">
    <div class="panel-form-content">
      <div class="text-center">
        <h5 class="mb-4">お問い合わせ</h5>
      </div>
      <b-form>
        <b-form-group>
          <label for="organizationName" class="sr-only">お名前</label>
          <b-form-input
            type="text"
            v-model="organizationName"
            id="organizationName"
            placeholder="組織名"
            class="form-control-lg"
          ></b-form-input>
          <b-form-text>任意</b-form-text>
        </b-form-group>
        <b-form-group>
          <label for="userName" class="sr-only">お名前</label>
          <b-form-input
            type="text"
            v-model="username"
            id="username"
            placeholder="お名前"
            class="form-control-lg"
            :state="usernameValidation" required></b-form-input>
          <b-form-invalid-feedback :state="usernameValidation">※必須です</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <label for="email" class="sr-only">メールアドレス</label>
          <b-form-input
            type="email"
            v-model="email"
            id="email"
            placeholder="メールアドレス"
            class="form-control-lg"
            :state="emailValidation" required></b-form-input>
          <b-form-invalid-feedback
            :state="emailValidation">
            {{ emailValidationMessage }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <label for="contactBody" class="sr-only">お問い合わせ内容</label>
          <b-form-textarea
            id="contactBody"
            v-model="contactBody"
            rows="8"
            max-rows="15"
            placeholder="お問い合わせ内容"></b-form-textarea>
          <b-form-invalid-feedback :state="contactBodyValidation">※必須です</b-form-invalid-feedback>
        </b-form-group>
      </b-form>
      <div>
        <b-button
        v-on:click="doSubmit"
        :disabled="isSending"
        class="btn btn-primary btn-block execute-btn">送信する</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as Http from '@/lib/http/http';
import Init from '@/components/mixins/Init.vue';
import * as Recaptcha from '@/lib/infrastructure/google/recaptcha';

export default {
  name: 'ContactForm',
  mixins: [
    Init,
  ],
  components: {
  },
  // props: ['whitepaper'],
  data: () => ({
    isSending: false,
    organizationName: '',
    username: '',
    email: '',
    contactBody: '',
    whitepaperChecked: '0',
    emailValidationMessage: '※必須です',
  }),
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      // this.whitepaperChecked = this.whitepaper;
      await Recaptcha.loadScript();
    },
    showCompleteModal(title, msg, resetFlag = true) {
      this.$bvModal.msgBoxOk(msg, {
        title: `${title}`,
        size: 'mid',
        buttonSize: 'mid',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      }).then(() => {
        if (resetFlag) this.resetForm();
      });
    },
    resetForm() {
      this.organizationName = '';
      this.username = '';
      this.email = '';
      this.contactBody = '';
    },
    // eslint-disable-next-line consistent-return
    async doSubmit() {
      if (this.usernameValidation
      && this.emailValidation
      && this.contactBodyValidation) {
        this.isSending = true;
        const recaptchaToken = await Recaptcha.execute();
        const flag = await Http.post(
          `https://${process.env.VUE_APP_BACKEND_DOMAIN}/${process.env.VUE_APP_BACKEND_USER_VERSION}/personal/contact`,
          '',
          {
            recaptchaToken,
            organizationName: this.organizationName,
            username: this.username,
            email: this.email,
            contactBody: this.contactBody,
            whitepaperChecked: this.whitepaperChecked === '1',
          },
        )
          .then((res) => {
            console.log(res);
            return true;
          })
          .catch((err) => {
            console.log(err);
            this.isSending = false;
            return false;
          });
        if (flag) {
          this.isSending = false;
          this.showCompleteModal('送信完了', 'お問い合わせありがとうございます。確認メールをお送りいたしました。担当者からのご連絡をお待ち下さい。');
        } else {
          this.isSending = false;
          this.showCompleteModal('送信完了', 'お問い合わせありがとうございます。確認メールをお送りいたしました。メールが届いていないようであれば、お手数ですが再度お問い合わせをお願いします｡', false);
        }
      }
    },
  },
  computed: {
    usernameValidation() {
      return this.username !== '';
    },
    emailValidation() {
      return this.email !== '' && !!this.email.match(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i);
    },
    contactBodyValidation() {
      return this.contactBody !== '';
    },
  },
  watch: {
    email() {
      if (this.email === '') {
        this.emailValidationMessage = '※必須です';
      }
      if (this.email !== '' && !this.email.match(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i)) {
        this.emailValidationMessage = 'メールアドレスの形式に誤りがあります';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';
</style>

/* eslint-disable no-param-reassign */
import { DurationType, CalculationType } from '../model/project/metrics-setting';

import * as TimeHelper from '../helper/time-helper';

/* *
  @return [{ date: 0000000000,  metrics: {}}]
* */
// eslint-disable-next-line import/prefer-default-export
export const summarize = (metricsSetting, metricsList) => {
  if (metricsSetting.duration === DurationType.yearly) {
    return summarizeMetrics(
      metricsSetting,
      TimeHelper.yearly(metricsList),
    );
  }
  if (metricsSetting.duration === DurationType.quarterly) {
    return summarizeMetrics(
      metricsSetting,
      TimeHelper.quarterly(metricsList, metricsSetting.fiscalEndMonth),
    );
  }
  if (metricsSetting.duration === DurationType.monthly) {
    return summarizeMetrics(
      metricsSetting,
      TimeHelper.monthly(metricsList),
    );
  }
  if (metricsSetting.duration === DurationType.weekly) {
    return summarizeMetrics(
      metricsSetting,
      TimeHelper.weekly(metricsList),
    );
  }
  if (metricsSetting.duration === DurationType.daily) {
    return summarizeMetrics(
      metricsSetting,
      TimeHelper.daily(metricsList),
    );
  }
  return [];
};

const summarizeMetrics = (metricsSetting, slicedMetrics) => slicedMetrics.map((dateMetricsMap) => ({
  date: dateMetricsMap[0],
  metrics: operateMetrics(dateMetricsMap[1], metricsSetting.calcType),
}));

const operateMetrics = (metricsList, calcType) => {
  const totaledMetrics = metricsList.reduce((m, cur) => {
    const keyList = Object.keys(cur.metrics);
    return keyList.reduce((map, key) => {
      if (!map[key]) map[key] = 0;
      map[key] += cur.metrics[key];
      return map;
    }, m);
  }, {});
  return Object.keys(totaledMetrics).reduce((map, key) => {
    if (calcType === CalculationType.sum) {
      map = Object.assign(map, { [key]: totaledMetrics[key] });
    }
    if (calcType === CalculationType.avg) {
      const lengthExcludesDecient = metricsList.filter((m) => m.metrics[key] || m.metrics[key] === 0).length;
      map = Object.assign(map, { [key]: totaledMetrics[key] / lengthExcludesDecient });
    }
    return map;
  }, {});
};

<template>
  <div class="plan-panel">
    <h3 class="font-weight-bold mb-4">従業員数パッケージプラン</h3>
    <div class="mb-4">
      <div>全銘柄の従業員数データを閲覧できるプランです。PERAGARUで扱う従業員数データは、上場企業とその子会社の合計の被保険者数から算出しています。PERAGARUでの個別銘柄でのメトリクス閲覧、従業員数一覧表示画面での閲覧が可能です。</div>
    </div>
    <div class="mb-4">
      <div class="d-flex flex-column flex-xl-row">
        <img src="/employee_metrics.png" style="height:160px;" class="shadow border border-secondary align-self-start mr-3 mb-3 mb-xl-0">
        <img src="/employee_sheets.png" style="height:160px;" class="shadow border border-dark align-self-start">
      </div>
      <div class="text-secondary text-right mt-2">※画像はイメージです</div>
    </div>
    <div class="mb-5">
      <h5 class="mb-3 font-weight-bold">INSIGHT</h5>
      <div class="mb-3">
        <h6 class="font-weight-bold">トレンド分析：</h6>
        <div>上場企業とその子会社の従業員数の増減を追跡することで、企業や業界の成長トレンドを把握。これにより、成長軌道にある企業や業界を見極めることができます。</div>
      </div>
      <div class="mb-3">
        <h6 class="font-weight-bold">事前予測：</h6>
        <div>従業員数のデータから、将来の売り上げや利益の増加を予測。報告書が公開される前に、業績が良好な企業を見つけ出すことができます。</div>
      </div>
      <div class="mb-3">
        <h6 class="font-weight-bold">リスク管理：</h6>
        <div>従業員数の急激な減少は、企業や業界が直面している潜在的な問題を示唆します。これをもとにリスクを避け、投資の安全性を高めることができます。</div>
      </div>
      <div class="mb-3">
        <h6 class="font-weight-bold">業種間比較：</h6>
        <div>弊社独自のカテゴリ分類を追加することにより、同業種間の比較が効率的に行えるようになりました。この分類により、企業や業界のパフォーマンスをより正確に評価し、投資判断をサポートします。</div>
      </div>
      <div class="mb-3">
        <h6 class="font-weight-bold">新しい投資先の発見：</h6>
        <div>一覧画面での閲覧により、従業員数が急激に変化した企業に注目することで、新しい投資先を抽出することができます。これにより、成長の兆しを見逃さず、最適なタイミングで投資することが可能になります。</div>
      </div>
    </div>
    <div class="mb-5">
      <h5 class="mb-3 font-weight-bold">料金</h5>
      <h5>4,400 <small>円</small></h5>
    </div>
    <div class="text-right">
      <b-button
        @click="gotoPaymentPackage('employee')"
        :disabled="isContracted"
        :variant="isContracted ? 'secondary' : 'info'">
        ご契約はこちら
      </b-button>
      <div class="text-secondary">非会員の方は会員登録に移動します</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import router from '../../router';

export default {
  name: 'PlanEmployee',
  data: () => ({
    isContracted: false,
  }),
  async mounted() {
    this.isContracted = await this.checkContractedWithPlanCode('employee');
  },
  methods: {
    ...mapActions({
      checkContractedWithPlanCode: 'ContractedPlan/checkContractedWithPlanCode',
    }),
    goto(link) {
      router.push({ path: link });
    },
    gotoPaymentPackage(planId) {
      router.push({
        path: `/payment/package/${planId}`,
      });
    },
    priceFormatter(price) {
      return `${String(price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')} 円/税込`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';
.plan-panel {
  display: flex;
  flex-direction: column;
  background-color: #333;
  border: 1px solid #6c757d;
  padding: 2rem;
}
</style>

<template>
  <div>
    <b-row>
      <b-col cols="auto">
        <h1 class="mb-0">{{ projectInfo.securityCode }}</h1>
      </b-col>
      <b-col cols="auto">
        <h1 class="mb-0">{{ projectInfo.companyDisplayName }}</h1>
      </b-col>
      <b-col cols="auto" class="text-right ml-auto">
        <label class="small text-secondary mb-0">決算月</label>
        <h5 class="mb-0">{{ fiscalEndMonth(projectInfo.fiscalEndMonth) }}</h5>
      </b-col>
      <b-col cols="auto">
        <label class="small text-secondary mb-0">コーポレートサイト</label>
        <b-link v-on:click='gotoCompanyProfile' style="color: white;">
          <h5 class="mb-0 text-truncate" style="max-width: 200px;">{{ companyUrl(projectInfo.companyUrl) }}</h5>
        </b-link>
      </b-col>
    </b-row>

    <hr class="border-secondary mt-3">

  </div>
</template>

<script>

export default {
  name: 'ProjectInfo',
  props: [
    'projectInfo',
  ],
  components: {},
  methods: {
    fiscalEndMonth(fiscalEndMonth) {
      return fiscalEndMonth ? `${Number(fiscalEndMonth)}月` : '-';
    },
    companyUrl(companyUrl) {
      return companyUrl && companyUrl !== 'Null' ? companyUrl : '-';
    },
    gotoCompanyProfile() {
      if (!!this.projectInfo.companyUrl && this.projectInfo.companyUrl !== 'Null') {
        window.open(this.projectInfo.companyUrl);
      }
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';

.panel {
  padding: 1rem 2rem;
  background-color: #303030;
  border: 1px solid #444;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  max-height: 12rem;
  .content {
    overflow-y: auto;
    max-height: 8rem;
  }
}
</style>

<template>
  <b-container class="mb-5">
    <div v-show="isLoading" class="text-center my-5 py-5">
      <b-spinner></b-spinner>
    </div>
    <div v-show="!isLoading">
      <h1 class="mb-4">銘柄一覧</h1>
      <ProjectTable :projects="projects"/>
    </div>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex';
import * as Http from '@/lib/http/http';
import ProjectModel from '@/lib/model/project/project';
import LoginRestriction from '@/components/mixins/LoginRestriction.vue';
import Init from '@/components/mixins/Init.vue';
import ProjectTable from '@/components/project/ProjectTable.vue';

export default {
  name: 'List',
  mixins: [
    Init,
    LoginRestriction,
  ],
  components: {
    ProjectTable,
  },
  data: () => ({
    projects: [],
    isLoading: true,
  }),
  async created() {
    this.projects = await this.getProjectListAllFromLocal();
    if (this.projects.length) this.isLoading = false;
  },
  async mounted() {
    const fetchedProjectList = await Http.get(`https://${process.env.VUE_APP_BACKEND_DOMAIN}/2/personal/list-all`);
    if (!fetchedProjectList || !Array.isArray(fetchedProjectList)) {
      this.resetProjectList();
      return;
    }
    this.projects = fetchedProjectList.map((p) => new ProjectModel(p));

    this.sort();
    this.setProjectList(this.projects);

    this.isLoading = false;
  },
  methods: {
    ...mapActions({
      getProjectListAllFromLocal: 'ProjectList/getAllFromLocal',
      setProjectList: 'ProjectList/setProjectList',
      resetProjectList: 'ProjectList/resetProjectList',
    }),
    sort() {
      this.projects = this.projects.sort((p1, p2) => {
        if (p1.securityCode < p2.securityCode) return -1;
        if (p1.securityCode > p2.securityCode) return 1;
        return 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';
</style>

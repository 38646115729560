<template>
  <b-container>
    <div v-show="isLoading" class="text-center my-5 py-5">
      <b-spinner></b-spinner>
    </div>
    <div v-show="!isLoading">
      <div class="mb-10">
        <b-col cols="mb-0">
          <h1 class="mb-0">PERAGARU 賃金データ</h1>
        </b-col>
        <hr class="border-secondary mt-3 mb-5">
      </div>

      <div class="mb-20">
        <h2 class="mb-5">Metrics</h2>
        <div v-show="isMetricsLoading" class="text-center my-5">
          <b-spinner></b-spinner>
        </div>
        <RelatedMetrics
          companyName='PERAGARU 賃金データ'
          :metrics='relatedMetrics'
          :dataDeficiency='relatedTypeWithDeficientList'
          :typeWithScrapeInfo='relatedTypeWithScrapeInfo'
          :fiscalEndMonth='12'
          :isMember=true
          :isFreeMember=false
        />
      </div>
    </div>
  </b-container>
</template>

<script>
import router from '@/router';
import * as Http from '@/lib/http/http';
import Session from '@/lib/model/user/session';
import Init from '@/components/mixins/Init.vue';
import RelatedMetrics from '@/components/project/RelatedMetrics.vue';
import * as Analytics from '@/lib/infrastructure/google/analytics';

export default {
  name: 'JobSalary',
  mixins: [
    Init,
  ],
  components: {
    RelatedMetrics,
  },
  data: () => ({
    metrics: [],
    dataDeficiency: {},
    typeWithScrapeInfo: {},
    relatedMetricsExisting: false,
    relatedMetrics: [],
    relatedTypeWithDeficientList: {},
    relatedTypeWithScrapeInfo: {},
    projectPlanList: [],
    packagePlanList: [],
    isLoading: true,
    isMetricsLoading: true,
  }),
  async mounted() {
    await this.init();
    this.scrollTop();
  },
  methods: {
    async init() {
      this.securityCode = decodeURIComponent(this.$route.params.securityCode);
      this.sessionToken = await Session.getSession();
      const contracted = await this.checkUserPlans();
      if (!contracted) {
        await router.push({ path: '/' });
        return;
      }
      this.isLoading = false;
      const succeeded = await this.fetchRelatedMetrics();
      if (!succeeded) {
        await router.push({ path: '/' });
        return;
      }
      this.isMetricsLoading = false;
      await this.sendPaidUserEvent();
    },
    async checkUserPlans() {
      if (!this.sessionToken) return false;
      const planList = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_DOMAIN}/2/personal/plan`,
        this.sessionToken,
      )
        .catch((e) => {
          console.log(e);
          return [];
        });
      return planList.some((plan) => plan.planCode === 'salary');
    },
    async sendPaidUserEvent() {
      // （GTM上で）subscribeイベントで付与されるis_paid_userフラグを
      // 過去に課金したユーザーにもユーザープロパティを設定するために、一時的に送信する。
      // 半年程度で削除する。（2025年になったら）
      if (!this.isFreeMember) Analytics.sendIsPaidUserEvent();
    },
    async fetchRelatedMetrics() {
      try {
        if (!this.sessionToken) return false;
        const {
          relatedMetrics,
          relatedTypeWithDeficientList,
          relatedTypeWithScrapeInfo,
          error,
        } = await Http.get(
          `https://${process.env.VUE_APP_BACKEND_DOMAIN}/2/personal/translated/salary`,
          this.sessionToken,
        )
          .catch((e) => {
            console.error(e);
            return { error: true };
          });
        if (error) return false;
        if (
          relatedMetrics
          && Array.isArray(relatedMetrics)
          && relatedMetrics.length
        ) {
          this.relatedMetricsExisting = !!relatedMetrics.length;
          this.relatedMetrics = JSON.parse(JSON.stringify(relatedMetrics));
          this.relatedTypeWithDeficientList = relatedTypeWithDeficientList;
          this.relatedTypeWithScrapeInfo = relatedTypeWithScrapeInfo;
        }
      } catch (e) {
        console.error(e);
        return false;
      }
      return true;
    },
    goto(link) {
      router.push({ path: link });
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';
.non-member {
  margin: 0 auto;
  padding: 3rem 0;
}
.card-style {
  background-color: #222;
}
</style>

/* eslint-disable no-shadow,no-unused-vars */
import * as Crypto from '../../../lib/helper/crypto-helper';
import * as LocalStorage from '../../../lib/infrastructure/local-storage/local-storage';

const storageKeyName = `${process.env.NODE_ENV}-UserLogin`;

const state = {
  loginUser: '',
};

const getters = {
  getLoginUser: (
    state,
    _getters,
    _rootState,
    _rootGetters,
  ) => state.loginUser,
};

const actions = {
  getFromLocal({ commit, state, getters }) {
    const encryptedUser = LocalStorage.get(storageKeyName);
    commit('setLoginUser', encryptedUser || '');
    return getters.getLoginUser;
  },
  async setLoginUser({ commit, state }, loginUser) {
    const encryptedUserName = await Crypto.sha256(loginUser);
    commit('setLoginUser', encryptedUserName);
    LocalStorage.upsert(storageKeyName, state.loginUser);
  },
  resetProjectList({ commit, state }) {
    commit('reset');
    LocalStorage.upsert(storageKeyName, state.loginUser);
  },
};

const mutations = {
  setLoginUser(state, loginUser) {
    state.loginUser = loginUser;
  },
  reset(state) {
    state.loginUser = '';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <b-container>
    <div v-show="isLoading" class="text-center my-5 py-5">
      <b-spinner></b-spinner>
    </div>
    <div v-show="!isLoading">
      <h1 class="mb-5">アカウント情報</h1>
      <b-row align-v="start">
        <b-col cols="12" order-lg="0">
          <b-alert v-model="isSuccess" variant="success" dismissible>
            <b-icon-patch-exclamation-fill></b-icon-patch-exclamation-fill> Successfully changed.
          </b-alert>
          <b-alert v-model="isError" variant="danger" dismissible>
            <b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> Error occurred. Please contact us from Inquiry Form.
            <a :href="inquiryFormLink">
              Go to inquiry form
            </a>
          </b-alert>
          <b-alert v-model="isKnownError" variant="danger" dismissible>
            <b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{ errorMessage }}
          </b-alert>
        </b-col>
      </b-row>
      <div class="panel">
        <b-form>
          <div v-if="username !== currentUser.email" class="mb-4">
            <h5>ユーザー名</h5>
            <h4>{{ username }}</h4>
          </div>
          <div>
            <h5>Eメール</h5>
            <h4>{{ currentUser.email }}</h4>
          </div>
          <div class="mt-5">
            <b-button
              v-if="phase === 'CHECK'"
              v-on:click="toChangePassword"
              variant="info"
            >
              パスワード変更
            </b-button>
          </div>
          <div v-if="phase === 'CHANGE'">
            <h5 class="mt-5">現在のパスワード</h5>
            <b-form-group>
              <b-form-input
                id="password"
                class="form-control-lg"
                placeholder="Current Password"
                type="password"
                v-model="currentPassword"
                :state="currentPasswordValidation"
                aria-describedby="currentPasswordInvalid"
                autocomplete="off"
              ></b-form-input>
              <b-form-invalid-feedback id="currentPasswordInvalid">入力必須です</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
              <h5>新しいパスワード</h5>
              <div id="passwordInfo" class="text-decoration-none">大文字・小文字・数字を含む10文字以上で入力してください。</div>
              <b-form-input
                id="password"
                class="form-control-lg"
                placeholder="New Password"
                type="password"
                v-model="password"
                :state="passwordValidation"
                aria-describedby="passwordInfo passwordInvalid noChange"
              ></b-form-input>
              <b-form-invalid-feedback :state="passwordValidation">{{ passwordValidationMessage }}</b-form-invalid-feedback>
              <b-form-invalid-feedback :state="passwordValidation">{{ passwordValidationMessageIncludeUppercase }}</b-form-invalid-feedback>
              <b-form-invalid-feedback :state="passwordValidation">{{ passwordValidationMessageIncludeLowercase }}</b-form-invalid-feedback>
              <b-form-invalid-feedback :state="passwordValidation">{{ passwordValidationMessageIncludeNumber }}</b-form-invalid-feedback>
              <b-form-invalid-feedback :state="passwordValidation">{{ passwordValidationMessageLength }}</b-form-invalid-feedback>
            </b-form-group>
            <h5>新しいパスワードの確認</h5>
            <b-form-group>
              <b-form-input
                id="confirm-password"
                class="form-control-lg"
                placeholder="Confirm Password"
                type="password"
                v-model="confirmedPassword"
                :state="confirmedPasswordValidation"
                aria-describedby="confirmedPasswordInvalid"
                autocomplete="off"
              ></b-form-input>
              <b-form-invalid-feedback id="confirmedPasswordValidation">{{ confirmedPasswordInvalidMessage() }}</b-form-invalid-feedback>
            </b-form-group>
            <div class="mt-5 text-right">
              <b-button v-on:click="doChangePassword" variant="info" class="mr-3">変更</b-button>
              <b-button v-on:click="back">戻る</b-button>
            </div>
          </div>
        </b-form>
      </div>
      <div class="text-center mt-5">
        <router-link :to="{ name: 'userDelete' }" class="text-light"><u>退会はこちら</u></router-link>
      </div>
    </div>
  </b-container>
</template>

<script>
import { emailToUsername } from '@/lib/helper/username-converter';
import router from '@/router';
import Signin from '@/lib/model/user/signin';

import Session from '@/lib/model/user/session';
import * as Http from '@/lib/http/http';

export default {
  name: 'UserSetting',
  components: {
  },
  data: () => ({
    sessionToken: {},
    phase: 'CHECK',
    isSuccess: false,
    isError: false,
    isKnownError: false,
    errorMessage: '',
    inquiryFormLink: process.env.VUE_APP_GOOGLE_FORM_LINK,
    currentUser: {},
    currentPassword: '',
    password: '',
    passwordValidationMessage: '',
    passwordValidationMessageIncludeUppercase: '',
    passwordValidationMessageIncludeLowercase: '',
    passwordValidationMessageIncludeNumber: '',
    passwordValidationMessageLength: '',
    confirmedPassword: '',
    signInStatus: {},
    isLoading: true,
  }),
  async created() {
    await this.init();
    this.isLoading = false;
  },
  methods: {
    async init() {
      // name,email,statusが必要なため、cognito(name,email)+localstorage(status)ではなく、DBからuserを取得する。
      const token = await Session.getSession();
      if (!token) {
        console.error('Session Error.');
        return;
      }
      this.sessionToken = token;

      const user = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_USER_DOMAIN}/${process.env.VUE_APP_BACKEND_USER_VERSION}/personal/user/current`,
        this.sessionToken,
      );
      this.currentUser = user;
    },
    toChangePassword() {
      this.phase = 'CHANGE';
    },
    back() {
      this.phase = 'CHECK';
      this.isError = false;
      this.isKnownError = false;
      this.errorMessage = '';
    },
    async doChangePassword() {
      if (
        !this.currentPassword
        || this.password !== this.confirmedPassword
        || !this.password.match(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d!-/:-@[-`{}-~\]]{10,}$/)
      ) return;

      const setStatus = await Signin.changePassword(this.currentPassword, this.password);
      if (!setStatus) {
        this.isError = true;
        window.scroll({ top: 0 });
        return;
      }
      this.signInStatus = setStatus;
      if (!setStatus.success()) {
        this.isKnownError = true;
        this.errorMessage = setStatus.errMessage;
        window.scroll({ top: 0 });
        return;
      }
      this.isSuccess = true;
      setTimeout(() => {
        this.isSuccess = false;
        window.scroll({ top: 0 });
      }, 10000);
      this.phase = 'CHECK';
      this.currentPassword = '';
      this.password = '';
      this.confirmedPassword = '';
    },
    goto(link) {
      // eslint-disable-next-line no-restricted-globals
      router.push({ path: link });
    },
    confirmedPasswordInvalidMessage() {
      if (!this.confirmedPassword) return '入力必須です';
      return 'パスワードが一致していません';
    },
  },
  async mounted() {
    await this.init();
  },
  computed: {
    currentPasswordValidation() {
      return !!this.currentPassword;
    },
    passwordValidation() {
      // ref) https://qiita.com/mpyw/items/886218e7b418dfed254b#%E5%8D%8A%E8%A7%92%E8%8B%B1%E6%95%B0%E5%AD%97%E8%A8%98%E5%8F%B7%E3%82%92%E3%81%9D%E3%82%8C%E3%81%9E%E3%82%8C1%E7%A8%AE%E9%A1%9E%E4%BB%A5%E4%B8%8A%E5%90%AB%E3%82%808%E6%96%87%E5%AD%97%E4%BB%A5%E4%B8%8A100%E6%96%87%E5%AD%97%E4%BB%A5%E4%B8%8B%E3%81%AE%E6%AD%A3%E8%A6%8F%E8%A1%A8%E7%8F%BE
      // 必須 && 半角英数(記号も含めてOK) && 10文字以上
      return this.password !== '' && !!this.password.match(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d!-/:-@[-`{}-~\]]{10,}$/);
    },
    confirmedPasswordValidation() {
      return !!this.confirmedPassword && this.confirmedPassword === this.password;
    },
    username() {
      if (!this.currentUser || !this.currentUser.name) return '';
      if (this.currentUser.name === emailToUsername(this.currentUser.email)) return this.currentUser.email;
      return this.currentUser.name;
    },
  },
  watch: {
    password() {
      if (this.password === '') {
        this.passwordValidationMessage = '※必須です';
        this.passwordValidationMessageIncludeUppercase = '';
        this.passwordValidationMessageIncludeLowercase = '';
        this.passwordValidationMessageIncludeNumber = '';
        this.passwordValidationMessageLength = '';
      }
      if (this.password !== '' && !this.password.match(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d!-/:-@[-`{}-~\]]{10,}$/)) {
        this.passwordValidationMessage = 'パスワードの形式に誤りがあります';
        if (!this.password.match(/[A-Z]/)) {
          this.passwordValidationMessageIncludeUppercase = '大文字が含まれていません';
        } else {
          this.passwordValidationMessageIncludeUppercase = '';
        }
        if (!this.password.match(/[a-z]/)) {
          this.passwordValidationMessageIncludeLowercase = '小文字が含まれていません';
        } else {
          this.passwordValidationMessageIncludeLowercase = '';
        }
        if (!this.password.match(/\d/)) {
          this.passwordValidationMessageIncludeNumber = '数字が含まれていません';
        } else {
          this.passwordValidationMessageIncludeNumber = '';
        }
        if (this.password.length < 10) {
          this.passwordValidationMessageLength = '10文字以上にしてください';
        } else {
          this.passwordValidationMessageLength = '';
        }
      }
    },
  },
};
</script>

<style scoped>
.panel {
  background-color: #333;
  padding: 1.5rem;
  border: 1px solid #444;
}
.invalid-feedback {
  color: gray;
  padding-top: 3.5px;
  margin: 0;
}
</style>

<template>
  <div>
    <h5>PERAGARUデータ閲覧</h5>
    <ul>
      <li>PERAGARU賃金データの閲覧権限</li>
      <li>ファイルダウンロード（csv、json形式）権限</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SalaryDetail',
  props: ['planCode', 'category'],
  data: () => ({}),
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';
</style>

<template>
  <b-container>
    <h1 class="mb-5">ご契約ありがとうございます</h1>
    <div class="desc-panel mb-5">
      <div class="mb-5">
        <h5 class="font-weight-bold mb-3">プラン名</h5>
        <h5>{{ subscription.planName }}</h5>
      </div>
      <div class="mb-5">
        <h5 class="font-weight-bold mb-3">プラン詳細</h5>
        <div v-if="planCode === 'employee'">
          <EmployeeDetail />
        </div>
        <div v-else>
          <UnitDetail :planCode="planCode" :category="category" />
        </div>
      </div>
      <div class="mb-5">
        <h5 class="font-weight-bold mb-3">料金</h5>
        <div v-if="!subscription.couponCode">
          <div class="d-flex">
            <h5>月額</h5>
            <h5 class="text-right">{{ amountFormatter(subscription.amount + subscription.tax) }} <small>円</small></h5>
          </div>
        </div>
        <div v-else>
          <div class="d-flex">
            <h5>初月</h5>
            <h5 class="text-right">{{ amountFormatter(subscription.initAmountTax) }} <small>円</small></h5>
          </div>
          <div class="d-flex">
            <h5>２ヶ月目〜</h5>
            <h5 class="text-right">{{ amountFormatter(subscription.amount + subscription.tax) }} <small>円</small></h5>
          </div>
        </div>
        <div class="text-right">
          <small class="text-secondary">１ヶ月ごとに自動更新</small>
        </div>
      </div>
    </div>
    <div class="text-right">
      <b-button @click="goto('/user/subscription')" variant="info" class="mr-3">契約情報</b-button>
      <b-button @click="goto('/top')" variant="info">TOP</b-button>
    </div>
  </b-container>
</template>

<script>
import router from '@/router';
import Init from '@/components/mixins/Init.vue';
import UnitDetail from '@/components/planDetails/UnitDetail.vue';
import EmployeeDetail from '@/components/planDetails/EmployeeDetail.vue';

export default {
  name: 'PaymentComplete',
  mixins: [Init],
  props: ['planCode', 'category', 'subscription'],
  components: {
    UnitDetail,
    EmployeeDetail,
  },
  data: () => ({}),
  methods: {
    goto(link) {
      router.push({ path: link });
    },
    amountFormatter(amount) {
      return `${String(amount).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom';
@import '~bootstrap/scss/bootstrap.scss';

.desc-panel {
  padding: 2rem;
  background-color: #303030;
  border: 1px solid #444;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
</style>
